import React from "react";
import {InputField} from 'react-semantic-redux-form';
import {connect, useDispatch, useSelector} from "react-redux";
import {Container, Divider, Form, Message} from "semantic-ui-react";
import {change, Field, getFormSubmitErrors, reduxForm} from "redux-form";
import {apiLogin} from "../../modules/app";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {api_call, getModule} from "../../modules/main_module";
import {api_url} from "../../utils";
import {If} from "../helpers/Condition";

export const LoginFormView = (props) => {
    const {submitError, submitFailed, handleChange, handleSubmit, onSubmit, form, error, ...other} = props;
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(api_call('login', 'get', api_url('admin/login_recaptcha')))
    }, [dispatch]);

    const {site_key} = useSelector(getModule('login'));
    const errors = useSelector(getFormSubmitErrors(form));

    return <Container>
        <Divider hidden/>
        <Form autoComplete="off" error={submitFailed} onChange={handleChange} onSubmit={handleSubmit(values=>onSubmit(values, form))}>
            {submitFailed &&
            <Message
                error
                content={'Wystąpił błąd: ' + errors.message}/>
            }
            <Field name={'email'}  component={InputField} label={'Email'}/>
            <Field name={'password'} type={'password'} component={InputField} label={'Hasło'}/>
            <If condition={site_key != null}>
                <HCaptcha
                    sitekey={site_key}
                    onVerify={(token,ekey) => dispatch(
                        change(form, 'recaptcha', token)
                    )}
                />
            </If>
            <Form.Button type='submit' id='submitFormButton' content='Zaloguj'/>
        </Form>
    </Container>
};

export const LoginFormComponent = reduxForm({
    form: 'loginForm',
})(LoginFormView);

export const LoginForm = connect(
    null,
    {
        onSubmit: apiLogin
    }
)(LoginFormComponent);