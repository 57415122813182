import {api_url} from "./utils";

export const API = {
    LOGIN:              api_url('admin/login'),
    LOGOUT:             api_url('admin/logout'),

    PHOTOS:             api_url('admin/photos_uri'),
    photo:              (id) => api_url('admin/photos', id),
    photo_base_64:      (id) => api_url('admin/photo_base_64', id),

    admin:              (id='') => api_url('admin/admins', id),

    comments:           (id='') => api_url('admin/comments'),
    messages:           (id='') => api_url('admin/messages'),

    user:               (id='') => api_url('admin/users', id),
    user_orders:        (id='') => api_url('admin/users', id) + '/orders',
    user_descriptions:  () => api_url('admin/user_content_approvements'),
    users_plans:        () => api_url('admin/users_plans'),
    user_login_logs:    (id='') => api_url('admin/user_login_logs'),
    order:              (id='') => api_url('admin/orders', id),
    payment_plan:       (id='') => api_url('admin/paymentPlans', id),
    payment_stats:      () => api_url('admin/paymentStats'),
    verify_photos:       (id='') => api_url('admin/verify_photos', id),
    filters:            () => api_url('admin/filters'),
    contentLocationData:() => api_url('admin/get-locations'),
    content:            (id) => api_url('admin/content', id),

    REFRESH_TOKEN:      api_url('admin/refresh'),
    GET_FULL_PHOTO:     (photo_id) => api_url(`getFullPhoto/${photo_id}`),

    GET_THREADS:        api_url('admin/getThreads'),
    GET_MESSAGES:       api_url('admin/getMessages'),

    GET_ROLES:          api_url('admin/getRoles'),
    UPDATE_ROLE:        api_url('admin/editRole'),
    CREATE_ROLE:        api_url('admin/newRole'),
    DELETE_ROLE:        api_url('admin/deleteRole'),

    GET_PERMISSIONS:    api_url('admin/getPermissions'),

    GET_ACTIVITIES:     api_url('admin/getActivities'),
};

