import {useMutation, UseQueryObjectConfig} from "react-query";
import axios from '../axios-interceptor';
import {AxiosRequestConfig} from "axios";
import {useNewUserApi} from "./users";

export const pendingChangesRequest = async ({config, data}:  {config?: AxiosRequestConfig, data?: any}) => {
    const res = await axios({
        method: 'get',
        ...config,
        data,
        url: `user_content_approvements`
    })
    return res.data
}

export const pendingChangeRequest = async ({id, config, data}:  {id: number, config?: AxiosRequestConfig, data?: any}) => {
    const res = await axios({
        method: 'get',
        ...config,
        data,
        url: `user_content_approvements/${id}`
    })
    return res.data
}

export const pendingChangeRequestConfig = (id: number, config?: AxiosRequestConfig) : UseQueryObjectConfig<any, any>=> ({
    queryKey: ['pendingChange', id],
    queryFn: () => pendingChangesRequest({config})
})

export const usePendingChangesApi = () => {
    const {fetchActiveUser} = useNewUserApi();
    const [mutation, data] = useMutation(pendingChangesRequest, {
        onSuccess: fetchActiveUser
    })

    const manage = (action: string) =>
        (id: number, value: string) =>
            mutation({
                data: {
                    action,
                    data: [
                        {id, value}
                    ]
                },
                config: {
                    method: 'post'
                }
            })

    const acceptChange = manage('accept')
    const denyChange = manage('deny')

    return {
        acceptChange,
        denyChange,
        ...data
    }
}

export const usePendingChangeApi = () => {
    const {fetchActiveUser} = useNewUserApi();
    const [mutation, data] = useMutation(pendingChangeRequest, {
        onSuccess: fetchActiveUser
    })

    const manage = (action: string) =>
        (id: number) =>
            mutation({
                id,
                data: {
                    action
                },
                config: {
                    method: 'patch'
                }
            })

    const acceptChange = manage('accept')
    const denyChange = manage('deny')

    return {
        acceptChange,
        denyChange,
        ...data
    }
}