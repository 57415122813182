import React, {useState, useCallback} from "react";
import {UserChatsView} from "../../components/stateless/user/UserChatsView";
import {UserMessagesView} from "../../components/stateless/user/UserMessagesView";
import {ThemeProvider} from "@livechat/ui-kit";
import {Button, Popup} from "semantic-ui-react";
import {useUsersRouter} from "../../hooks/router";
import {useGetUser} from "../../api/users";
import {useFetchMessages} from "../../api/chat";
import { Link } from "react-router-dom";
import { ROUTE } from "../../route-names";
import { request } from "../../modules/app";
import { api_url } from "../../utils";

export const UserChat = () => {
    const {user_id} = useUsersRouter();
    const {user_threads} = useGetUser(user_id);

    const [activeThreadId, setActiveThreadId] = useState(0);
    const {messages, participants, fetchMore, canFetchMore, } = useFetchMessages({thread_id: activeThreadId});
    const [loading, setLoading] = useState(false);

    const removeAllUnreadMessages = () => {
        setLoading(true);
        request('post', api_url(`admin/messages/removeAllUnreadMessagesSentByUser`), { user_id: user_id })
            .then(res => {
                setLoading(false);
                alert('Usunięto wszystkie wiadomości wysłane przez tego użytkownika nieodczytane przez odbiorców');
            });
    }

    const DeleteAllMessagesButton = useCallback(() =>
    <Popup open={loading === false ? undefined : true} on={"click"} trigger={<Button content={'Usuń wszystkie nieodczytane'} />}>
      <div>Czy na pewno usunąć wszystkie wiadomości wysłane przez tego użytkownika nieodczytane przez odbiorców?</div>
      <Button onClick={() => removeAllUnreadMessages()} content={'Usuń'} loading={loading} disabled={loading} />
    </Popup>
    , [messages, loading])

    const theme = {
        vars: {
            'primary-color': '#427fe1',
            'secondary-color': '#fbfbfb',
            'tertiary-color': '#fff',
            'avatar-border-color': 'blue',
        },
        AgentBar: {
            Avatar: {
                size: '42px',
            },
            css: {
                backgroundColor: 'var(--secondary-color)',
                borderColor: 'var(--avatar-border-color)',
            }
        },
        MessageList: {
            css: {
                backgroundColor: '#f3f3f4'
            },
        },
        TextComposer: {
            css: {
                'color': '#000',
            },
        },
    }
    return <>
            <div style={{margin: '10px'}}>
                <DeleteAllMessagesButton />
            </div>
            <div style={{margin: '10px'}}>
                <Link to={ROUTE.MESSAGES_FOR_USER_URL(user_id)} style={{color: '#000'}}><Button content={"Wiadomości wysłane przez użytkownika"} /></Link>
            </div>
            <div style={{ display: 'flex' }}>
                <ThemeProvider theme={theme}>
                    <UserChatsView data={user_threads} active_thread_id={activeThreadId} selectActiveThread={setActiveThreadId}/>

                    <div style={{width: '100%'}}>
                        <UserMessagesView messages={messages} actual_user_id={user_id} participants={participants}/>

                        {canFetchMore ? (
                            <Button content={"Wczytaj więcej wiadomości"} fluid onClick={() => fetchMore()}/>
                        ) : ''}

                    </div>
                </ThemeProvider>
            </div>

    </>
};

export default UserChat;