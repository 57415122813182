import React from "react";
import {Container, Divider, Dropdown, Form, Header, Message} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {InputField, SelectField} from 'react-semantic-redux-form';
import {connect} from "react-redux";
import Date from "../stateless/form/Date";
import TextArea from "../stateless/form/redux-form/TextArea";
import {mapArrayOptionsToObject} from "../stateless/form/redux-form/Select";
import * as ADVANCED from "../stateless/user/AdvancedProperties/constants";
import {apiSaveUser} from "../../modules/users";

const gender_options = [
    {
        key: 0,
        value: 'f',
        text: 'Kobieta',
    },
    {
        key: 1,
        value: 'm',
        text: "Mężczyzna",
    }
];

export const mapOptions = (options) => {
    return options.map(function (option, i) {
        return {key: i, text: option, value: i};
    });
};

const DropdownMultipleSelection = ({input, options, label, onChange, value, ...custom}) => {

    if(!Array.isArray(value)){
        value = value.split(',').map(v => {
            return parseInt(v);
        });
    }

    return <Dropdown fluid multiple selection {...input} {...custom} placeholder={label} label={label}
                     options={mapArrayOptionsToObject(options)}
                     onChange={(e, {value}) => (onChange(value))}
                     value={value}
    />
};



function surround(component) {
    return ({input, placeholder, meta: {touched, error}, ...custom}) => {
        const hasError = touched && error !== undefined;


        return (<Form.Field>
                <label> {placeholder} </label>
                <component
                    {...input}
                />
                {component({
                    ...input,
                    placeholder,
                    error: hasError && error[0],
                    ...custom
                })}
            </Form.Field>
        );
    }
}

export const DateField = surround(Date);

export const UserForm = (props) => {
    const {submitFailed, handleSubmit, onSubmit, initialValues, form, submitting} = props;

    return <Container>
        <Divider hidden/>
        <Form autoComplete="off" error={submitFailed} loading={submitting} onSubmit={handleSubmit(values=>onSubmit(values, form))}>

            {submitFailed &&
            <Message
                error
                content={'Wystąpił błąd'}/>
            }

            <Header as="h3" dividing>
                Informacje
            </Header>

            <Form.Group widths="equal">
                <Field name={'name'} component={InputField} label={'Imię'}/>
                <Field name={'login'} component={InputField} label={'Login'} disabled/>
                <Field name={'email'} component={InputField} label={'Email'}/>
                <Field name={'phone'} component={InputField} label={'Telefon'}/>
            </Form.Group>
            <Field name={'password'} component={InputField} label={'Hasło'}/>
            <Form.Group widths="equal">
                <Field name={'height'} component={InputField} label='Wzrost' type="number"/>
                <Field name={'gender'} component={SelectField} label='Płeć' options={gender_options}/>
                <Field name={'looking_gender'} component={SelectField} label='Szukana Płeć' options={gender_options}/>
                <Field name={'birth_date'} component={surround(Date)} placeholder='Data Urodzin'/>
            </Form.Group>

            <Form.Group widths="equal">
                <Field name={'description'} component={surround(TextArea)} placeholder='Opis'/>
            </Form.Group>

            <Header as="h3" dividing>
                Zaawansowane
            </Header>

            <Form.Group widths="equal">
                <Field name={'martial_status'} component={SelectField}
                       options={mapOptions(ADVANCED.MARTIAL_STATUS)} label={'Status'} clearable/>
                <Field name={'alcohol'} component={SelectField} options={mapOptions(ADVANCED.ALCOHOL)}
                       label={'Alkohol'} clearable/>
                <Field name={'body'} component={SelectField} options={mapOptions(ADVANCED.BODY)}
                       label={'Budowa ciała'} clearable/>
                <Field name={'has_children'} component={SelectField} options={mapOptions(ADVANCED.CHILDREN)}
                       label={'Dzieci'} clearable/>
            </Form.Group>
            <Form.Group widths="equal">
                <Field name={'want_children'} component={SelectField} options={mapOptions(ADVANCED.WANT_CHILDREN)}
                       label={'Chce dzieci'} clearable/>
                <Field name={'education'} component={SelectField} options={mapOptions(ADVANCED.EDUCATION)}
                       label={'Wykształcenie'} clearable/>
                <Field name={'eye_color'} component={SelectField} options={mapOptions(ADVANCED.EYE_COLOR)}
                       label={'Kolor Oczu'} clearable/>
                <Field name={'hair_color'} component={SelectField} options={mapOptions(ADVANCED.HAIR_COLOR)}
                       label={'Kolor Włosów'} clearable/>
            </Form.Group>
            <Form.Group widths="equal">
                <Field name={'looking_for'} component={surround(DropdownMultipleSelection)}
                       options={ADVANCED.LOOKING_FOR}
                       placeholder={'Czego szuka'} clearable/>
                <Field name={'job'} component={SelectField} options={mapOptions(ADVANCED.JOB)} label={'Zawód'}
                       clearable/>
                <Field name={'smoke'} component={SelectField} options={mapOptions(ADVANCED.SMOKE)}
                       label={'Papierosy'} clearable/>
            </Form.Group>
            <Form.Button type='submit' id='submitFormButton' content='Zapisz'/>
        </Form>
    </Container>
};

export default connect(
    null,
    {
        onSubmit: apiSaveUser
    }
)(
    reduxForm({
        form: 'user-form',
        enableReinitialize: true,
    })(UserForm)
);