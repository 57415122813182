import React from 'react'
import {ChatList, ChatListItem, Column, Row} from '@livechat/ui-kit'
import {UserName} from "../../User/UserName";

export const UserChatsView = ({data, active_thread_id, selectActiveThread}) => (
    <div style={{ maxWidth: 300, flex: '0 0 65%' }}>
            <ChatList>
                    {data.map(function (thread) {
                            return <ChatListItem active={thread.id === active_thread_id} onClick={()=>selectActiveThread(thread.id)}>
                                    <Column fill>
                                        <Row justify>
                                            <UserName user={thread.user} link={false} gender banned deleted country/>
                                        </Row>
                                    </Column>
                            </ChatListItem>
                    })}
            </ChatList>
    </div>
);