import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as VerifyPhotosModule from "../../modules/verify_photos";
import {api_get, getPagination, getVerifyPhotos} from "../../modules/verify_photos";
import {Pagination} from "../../components/tables/pagination";
import {Button, Card, Form, Header, Image, Message, Modal} from "semantic-ui-react";
import {UserName} from "../../components/User/UserName";
import {Field, reduxForm} from "redux-form";
import {TextAreaField, SelectField} from "react-semantic-redux-form";
import {submitForm} from "../../modules/app";
import {API} from "../../api-endpoints";
import {PageContainer} from "../../components/page/PageContainer";

const form_reject_verify_photo = 'form-reject-verify-photo';

const FormRejectVerifyPhoto = (props) => {
    const {submitting, submitFailed, change} = props;
    const selectOptions = [
        { text: 'Twarz + Gest', value: 'Zdjęcie weryfikacyjne musi zawierać  Twarz + Gest. Dodaj poprawne zdjęcie.' },
    ];

    const handleSelect = (value) => {
        change('reject_reason', value)
    };

    return <Form autoComplete="off" error={submitFailed} loading={submitting}>
        {submitFailed &&
        <Message
            error
            content={'Wystąpił błąd'}/>
        }
        <Field name="{'select_reason'}"
            component={SelectField}
            search={false}
            label={"Wybierz"}
            options={selectOptions}
            onChange={handleSelect}
        />
        <Field name={'reject_reason'} 
            component={TextAreaField} 
            label={'Powód odrzucenia (wymagane)'}
        />
    </Form>
};

const FormRejectVerifyPhotoReduxForm = reduxForm({
    form: form_reject_verify_photo,
})(FormRejectVerifyPhoto);

const RejectModal = ({open, onRejectClick, onCancelClick}) => (
    <Modal open={open}>
        <FormRejectVerifyPhotoReduxForm/>
        <Button onClick={onRejectClick} content={'Odrzuć'} color={'red'}/>
        <Button onClick={onCancelClick} content={'Anuluj'} color={'gray'}/>
    </Modal>
);

const VerifyPhoto = ({verifyPhoto, onClickAccept, onClickReject}) => {
    const ActionSection = () => {
        if(verifyPhoto.status === 2)
            return <>
                <Header as={'h2'} color={'red'} content={'Odrzucony'}/>
                <Header as={'h5'} content={'Powód:'}/>

                <Card.Description>
                    {verifyPhoto.reject_reason}
                </Card.Description>
        </>

        if(verifyPhoto.status === 1)
            return <Header as={'h2'} color={'green'} content={'Zatwierdzony'}/>

        return <>
            <Button color={'green'} onClick={onClickAccept} content={'Akceptuj'}/>
            <Button color={'red'} onClick={onClickReject} content={'Odrzuć'}/>
        </>
    }
    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    <UserName user={verifyPhoto.user}/>
                </Card.Header>
                {verifyPhoto.main_photo ?
                    <Image src={verifyPhoto.main_photo}/>
                    :
                    <h4>Brak zdjęcia publicznego</h4>
                }
                <Image src={verifyPhoto.uploaded_image}/>
                <Image src={verifyPhoto.gesture_image}/>
                <ActionSection/>
            </Card.Content>
        </Card>
    );
};

export const VerifyPhotosView = ({pagination, photos, onPageChange, onClickAccept, onClickReject}) => (
    <div>
        <Card.Group>
            {photos.map(photo => (
                <VerifyPhoto verifyPhoto={photo}
                             onClickAccept={() => onClickAccept(photo.id)}
                             onClickReject={() => onClickReject(photo.id)}
                />
            ))}
        </Card.Group>
        <Pagination pagination={pagination} onChange={onPageChange}/>
    </div>
);


const useStateComponent = (inputValue, onChange) => {
    const [state, setState] = React.useState(inputValue);
    React.useEffect(() => {
        onChange && onChange(state);
    }, [JSON.stringify(state)]);
    React.useEffect(() => {
        setState(inputValue);
    }, [JSON.stringify(inputValue)]);
    return [state, setState]
}

export const VerifyPhotos = ({query={}, onQueryChange}) => {
    const dispatch = useDispatch();
    const photos = useSelector(getVerifyPhotos);
    const pagination = useSelector(getPagination);

    const handleQueryChange = (new_query) => {
        console.log('handleQueryChange',new_query)
        dispatch(api_get(null, new_query));
        onQueryChange && onQueryChange(new_query)
    }

    useEffect( () => {
        dispatch(api_get(null, query_))
    }, []);

    const [query_, setQuery] = useStateComponent({
        'page': 1,
        'per_page': 10,
        ...query
    }, handleQueryChange);

    const [modal, resetModal] = React.useState({open: false, id: undefined});

    const handleAccept = (id) => {
        dispatch(
            VerifyPhotosModule.api_update(id, {
                action: 'accept'
            }, ()=>dispatch(api_get(null, query_)))
        );
    };

    const handleRejectClick = (id) => {
        resetModal({open: true, id});
    };

    const handleReject = () => {
        dispatch(
            submitForm(form_reject_verify_photo, API.verify_photos(modal.id), 'put', {
                action: 'deny'
            }, () => {
                resetModal({open: false});
                dispatch(api_get(null, query_));
            })
        );
    };

    const handleChangePage = (page) => {
        setQuery(previous => ({...previous, page}));
    };

    const statusSelected = (status) => {
        setQuery(previous => ({...previous, status: status}));
    }

    return <>
        <div style={{marginBottom: '10px'}}>
            <Button content={'Do zweryfikowania'} onClick={()=>statusSelected(0)} color={query_.status == 0 ? 'green' : null}/>
            <Button content={'Zatwierdzone'} onClick={()=>statusSelected(1)} color={query_.status == 1 ? 'green' : null}/>
            <Button content={'Odrzucone'} onClick={()=>statusSelected(2)} color={query_.status == 2 ? 'green' : null}/>
        </div>
        <RejectModal open={modal.open} onCancelClick={() => resetModal({open: false})} onRejectClick={handleReject}/>
        <VerifyPhotosView pagination={pagination}
                          onPageChange={handleChangePage}
                          photos={photos}
                          onClickAccept={handleAccept}
                          onClickReject={handleRejectClick}
        />
    </>
};

export default function VerifyPhotosPage(){
    return <PageContainer name={'Zdjęcia weryfikacyjne'}>
        <VerifyPhotos query={{status: 0}}/>
    </PageContainer>
}