import {useUsersRouter} from "../../../../hooks/router";
import {useDownloadPhoto} from "../../../../api/photos";
import {Button, Dimmer, Icon, Image, Loader} from "semantic-ui-react";
import React, {useState} from "react";
import {ROUTE} from "../../../../route-names";
import {photosRequest} from "../../../../api/requests";
import {useCustomPaginatedQuery} from "../../../../api/core";

export const UsersPhotos = ({privatePhotos=false, user_id}) => {
    const {data: user_photos} = useCustomPaginatedQuery('photos', photosRequest, {initConfig: {
        filters: {
            user_id,
            trashed: 'with'
        },
        perPage: 0,
        sortBy: 'position'
    }});

    return <Image.Group>
        {user_photos.filter(photo => photo.private == privatePhotos).map(photo =>
            photo.deleted_at ?
                <DeletedPhoto photo_id={photo.id} key={photo.id} fetchSize={'medium'} deleted_at={photo.deleted_at}/>
                :
                <Photo photo_id={photo.id} key={photo.id} fetchSize={'medium'}/>
        )}
    </Image.Group>
}

export const PhotoSrc = ({render, photo_id, fetchSize, cropped=1}) => {
    const api = useDownloadPhoto(photo_id, fetchSize, cropped);

    return render({
        originalSrc: api.data?.image,
        src: api.data?.image ?? "https://react.semantic-ui.com/images/wireframe/image-text.png",
        loading: api.isFetching,
        api
    })

}

export const Photo = ({photo_id, fetchSize, cropped, ...props}) => {
    const {history} = useUsersRouter();
    const [isActive, setIsActive]  = useState(false)
    const active = isActive

    const content = (loading) => (
        <div>
            {loading ? (
                <Loader/>
            ): (
                <Button size={"small"} icon onClick={() => history.push(ROUTE.photo(photo_id))}>
                    <Icon name={'edit'}/>
                </Button>
            )}
        </div>
    )

    return (
        <PhotoSrc fetchSize={fetchSize} photo_id={photo_id} cropped={cropped} render={({src, loading}) => (
            <Dimmer.Dimmable
                as={Image}
                dimmed={active || loading}
                dimmer={{ active: active || loading, content: content(loading) }}
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
                size='small'
                src={src}
                {...props}
            />
        )}/>
    )

    // return <Image as={'div'} size={"medium"} wrapped>
    //     <Image as={'div'} src={api.data?.image} size={"medium"} wrapped>
    //         {/*{api.isLoading ? <Loader active inline/> : undefined}*/}
    //     </Image>
    //     {api.isLoading ? <Loader active inline/> : undefined}
    // </Image>
    //
    // return <div style={{position: 'relative', width: '50px', height: '50px'}}>
    //     <Image src={api.data?.photo}/>
    //     {/*<Loader active inline>Preparing Files</Loader>*/}
    // </div>
}

export const DeletedPhoto = ({photo_id, fetchSize, cropped, deleted_at, ...props}) => {
    const [isActive, setIsActive]  = useState(false)
    const active = isActive

    const content = (loading) => (
        <div>
            {loading ? (
                <Loader/>
            ): (
                <div><div>Usunięte</div><div>{deleted_at}</div></div>
            )}
        </div>
    )

    return <PhotoSrc fetchSize={fetchSize} photo_id={photo_id} cropped={cropped} render={({src, loading}) => (
            <Dimmer.Dimmable
                as={Image}
                dimmed={active || loading}
                dimmer={{ active: active || loading, content: content(loading) }}
                onMouseEnter={() => setIsActive(true)}
                onMouseLeave={() => setIsActive(false)}
                size='small'
                src={src}
                {...props}
            />
        )}/>
}
