import React from 'react';
import {api_call, getModule} from "../../modules/main_module";
import {api_url} from "../../utils";
import {useDispatch, useSelector} from "react-redux";

export const MailPreview = ({mail_body, ...props}) => {
    // fetchApi(api_url('admin/mailManager/preview'), {body: mail_body}, 'post')
    const dispatch = useDispatch();
    React.useEffect(()=>{
        dispatch(api_call('mailPreview', 'post', api_url('admin/mailManager/preview'), {body: mail_body}))
    }, [mail_body])

    const {last_call: body} = useSelector(getModule('mailPreview'))

    return <div dangerouslySetInnerHTML={{ __html: body && body.body }} {...props}/>
}