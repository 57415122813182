import React from "react";
import {MyTable} from "./UserInfoBasicTable";
import {parseDate} from "../../../utils";

export const UserInfoNetworkTable = ({data}) => (
    <MyTable data={[
            ['Typ konta', data.premium_type],
            ['Blokada', data.banned && `${parseDate(data.ban_start)} - ${parseDate(data.ban_end)} (${data.ban_type} godzin)`],
            ['Ostatnie logowanie', parseDate(data.last_online)],
            ['Ostatnie IP', data.last_ip && data.last_ip],
            ['Ostatni port', data.last_port && data.last_port],
            ['Ostatni user-agent', data.last_user_agent],
            ['Ostatni window size', data.last_user_window_size],
            ['Data rejestracji', parseDate(data.created_at)],
            ['Rejestracja - IP', data.registration_ip && data.registration_ip],
            ['Rejestracja - Port', data.registration_port && data.registration_port],
            ['Rejestracja - nazwa hosta', data.registration_host_name],
    ]}/>
);