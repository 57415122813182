import {AxiosRequestConfig, CancelToken, Method} from "axios";
import axios from "../axios-interceptor";
import {apiQuery} from "../utils";


export type Endpoint =
    | 'photos'
    | 'users'
    | 'contents'
    | string

interface RequestInitConfig extends AxiosRequestConfig {
    for: Endpoint,
}

interface RequestConfig extends AxiosRequestConfig {
    page?: number,
    perPage?: number,
    filters?: object,
    params?: object
    sortBy?: object
}

const axiosRequest = (initConfig : RequestInitConfig) => (config: RequestConfig) => {
    // @ts-ignore
    const source = CancelToken.source()

    const {for: for_endpoint, ...otherAxiosInitConfig} = initConfig;
    const {page, params, filters, perPage, sortBy, ...otherAxiosConfig} = config;

    const promise = axios({
        // @ts-ignore
        url: apiQuery().for(for_endpoint).sort(sortBy).page(page).where(filters).params(params).limit(perPage).get(),
        method: 'get',
        ...otherAxiosInitConfig,
        ...otherAxiosConfig,
        cancelToken: source.token,
    })

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
    }

    return promise;
}

export const request = (config: AxiosRequestConfig) => {
    // @ts-ignore
    const source = CancelToken.source()

    const promise = axios({
        method: 'get',
        ...config,
        cancelToken: source.token,
    })

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
    }

    return promise;
}

export interface BuildRequestData{
    url: Array<Endpoint|string|number>
    method: Method
}

export const buildRequest = (requestData: BuildRequestData) => {
    return () => request({
        url: requestData.url.filter(x=>x).join('/'),
        method: requestData.method
    })
}

const entityRequest = <TAction=string>(endpoint: Endpoint) => ({id, action, data, method='patch', uri_action=''} : {id: number, action?: TAction, data?: any, method?: string, uri_action?: string}) => {
    // @ts-ignore
    return axios({
        // @ts-ignore
        // url: apiQuery().for(`${endpoint}/${id}/${uri_action}`).params({action}).get(),
        url: apiQuery().for([endpoint, id, uri_action].filter(x => x).join('/')).params({action}).get(),
        method,
        data
    })
}

export type PhotoAction =
    | string

export const photoRequest = entityRequest<PhotoAction>('photos')
export const photosRequest = axiosRequest({
    for: "photos"
})

export type ContentAction =
    | string
export const contentRequest = entityRequest<ContentAction>('contents')
export const contentsRequest = axiosRequest({
    for: "contents"
})

export const paymentStatsRequest = axiosRequest({
    for: "payment_stats"
})


export interface RestResponse{
    data?: {
        data?: {
            data?: object
        }
    },
    status: number
}
