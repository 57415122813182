import {usePhotosApi} from "../../api/photos";
import {PhotoCrop, usePhotoCrop} from "../photo-crop";
import {PhotoSrc} from "../../pages/users/components/user-page/photos-section";
import React, {useState} from "react";
import {Button, Confirm} from "semantic-ui-react";

export const useConfirm = (onConfirm=undefined, onCancel=undefined) => {
    const [open, setOpen] = useState(false)
    const methods = {
        open,
        onCancel: () => {
            onCancel && onCancel()
            setOpen(false);
        },
        onConfirm: () => {
            onConfirm && onConfirm();
            setOpen(false);
        }
    }

    return {methods,
        setOpen, open,
        showConfirmationModal: () => setOpen(true)
    }
}

export const OriginalImageCrop = ({photo}) => {
    const api = usePhotosApi(photo.id);

    const handleCrop = () => {
        api.cropOriginalPhoto(photoCrop.cropValues)
    };

    const {methods, ...confirmState} = useConfirm(handleCrop);
    const photoCrop = usePhotoCrop(photo, {
        initPhotoCrop: {aspect: 0},
        aspect: 0
    });

    return <>
        <Confirm
            {...methods}
            header='Czy na pewno przyciąć zdjęcie?'
            content='Tej operacji nie będzie można cofnąć!'
            confirmButton={"Przytnij"}
            cancelButton={"Anuluj"}
        />
        <Button content={"Przytnij"} onClick={confirmState.showConfirmationModal}/>
        <div>
            <PhotoSrc fetchSize={'original'} cropped={0} photo_id={photo.id} render={({ src, loading }) => (
                <PhotoCrop src={src} photoCrop={photoCrop} />
            )} />
        </div>
    </>
}