import React from "react";
import {Button, Divider, Form, Header, Message} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {Checkbox, InputField, SelectField} from 'react-semantic-redux-form';
import {getAdminStore, getRoles} from "../../store/createRootReducer";
import {getAdmin} from "../../modules/admin";
import {connect} from "react-redux";
import {PageContainer} from "../page/PageContainer";

export const EditAdminView = ({error, handleSubmit, roles, isNew}) => (
    <PageContainer name={isNew ? 'Dodaj administratora' : 'Edytuj administratora'}>
        <Divider hidden/>
        <Form autoComplete="off" error={error}>
            {error &&
            <Message
                error
                content={'Wystąpił błąd'}/>
            }

            <Header as="h3" dividing>
                Informacje
            </Header>

            <Form.Group widths="equal">
                <Field name={'name'} component={InputField} label={'Imię'}/>
                <Field name={'email'} component={InputField} label={'Email'}/>
                <Field
                    component={SelectField}
                    name='role'
                    label="Rola"
                    options={roles.map(role=>({
                        key: role.id,
                        value: role.name,
                        text: role.name,
                    }))}
                    placeholder="Wybierz rolę"/>

            </Form.Group>
            <Field
                component={Checkbox}
                name='logging'
                label="Logowanie aktywności"
            />
            <Divider hidden/>
            <Form.Group widths="equal">
                <Field name={'password'} component={InputField} label={'Hasło'}/>
            </Form.Group>

            <Button type='button' id='submitFormButton' onClick={handleSubmit}>Zapisz</Button>
        </Form>
    </PageContainer>
);

export const EditAdminFormComponent = reduxForm({
    form: 'adminEdit',
    enableReinitialize: true,
})(EditAdminView);

export const EditAdminForm = connect(state=>({
    roles: getRoles(state),
    initialValues: getAdmin(getAdminStore(state)),
}))(EditAdminFormComponent);