import {applyMiddleware, compose, createStore} from 'redux'
import thunk from "redux-thunk";
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router'

import {createBrowserHistory} from 'history'
import createRootReducer from './createRootReducer';
import {loadState, saveState} from "./local-state";
import rootSaga from "./sagas";

export const history = createBrowserHistory();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
    const persistedState = loadState();

    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(
        createRootReducer(history), // root reducer with router state
        persistedState,
        storeEnhancers(
            applyMiddleware(
                thunk,
                routerMiddleware(history),
                sagaMiddleware
            )
        )
    );
    const rootTask = sagaMiddleware.run(rootSaga);
    rootTask.toPromise().catch(function (err) { console.log("Error in Sagas", err.constructor.name); });
    store.subscribe(() => {
        saveState({
            root: store.getState().root
        });
    });


    return store
}