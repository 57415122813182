import {Pagination as SemanticPagination, Segment} from "semantic-ui-react";
import React from "react";

export const Pagination = ({pagination={current_page: 1, last_page: 10}, onChange}) => (
    <Segment basic textAlign={"center"}>
        <SemanticPagination activePage={pagination.current_page}
                    totalPages={pagination.last_page}
                    onPageChange={(e, { activePage })=>onChange(activePage)}
        />
    </Segment>
);