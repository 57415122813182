import {UserName} from "../../../components/User/UserName";
import Moment from "moment";
import {TableFromArray} from "../../../components/stateless/Table";
import React, {useState} from "react";
import {Button, List, Table, Transition} from "semantic-ui-react";
import {useUsersRouter, useAppRouter} from "../../../hooks/router";

function ConnectedUsers({ data }) {
    const initial_limit = 5
    const [limit, setLimit] = useState(initial_limit)
    return <div>
        <Transition.Group
            as={List}
            duration={400}
            divided
            directional
        >
            {data.connected_users?.slice(0, limit).map(user =>
                <div style={{ marginLeft: '20px' }}>
                    <UserName user={user} gender banned deleted messages />
                </div>)}
        </Transition.Group>

        {data.connected_users.length > initial_limit ? (
            <Button size={"mini"} content={limit === initial_limit ? 'Pokaż więcej' : 'Ukryj'} onClick={() => setLimit(prevState => prevState === initial_limit ? 100 : initial_limit)} />
        ) : ''}
    </div>;
}

export default function UsersTable({ users }) {
    const usersRouter = useUsersRouter();
    const appRouter = useAppRouter();
    return <TableFromArray data={users} columns={[
        ['Login', (data) => <>
            <UserName user={data} gender banned deleted messages/>
            <ConnectedUsers data={data} />
            { data.deleted_at ? 
                <div>{Moment(data.deleted_at).format('Y-MM-DD HH:mm')}<br/>{data.delete_reason}</div>
                : ''
            }
        </>],
        ['E-mail', (data) => getUserEmailCell(data, appRouter)],
        ['IP', (data) => getUserIp(data, usersRouter)],
        ['Typ konta', (data) => {
            if(data.premium_type == 'female premium') return 'premium kobiet';
            else if(data.premium_type == 'free premium') return 'darmowe premium';
            else if(data.premium_payment == 'manual') return `${data.premium_type} ręczne`;
            else return data.premium_type;
        }],
        ['Kraj', (data) => (data.country && data.country.name)],
        ['WWW', (data) => getDomainAndBrowser(data)],
        ['Rejestracja', (data) => getTwoLineDate(data.created_at)],
        ['Online', (data) => getTwoLineDate(data.last_online)],
        ['Wiadomości', (data) => getMessagesLines(data)]
        // ['Zweryf.', (data) => (data['approved_profile'] ? "Tak" : "Nie")],
    ]} />
}

export function UsersGroupTable({groups}){
    const usersRouter = useUsersRouter();
    const appRouter = useAppRouter();
    return (
        <Table celled compact structured>
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell content={"-"}/>
                    <Table.HeaderCell content={'Login'}/>
                    <Table.HeaderCell content={'E-mail'}/>
                    <Table.HeaderCell content={'IP'}/>
                    <Table.HeaderCell content={'Typ konta'} collapsing/>
                    <Table.HeaderCell content={'Kraj'}/>
                    <Table.HeaderCell content={'WWW'}/>
                    <Table.HeaderCell content={'Rejestracja'}/>
                    <Table.HeaderCell content={'Online'} collapsing/>
                    <Table.HeaderCell content={'Zweryf.'}/>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {groups.map((g, g_index) => (
                        g.users?.map((u, index) => (
                            <Table.Row style={{background: g_index % 2 ? 'rgba(213,213,213,0.48)' : ''}}>
                                {index === 0 ? (
                                    <Table.Cell rowSpan={g.users.length} selectable collapsing textAlign={"center"}></Table.Cell>
                                ) : ''}
                                <Table.Cell collapsing>
                                    <UserName user={u} gender banned deleted messages/>
                                    { u.deleted_at ? 
                                        <div>{Moment(u.deleted_at).format('Y-MM-DD HH:mm')}<br/>{u.delete_reason}</div>
                                        : ''
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {getUserEmailCell(u, appRouter)}
                                </Table.Cell>
                                <Table.Cell>
                                    {getUserIp(u, usersRouter)}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {u.premium_type}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {u.country?.name}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {getDomainAndBrowser(u)}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {getTwoLineDate(u.created_at)}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {getTwoLineDate(u.last_online)}
                                </Table.Cell>
                                <Table.Cell collapsing textAlign={'center'}>
                                    {u['approved_profile'] ? "Tak" : "Nie"}
                                </Table.Cell>
                            </Table.Row>
                        ))

                ))}
            </Table.Body>
        </Table>
    )
}


export const getUserIp = (data, usersRouter) => {
    if (data.last_ip && data.last_ip != '0.0.0.0')
        return <div>
            <div>
                <span onClick={() => usersRouter.goToUsersPage(false, { ip: data.last_ip })} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {data.last_ip}
                </span>:{data.last_port || 0} [last]</div><div>{data.last_host_name}
            </div>
        </div>;
    else
        return <div>
            <div>
                <span onClick={() => usersRouter.goToUsersPage(false, { ip: data.registration_ip })} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                    {data.registration_ip}
                </span>:{data.registration_port || 0} [last]</div><div>{data.registration_host_name}
            </div>
        </div>;
};

export const getTwoLineDate = (value) => {
    if (value) {
        const date = Moment(value);
        return <div>{date.format('Y-MM-DD')}<div>{date.format('HH:mm')}</div></div>
    }
};

export const getDomainAndBrowser = (data) => {
    return `.${data.domain_lang}_${data.browser_lang}${data.from_ads ? '_adw' : ''}`;
}

export const getUserEmailCell = (data, appRouter) => {
    return <span onClick={() => appRouter.goToSendMailPage({email: data.email}, false)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{data.email}</span>
}

const getMessagesLines = (data) => {
    return <div>
        { (data.limited_sent != null || data.limited_received != null) ? <div>{ data.limited_sent } / { data.limited_received }</div> : '' }
        <div>{ data.total_sent } / { data.total_received } / { data.total_threads }</div>
    </div>
}