import * as modules from "./main_module";
import {api_url} from "../utils";

export const THREADS = 'suggestion_threads';
export const MESSAGES = 'suggestion_messages';
export const TEMPLATES = 'suggestion_templates';

export const url = (id=null) => api_url('admin/contactMessages', id);

export const api_call_threads = (method, url, body={}, functions={}) => modules.api_call(THREADS, method, url, body, functions);
export const getModuleThreads = modules.getModule(THREADS);
export const getThreads = modules.getData(THREADS);
export const getMetaThreads = modules.getMeta(THREADS);

export const api_call_messages = (method, url, body={}, functions={}) => modules.api_call(MESSAGES, method, url, body, functions);
export const getMessages = () => modules.getData(MESSAGES);
export const getMetaMessages = () => modules.getMeta(MESSAGES);

export const getAdditional = modules.getAdditional(THREADS);

export const updateAdditional = (key, value) => modules.update(THREADS, 'additional', {[key]: value});
export const fetchThreads = (body={}) => modules.api_call(THREADS,'get', url(), body);
export const fetchThread = (id, body={}) => modules.api_call(MESSAGES, 'get', url(id), {per_page:999, ...body});

export const fetchTemplates = () => modules.api_call(TEMPLATES, 'get', api_url('admin/templates'), {type: 'contactMessages'});
export const getTemplates = modules.getModule(TEMPLATES);