import { createAction } from "../utils";
import { handleActions } from "redux-actions";
import { getUserDescriptionsStore } from "../store/createRootReducer";
import { put, select, takeEvery } from "@redux-saga/core/effects";
import { fetchApi } from "./app";
import { API } from "../api-endpoints";

const FETCH_USER_DESCRIPTIONS = 'app/user_descriptions/FETCH_USER_DESCRIPTIONS';
const RESET = 'app/user_descriptions/RESET';
const UPDATE = 'app/user_descriptions/UPDATE';

export const fetchUserDescriptions = () => createAction(FETCH_USER_DESCRIPTIONS);
const reset = (key, value) => createAction(RESET, { key, value });
const update = (key, value) => createAction(UPDATE, { key, value });

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetUserDescriptionsQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({ current_page: page });

export const filters = {
  profile_desc: {},
  photo_desc: {
      'action': 'photos'
  },
  profile_name: {
      'action': 'name'
  },
};

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
  id: undefined,
  user_descriptions: [],
  meta: { current_page: 1, last_page: 1, per_page: 100 },
  filter: filters.profile_desc,
  query: {}
};

export default handleActions({
  [RESET]: (state, action) => {
    return { ...state, [action.payload.key]: action.payload.value }
  },
  [UPDATE]: (state, action) => {
    return { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload.value } }
  },
},
  initialState
)

export const getUserDescriptions = (state) => getUserDescriptionsStore(state).user_descriptions;
export const getUserDescriptionsMeta = (state) => getUserDescriptionsStore(state).meta;
export const getUserDescriptionsQuery = (state) => getUserDescriptionsStore(state).query;
export const getUserDescriptionsFilter = (state) => getUserDescriptionsStore(state).filter;

export function* callFetchUserDescriptions() {
  let filter = yield select(getUserDescriptionsFilter);
  let { current_page: page, per_page } = yield select(getUserDescriptionsMeta);

  const query = yield select(getUserDescriptionsQuery);
  const response = yield fetchApi(API.user_descriptions(), {...filter, page, per_page, ...query }, 'get');
  yield put(reset('user_descriptions', response.data.data));
  yield put(resetMeta(response.data.meta));
}

export function* userDescriptionsSaga() {
  yield takeEvery(FETCH_USER_DESCRIPTIONS, callFetchUserDescriptions);
}