import {handleActions} from "redux-actions";
import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {createAction} from "../utils";
import {API} from "../api-endpoints";
import {getUserId} from "./user";
import {apiAuthRequest} from "../store/sagas";
import {getChatStore} from "../store/createRootReducer";


const SAGA_FETCH_MESSAGES = 'app/chat/SAGA_FETCH_MESSAGES';
const SAGA_FETCH_THREADS = 'app/chat/SAGA_FETCH_THREADS';

const RESET = 'app/chat/RESET';
const UPDATE = 'app/chat/UPDATE';

export const reset = (key, value) => createAction(RESET, {key, value});
export const update = (key, value) => createAction(UPDATE, {key, value});

export const fetchMessages = (thread_id) => createAction(SAGA_FETCH_MESSAGES, {thread_id});
export const fetchThreads = () => createAction(SAGA_FETCH_THREADS);

export const resetMessages = (data=[]) => reset('messages', data);
export const resetParticipants = (data=[]) => reset('participants', data);
export const resetThreads = (data=[]) => reset('threads', data);
export const resetMeta = (data={current_page: 1, last_page: 1}) => reset('meta', data);
export const changePage = (page=1) => update('meta', {current_page: page});

const initialState = {
    thread_id: undefined,
    messages: [],
    participants: [],
    threads: [],
    meta: {current_page: 1, last_page: 1},
};


export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
    },
    initialState
)

export const getThreadId = (state) => getChatStore(state).thread_id;
export const getMessages = (state) => getChatStore(state).messages;
export const getMessagesMeta = (state) => getChatStore(state).meta;
export const getParticipants = (state) => getChatStore(state).participants;
export const getThreads = (state) => getChatStore(state).threads;


function* callFetchThreads(action) {
    const response = yield call(apiAuthRequest, API.GET_THREADS, {
        user_id: yield select(getUserId)
    });
    yield put(resetThreads(response.data.data));
}

function* callFetchMessages(action) {
    const {thread_id} = action.payload;
    yield put(reset('thread_id', thread_id));

    const {current_page: page} = yield select(getMessagesMeta);

    const response = yield call(apiAuthRequest, API.GET_MESSAGES, {
        user_id: yield select(getUserId),
        thread_id,
        page
    });
    yield put(resetParticipants(response.data.participants));
    yield put(resetMessages(response.data.data));
    yield put(resetMeta(response.data.meta));
}

export function* chatSaga() {
    yield takeEvery(SAGA_FETCH_THREADS, callFetchThreads);
    yield takeEvery(SAGA_FETCH_MESSAGES, callFetchMessages);
}