import React from 'react';
import {connect} from "react-redux";
import {Container} from "semantic-ui-react";
import {apiGetOrders, getOrders} from "../../modules/orders";
import {OrderForm} from "../../components/forms/order-form";

export class EditOrderComponent extends React.Component{
    constructor(props) {
        super(props);
        props.apiGetPayments(props.match.params.id);
    }

    render() {
        return <Container>
            <OrderForm initialValues={this.props.payment}/>
        </Container>
    }
}

const mapStateToProps = (state) => {
    return {
        payment: getOrders(state),
    };
};

export default connect(mapStateToProps, {apiGetPayments: (id) => apiGetOrders(id, null)})(EditOrderComponent);
