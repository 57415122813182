import {handleActions} from "redux-actions";
import {call, put, takeEvery} from "@redux-saga/core/effects";
import {createAction} from "../utils";
import {API} from "../api-endpoints";
import {startSubmit, stopSubmit} from "redux-form";
import {apiSafeCall} from "../utils/apiCallWrapper";
import {push} from "react-router-redux";
import {ROUTE} from "../route-names";
import {fetchApi} from "./app";


const SAGA_FETCH_ADMIN = 'app/admin/SAGA_FETCH_ADMIN';

const RESET_ADMIN = 'app/admin/RESET_ADMIN';
const TOGGLE_EDIT = 'app/admin/TOGGLE_EDIT';
const SUBMIT_FORM = 'app/admin/SUBMIT_FORM';

export const fetchAdmin = (id) => createAction(SAGA_FETCH_ADMIN, {id});
export const resetAdmin = (data) => createAction(RESET_ADMIN, {data});
export const toggleEditAdmin = (state=undefined) => createAction(TOGGLE_EDIT, {state});
export const submitFormAdmins = (form_id, values) => createAction(SUBMIT_FORM, {form_id, values});

const initialState = {
    data: {
        id: undefined,
    },
    edit: false,
};


export default handleActions({
        [RESET_ADMIN]: (state, action) => {
            return {...state, data: action.payload.data}
        },
        [TOGGLE_EDIT]: (state, action) => {
            const edit = action.payload.state !== undefined ? action.payload.state : !isAdminEdit(state);
            return {...state, edit}
        },
    },
    initialState
)

export const getAdmin = state => state.data;
export const isAdminEdit = state => state.edit;

function* callFetchAdmin(action) {
    let url = API.admin(action.payload.id);
    const response = yield call(fetchApi, url, {}, 'get');
    yield put(resetAdmin(response.data));
}

function* callSubmitForm(action) {
    const {form_id, values} = action.payload;
    yield apiSafeCall(function* (){
        yield put(startSubmit(form_id));
        const {id} = values;
        let uri = API.admin(id);
        console.log('ID', id, uri);
        try {
            const response = yield fetchApi(uri, {
                ...values
            }, id ? 'patch' : 'post');

            if(id){
                yield put(resetAdmin(values));
                yield put(toggleEditAdmin());
            }else{
                const newId = response.data.data.id;
                yield put(push(ROUTE.admin(newId)))
            }
        }catch (e) {
            const errors = e.response.data.errors;
            if(errors){
                yield put(stopSubmit(form_id, errors))
            }else{
                throw e;
            }
        }
    })

}

export function* adminSaga() {
    yield takeEvery(SAGA_FETCH_ADMIN, callFetchAdmin);
    yield takeEvery(SUBMIT_FORM, callSubmitForm);
}