import React, {useEffect, useMemo, useState} from "react";
import ReactCrop from "react-image-crop";
import {pick} from "lodash";

export const PhotoCrop = ({src, photoCrop}) => {
    const {
        crop, setCrop,
        setImageSizes,
    } = photoCrop;

    return (
        <ReactCrop style={{maxWidth: "100%"}} imageStyle={{maxWidth: "100%"}} onImageLoaded={setImageSizes} src={src} crop={crop}
                   onComplete={crop => {
                       setCrop(prevState => {
                           if (prevState.width && crop.width === 0)
                               return prevState;
                           return crop;
                       })
                   }} onChange={setCrop}/>
    )
}

export const usePhotoCrop = (initPhoto={}, {initPhotoCrop, aspect} = {}) => {
    aspect = aspect ?? 1;

    const [photo, setPhoto] = useState(initPhoto);
    const [crop, setCrop] = useState({});
    const [imageSizes, setImageSizes] = useState({});

    const scale = useMemo(() => imageSizes.naturalHeight / imageSizes.clientHeight, [imageSizes.clientHeight])

    useEffect( () => {
        if(scale){
            const calculateValue = (old) => Math.trunc(old / scale)

            setCrop({
                unit: 'px',
                x: calculateValue(photo.crop_x),
                y: calculateValue(photo.crop_y),
                width: calculateValue(photo.crop_width),
                height: calculateValue(photo.crop_height),
                aspect,
            })
        }
    }, [scale, photo]);

    const cropValues = useMemo( () => {
        let crop_values = pick(crop, ['x', 'y', 'width', 'height']);
        Object.keys(crop_values).map(k => crop_values[k] = Math.trunc(crop_values[k] * scale))
        return crop_values
    }, [crop, scale]);

    return {
        cropValues,
        crop, setCrop,
        imageSizes, setImageSizes,
        scale,
        photo, setPhoto,
    }
}
