import {handleActions} from "redux-actions";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {goBack, replace} from "react-router-redux";
import {apiQuery, createAction} from "../utils";
import {getUsersStore} from "../store/createRootReducer";
import {API} from "../api-endpoints";
import {resetUser} from "./user";
import {fetchApi} from "./app";
import {ROUTE} from "../route-names";
import {startSubmit, stopSubmit} from "redux-form";

const API_SAVE_USER = 'app/users/API_SAVE_USER';
const API_EDIT_USER = 'app/users/API_EDIT_USER';
const DELETE_USER = 'app/users/DELETE_USER';
const FETCH_USERS = 'app/users/FETCH_USERS';
const RESET = 'app/users/RESET';
const UPDATE = 'app/users/UPDATE';

export const apiSaveUser = (values, form) => createAction(API_SAVE_USER, {values, form});
export const apiEditUser = (id, data) => createAction(API_EDIT_USER, {id, data});
export const fetchUsers = () => createAction(FETCH_USERS);
export const deleteUser = () => createAction(DELETE_USER);
const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetUsersQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({current_page: page});

export const filters = {
    all: {trashed: 'with', frozen: 'with'},
    unverified: {approved_profile: 0},
    verified: {approved_profile: 1},
    last_online: {last_online: 2},
    banned: {banned: 1},
    ban24: {ban_type: 24},
    ban48: {ban_type: 48},
    ban72: {ban_type: 72},
    deleted: {trashed: 'only'},
    unchanged_password: {unchanged_password: 1},
    changed_password: {changed_password: 1}
};

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
    id: undefined,
    users: [],
    meta: {current_page: 1, last_page: 1},
    filter: filters.all,
    query: {}
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
    },
    initialState
)

export const getUsers = (state) => getUsersStore(state).users;
export const getUsersMeta = (state) => getUsersStore(state).meta;
export const getUsersQuery = (state) => getUsersStore(state).query;
export const getUsersFilter = (state) => getUsersStore(state).filter;

function* callFetchUsers() {

    let filter = yield select(getUsersFilter);
    let {current_page: page} = yield select(getUsersMeta);

    const query = yield select(getUsersQuery);

    const url_query = apiQuery().for('users').where(query).page(page).limit(10).where(filter)
    const response = yield fetchApi(url_query.get(), {}, 'get');
    yield put(reset('users', response.data.data));
    yield put(resetMeta({
        current_page: response.data.meta.current_page,
        last_page: response.data.meta.last_page,
    }));
}

export function* callApiSaveUser(action){
    const {values, form} = Object.freeze(action.payload);
    const edit = !!values.id;
    const uri =  API.user(values.id);
    values.looking_for = Array(values.looking_for).filter(x => !!x).join(',');

    try{
        yield put(startSubmit(form));
        const response = yield call(fetchApi, uri, values, edit ? 'put' : 'post');

        if(values.id){
            yield put(resetUser(values));
            yield put(goBack());
        }
        else{
            yield put(replace(ROUTE.user(response.data.data.id)))
        }
        yield put(stopSubmit(form))

    }catch (e) {
        console.log(e.response.data.errors);
        yield put(stopSubmit(form, e.response.data.errors));
    }
}

function* callEditUser(action){
    const {id, data} = action.payload;
    const response = yield call(fetchApi, API.user(id), data, 'put');
    yield put(resetUser(response.data.data));
}

export function* usersSaga() {
    yield takeEvery(FETCH_USERS, callFetchUsers);
    yield takeEvery(API_SAVE_USER, callApiSaveUser);
    yield takeEvery(API_EDIT_USER, callEditUser);
}