import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {CommentsView} from "../comments";
import {changePage, fetchComments, resetCommentsQuery} from "../../modules/comments";
import {useParams} from "react-router";

export const UserCommentsPage = () => {
    const dispatch = useDispatch();

    const {id} = useParams();

    useEffect(()=>{
        dispatch(changePage(1));
        dispatch(resetCommentsQuery({author_id: id}));
        dispatch(fetchComments());

        return () => {
            dispatch(resetCommentsQuery({}));
            dispatch(changePage(1));
        }
    }, [id, dispatch]);

    return <CommentsView/>
};