import connect from "react-redux/es/connect/connect";
import Pages from "../components/stateless/table/Pages";
import {getAdminsMeta} from "../modules/admins";

const mapStateToProps = (state) => {
    const {current_page, last_page} = getAdminsMeta(state);
    return {
        numbers: Array.from({length: last_page}, (v, k) => k+1),
        active_page: current_page
    };
};

export default connect(mapStateToProps)(Pages);