import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Divider, Header, Pagination, Segment, Popup } from "semantic-ui-react";
import Moment from 'moment';
import { SelectableTableFromArray } from "../../components/stateless/Table";
import * as UsersPlansModule from "../../modules/users_plans";
import { changePage, fetchUsersPlans, getUsersPlans, getUsersPlansMeta, setFilter } from "../../modules/users_plans";
import { UserName } from "../../components/User/UserName";
import { api_url } from "../../utils";
import { request } from "../../modules/app";

const are_filters_equal = (filter1, filter2) => {
  return (filter1 && filter2 &&
    filter2[Object.keys(filter1)[0]] === filter1[Object.keys(filter1)[0]] &&
    Object.keys(filter1).length
  ) &&
    (
      Object.keys(filter1).length !== 0
    );
}

export const ButtonSetFilter = ({ actual_filter, setFilter, filter, content }) => (
  <Button size='mini' id='filter_comments' color={are_filters_equal(filter, actual_filter) ? 'green' : null} floated='left' onClick={() => setFilter(filter)} content={content} />
);

const Filter = () => {
  const dispatch = useDispatch();
  const actual_filter = useSelector(UsersPlansModule.getUsersPlansFilter);

  const filters = [
    ['Wszystkie', UsersPlansModule.filters.all],
    ['Aktywne', UsersPlansModule.filters.active],
    ['Niestandardowe', UsersPlansModule.filters.custom]
  ];

  const setFilterAndFetchUsersPlans = (filter) => {
    dispatch(setFilter(filter));
    dispatch(changePage(1));
    dispatch(fetchUsersPlans());
  };

  return <Button.Group floated={'left'}>
    {filters.map((filter) => (
      <ButtonSetFilter content={filter[0]} actual_filter={actual_filter} filter={filter[1]} setFilter={setFilterAndFetchUsersPlans} />
    ))}
  </Button.Group>
};

const UsersPlansSection = ({ view, usersPlans }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const deletePlan = (id) => {
    setLoading(true)
    request('delete', api_url(`admin/users_plans/${id}`), {}).then(res => {
      dispatch(fetchUsersPlans())
    })
  }

  useEffect(() => {
    setLoading(false)
    setSelected([]);
  }, [usersPlans])

  const RejectSelectedButton = useCallback(() =>
    <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Usuń (${selected.length})`} />}>
      <div>Czy na pewno usunąć wybrane zachcianki?</div>
      <Button onClick={() => {
        selected.forEach(planId => {
          deletePlan(planId)
        })
      }} content={'Usuń'} loading={loading} disabled={loading} />
    </Popup>
    , [usersPlans, selected, loading])


  return <>
    <RejectSelectedButton />
    <SelectableTableFromArray selected={selected} setSelected={setSelected} data={usersPlans} warningConditionField={'suspect'} warningConditionValue={1} columns={[
      ['Użytkownik', (data) => <UserName user={data.user} premium_icon={false} hot_icon={false} gender country account_type />],
      ['Odpowiedzi', (data) => {
        return <>Ilość: {data.responses.length}
          <div style={{ marginTop: '10px' }}>
            {Array.isArray(data.responses) ?
              data.responses.map(function (user) {
                return <div><UserName user={user} premium_icon={false} hot_icon={false} gender country account_type /></div>
              }) : 'Brak danych'}
          </div>
        </>
      }],
      ['Typ', (data) => {
        switch (+(data.type)) {
          case 1: return 'Kino';
          case 2: return 'Teatr';
          case 3: return 'Bieganie';
          case 4: return 'Spacer';
          case 5: return 'Kolacja';
          case 6: return 'Imprezka';
          case 0: return 'Własna zachcianka';
          default: return '';
        }
      }],
      ['Własna treść', (data) => data.custom_plan ? data.custom_plan : '-'],
      ['Data zakończenia', (data) => Moment(data.end_time).format('Y-MM-DD HH:mm')],
      ['Aktywny', (data) => data.active ? 'Tak' : 'Nie'],
    ]} />
  </>
};

export const UsersPlansView = () => {
  let usersPlans = useSelector(getUsersPlans);
  const [view, setView] = useState(0);
  const meta = useSelector(getUsersPlansMeta);
  const dispatch = useDispatch();

  const setPageAndFetchUsersPlans = (page) => {
    dispatch(changePage(page));
    dispatch(fetchUsersPlans());
  };

  return <Container style={{ width: '99%' }}>
    <Divider hidden />
    <Header as='h2'>Plany użytkowników</Header>
    <Divider />
    <Filter />
    <Divider hidden />
    <UsersPlansSection view={view} usersPlans={usersPlans} />
    <Divider />
    <Segment basic textAlign={"center"}>

      <Pagination activePage={meta ? meta.current_page : 1}
        totalPages={meta ? meta.last_page : 2}
        onPageChange={(e, { activePage }) => setPageAndFetchUsersPlans(activePage)}
      />
    </Segment>
  </Container>;
};

export const UsersPlans = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersPlans());
  }, [dispatch]);

  return <UsersPlansView />
};