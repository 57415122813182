import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {reducer as formReducer} from 'redux-form'
import root from "../modules/app"
import users from "../modules/users"
import user_descriptions from "../modules/user_descriptions"
import admins from "../modules/admins"
import user from "../modules/user";
import chat from "../modules/chat";
import admin from "../modules/admin";
import activities from "../modules/activities";
import roles from "../modules/roles";
import permissions from "../modules/permissions";
import photos from "../modules/photos";
import orders from "../modules/orders";
import payment_plans from "../modules/payment_plans";
import comments from "../modules/comments";
import verify_photos from "../modules/verify_photos";
import login_logs from "../modules/login_logs";
import users_plans from "../modules/users_plans";
import messages from "../modules/messages";
import filters from "../modules/filters";
import content from "../modules/content";
import payment_stats from "../modules/payment_stats";
import modules from "../modules/main_module";
import {createAction} from "../utils";


const RESET = 'app/RESET';

export const resetStore = (data=undefined) => createAction(RESET, {data});

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    root,
    users,
    admins,
    comments,
    user,
    chat,
    admin,
    activities,
    permissions,
    roles,
    photos,
    orders,
    payment_plans,
    verify_photos,
    user_descriptions,
    login_logs,
    users_plans,
    messages,
    filters,
    content,
    payment_stats,
    modules,
});

export default history => ( state, action ) => {
    if ( action.type === RESET ) {
        state = undefined;
    }

    return rootReducer(history)(state, action)
}

export const getRoles = state => state.roles;
export const getUserStore = state => state.user;
export const getPermissions = state => state.permissions;
export const getUsersStore = state => state.users;
export const getAdminsStore = state => state.admins;
export const getAdminStore = state => state.admin;
export const getChatStore = state => state.chat;
export const getPhotosStore = state => state.photos;
export const getOrdersStore = state => state.orders;
export const getPaymentPlansStore = state => state.payment_plans;
export const getCommentsStore = state => state.comments;
export const getVerifyPhotosStore = state => state.verify_photos;
export const getLoginLogsStore = state => state.login_logs;
export const getModulesStore = state => state.modules;
export const getUserDescriptionsStore = state => state.user_descriptions;
export const getUsersPlansStore = state => state.users_plans;
export const getMessagesStore = state => state.messages;
export const getFiltersStore = state => state.filters;
export const getContentStore = state => state.content;
export const getPaymentStatsStore = state => state.payment_stats;
