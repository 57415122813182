import {call, put, takeEvery} from 'redux-saga/effects';
import {handleActions} from "redux-actions";
import {createAction} from "../utils";
import * as axios from "axios";
import {API} from "../api-endpoints";
import {ROUTE} from "../route-names";
import {resetStore} from "../store/createRootReducer";
import {push, replace} from "react-router-redux";
import {getFormValues, startSubmit, stopSubmit} from "redux-form";
import {delay, fork, select} from "@redux-saga/core/effects";
import {wipeSubscribers} from "../store/axios-token-refresh";

const SUBMIT_FORM = 'app/app/SUBMIT_FORM';

const LOGIN = 'app/app/LOGIN';
const RESET = 'app/app/RESET';
const UPDATE = 'app/app/UPDATE';
const LOGOUT = 'app/app/LOGOUT';
const REFRESH_TOKEN = 'app/app/REFRESH_TOKEN';

export const submitForm = (form, url, method, data={}, onSuccess=null) => createAction(SUBMIT_FORM, {form, url, method, data, onSuccess});
export const apiLogin = (values, form_id) => createAction(LOGIN, {values,  form_id});
export const reset = (key, value) => createAction(RESET, {key, value});
export const resetLogoutTime = (time=60*60) => reset('logout_time', time + (Date.now() / 1000));
export const setLoggedIn = (log) => reset('logged_in', log);
export const logout = () => createAction(LOGOUT);

const initialState = {
    logged_in: false,
    user: {
        id: 0,
        name: undefined,
        email: undefined,
    },
    token_expired_in: 0,
    logout_time: 0,
};


export default handleActions({
    [RESET]: (state, action) => {
        return {...state, [action.payload.key]: action.payload.value}
    },
    [UPDATE]: (state, action) => {
        return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
    },
}, initialState)

export const getLogoutTime = state => state.root.logout_time - Date.now() / 1000;
export const getAdmin = state => state.root.user;
export const getLoggedIn = state => state.root.logged_in;

// Side effects Services
export function getAuthToken() {
    return JSON.parse(localStorage.getItem('authToken'))
}

export function setAuthToken(token) {
    localStorage.setItem('authToken', JSON.stringify(token))
}

export function removeAuthToken() {
    localStorage.removeItem('authToken')
}

function* callLogin(action) {
    const {values, form_id} = Object.freeze(action.payload);

    yield put(startSubmit(form_id));

    try{
        const response = yield call(fetchApi, API.LOGIN, values, 'post');
        yield call(setAuthToken, response.data.token);
        yield put(reset('user', response.data.data));
        yield put(resetLogoutTime());
        yield put(setLoggedIn(true));
        yield put(stopSubmit(form_id));
        yield put(replace(ROUTE.DASHBOARD));
    }catch (e) {
        yield put(stopSubmit(form_id, e.response.data));
    }
}

function* callLogout() {
    try {
        yield request('post', API.LOGOUT, {});
    }catch (e) {
        console.error(e);
    }
    removeAuthToken();
    yield put(resetStore());
    yield put(push(ROUTE.LOGIN));
    wipeSubscribers();
}

export function request(method, uri, data={}){
    const token = getAuthToken();
    if(method==='get'){
        return axios({
            url: uri,
            method,
            params: data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            }
        });
    }
    else{
        return  axios({
            url: uri,
            method,
            data,
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
            }
        });
    }
}

export function* fetchApi(uri, data, method){
    const token = yield call(getAuthToken);
    let response;
    try {
        if(method==='get'){
            response = yield axios({
                url: uri,
                method,
                params: data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + token,
                }
            });
        }
        else{
            response = yield axios({
                url: uri,
                method,
                data,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + token,
                }
            });
        }
        return response;
    }catch (e) {
        const response = e.response;
        if (response.status === 403){
            yield put(push(ROUTE.NO_PERMISSION));
            console.log('Brak pozwolenia');
        }else
            throw e;
    }
}

function* logout_time_decrement() {
    while(true){
        const logout_time = yield select(getLogoutTime);
        const logged_in = yield select(getLoggedIn);
        if(logout_time < 0 && logged_in)
            yield put(logout());
        yield delay(1000);
    }
}

function* refresh_token() {
    try{
        const response = yield call(fetchApi, API.REFRESH_TOKEN, {}, 'post');
        yield call(setAuthToken, response.data.token);
        yield put(resetLogoutTime(response.data.token_expired_in));
    }catch (e) {
    }
}

export function* saga_submit_form(url, form, method, data, onSuccess) {
    const form_data = yield select(getFormValues(form));

    yield put(startSubmit(form));
    try {
        yield fetchApi(url, {...form_data, ...data}, method);
        yield put(stopSubmit(form));
        if(onSuccess)
            onSuccess();
    } catch (e) {
        yield put(stopSubmit(form, e.response.data.errors));
    }
}

function* call_submit_form(action) {
    const {url, form, method, data, onSuccess} = action.payload;
    yield saga_submit_form(url, form, method, data, onSuccess);
}

export function* appSaga() {
    yield takeEvery(LOGIN, callLogin);
    yield takeEvery(LOGOUT, callLogout);
    yield takeEvery(REFRESH_TOKEN, refresh_token);
    yield takeEvery(SUBMIT_FORM, call_submit_form);
    yield fork(logout_time_decrement);
}

