import React, {useState} from "react";
import {Button, Container, Divider, Form, Header, Popup, Select} from "semantic-ui-react";
import { Pagination } from "../../components/tables/pagination";
import { useCustomPaginatedQuery } from "../../api/core";
import { contentsRequest } from "../../api/requests";
import { useContentApi } from "../../api/contents";
import { TableWithWrapperView } from "../../components/stateless/Table";
import { useAppRouter } from "../../hooks/router";
import { ROUTE } from "../../route-names";
import {domainOptions, emptyOption, languageOptions} from "../../constants";
import {changePage, resetQuery} from "../../modules/orders";
import {useDispatch} from "react-redux";

const DEFAULT_ORDER_BY = 'domain';
const DEFAULT_ORDER = 'desc';

const sortByOptions = [
  { value: 'domain', text: 'Domena' },
  { value: 'lang', text: 'Język' },
  { value: 'region_id', text: 'Województwo' },
  { value: 'city_id', text: 'Miasto' }
].map((status, i) => ({ ...status, key: `cblsk-${i}` }));

const sortOrdersOptions = [
  { value: 'asc', text: 'Rosnąco' },
  { value: 'desc', text: 'Malejąco' },
].map((status, i) => ({ ...status, key: `cblsok-${i}` }));

export const Filters = ({ onChange }) => (
    <Form>
      <Form.Group>
        <Form.Field
            name={'domain'}
            onChange={onChange}
            control={Select}
            options={[emptyOption, ...domainOptions]}
            label={{ children: 'Domena', htmlFor: 'form-select-control-status' }}
            placeholder='Domena'
            search
            searchInput={{ id: 'form-select-control-domain' }}
        />
        <Form.Field
            name={'lang'}
            onChange={onChange}
            control={Select}
            options={[emptyOption, ...languageOptions]}
            label={{ children: 'Język', htmlFor: 'form-select-control-language' }}
            placeholder='Język'
            search
            searchInput={{ id: 'form-select-control-language' }}
        />
        <Form.Field
            name={'orderBy'}
            defaultValue={DEFAULT_ORDER_BY}
            onChange={onChange}
            control={Select}
            options={sortByOptions}
            label={{ children: 'Sortuj wg', htmlFor: 'form-select-control-sort_by' }}
            placeholder='Sortuj wg'
            search
            searchInput={{ id: 'form-select-control-sort_by' }}
        />
        <Form.Field
            name={'order'}
            defaultValue={DEFAULT_ORDER}
            onChange={onChange}
            control={Select}
            options={sortOrdersOptions}
            label={{ children: 'Typ sortowania', htmlFor: 'form-select-control-sort_order' }}
            placeholder='Typ sortowania'
            search
            searchInput={{ id: 'form-select-control-sort_order' }}
        />
      </Form.Group>
    </Form>
);

export const ContentsList = ({ contents }) => {
  const api = useContentApi();
  const { history } = useAppRouter();

  return <TableWithWrapperView data={contents} columns={{
    'title': {
      name: 'Tytuł'
    },
    'lang': {
      name: 'Język'
    },
    'domain': {
      name: 'Strona'
    },
    'region': {
      name: 'Województwo (strona)',
      wrapper: (data) => data.region_name && `${data.region_name} ${data.region_priority ? `(${data.region_priority})` : ''}`
    },
    'city': {
      name: 'Miasto (strona)',
      wrapper: (data) => data.city_name && `${data.city_name} ${data.city_priority ? `(${data.city_priority})` : ''}`
    },
    'options': {
      name: 'Opcje',
      wrapper: (data) => {
        return <>
          <Popup on={"click"} trigger={<Button content={'Usuń'} />}>
            Czy na pewno usunąć ten artykuł?
            <Button onClick={() => api.delete(data.id)} content={'Usuń'} />
          </Popup>
          <Button content={'Edytuj'} onClick={() => history.push(ROUTE.contentByLocation(data.id))} />
        </>
      }
    }
  }} />
};

export const ContentsView = ({ contents, pagination, onChangePage, page }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    orderBy: DEFAULT_ORDER_BY,
    order: DEFAULT_ORDER
  });

  React.useEffect(() => {
    dispatch(resetQuery(query));
    dispatch(changePage(1, query));
  }, [dispatch, query]);

  const handleChange = (e, { name, value }) => {
    const new_query = { ...query, [name]: value };
    if (!value) {
      delete new_query[name];
    }
    setQuery(new_query);
    onChangePage(page, new_query)
  };
  const handlePageChange = page => {
    onChangePage(page, query)
  }

  return <>
    <Filters data={query} onChange={handleChange} />
    <ContentsList contents={contents} />
    <Pagination pagination={pagination} onChange={handlePageChange} />
  </>
};

export const ContentsByLocation = () => {
  const defaultFilters = {
    contentsByLocation: true
  }
  const { data: contents, setPage, pagination, setFilters, page } = useCustomPaginatedQuery('contents', contentsRequest, {
    initConfig: { perPage: 1000, page: 1, orderBy: DEFAULT_ORDER_BY, order: DEFAULT_ORDER, filters: defaultFilters }
  });

  const { history } = useAppRouter();
  
  const changePageAndFetch = (page, query) => {
    setFilters({
      ...defaultFilters,
      ...query
    });
    setPage(page);
  };

  return <Container style={{ width: '95%' }}>
    <Divider hidden />
    <Header as='h2'>
      Artykuły <Button content={'Dodaj'} onClick={() => history.push(ROUTE.CONTENT_BY_LOCATION_NEW)}/>
    </Header>

    <ContentsView
      page={page}
      contents={contents}
      pagination={pagination}
      onChangePage={changePageAndFetch}
    />
  </Container>
};

export default ContentsByLocation;
