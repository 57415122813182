import {all, call, spawn} from "redux-saga/effects"

import {activitiesSaga} from "../modules/activities";
import {adminSaga} from "../modules/admin";
import {adminsSaga} from "../modules/admins";
import {appSaga, fetchApi} from "../modules/app";
import {chatSaga} from "../modules/chat";
import {permissionsSaga} from "../modules/permissions";
import {rolesSaga} from "../modules/roles";
import {userSaga} from "../modules/user";
import {usersSaga} from "../modules/users";
import {userDescriptionsSaga} from "../modules/user_descriptions";
import {photosSaga} from "../modules/photos";
import {ordersSaga} from "../modules/orders";
import {paymentPlansSaga} from "../modules/payment_plans";
import {commentsSaga} from "../modules/comments";
import {verifyPhotosSaga} from "../modules/verify_photos";
import {loginLogsSaga} from "../modules/login_logs";
import {usersPlansSaga} from "../modules/users_plans";
import {messagesSaga} from "../modules/messages";
import {filtersSaga} from "../modules/filters";
import {contentsSaga} from "../modules/content";
import {paymentStatsSaga} from "../modules/payment_stats";
import {moduleSaga} from "../modules/main_module";


export default function* rootSaga() {
    const sagas = [
        activitiesSaga,
        adminSaga,
        adminsSaga,
        appSaga,
        chatSaga,
        permissionsSaga,
        rolesSaga,
        userSaga,
        usersSaga,
        userDescriptionsSaga,
        photosSaga,
        ordersSaga,
        paymentPlansSaga,
        commentsSaga,
        verifyPhotosSaga,
        loginLogsSaga,
        usersPlansSaga,
        messagesSaga,
        filtersSaga,
        contentsSaga,
        paymentStatsSaga,
        moduleSaga,
    ];

    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    console.log(e)
                }
            }
        })));
}

export function* apiAuthRequest(url, data) {
    return yield fetchApi(url, data, 'post');
}
