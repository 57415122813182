import {Form, Table} from "semantic-ui-react";
import React from "react";
import parse from 'html-react-parser';

export const TableView = ({columns, data, actions}) => (
    <Table celled compact striped>
        <Table.Header fullWidth>
            <Table.Row>
                {columns.map(function (column) {
                    return <Table.HeaderCell key={column.name}>
                        { column.displayName }
                    </Table.HeaderCell>
                })}
                {actions && <Table.HeaderCell>
                    Akcje
                </Table.HeaderCell>}
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {data && data.length > 0 ?
                data.map(function(datum){
                    return <Table.Row key={datum.id}>
                        {columns.map(function (column) {
                            let cell = column.view ? column.view(datum) : datum[column.name];
                            return <Table.Cell key={column.name}>{
                                 cell || 'Brak danych'
                            }</Table.Cell>
                        })}
                        {actions && <Table.Cell>
                            {actions(datum.id)}
                        </Table.Cell>}
                    </Table.Row>
                })
                : <h2 style={{margin: '10px'}}>Brak danych</h2>}
        </Table.Body>
    </Table>
);

export const TableWithWrapperView = ({data, columns, actions}) => (
    <Table celled compact striped>
        <Table.Header fullWidth>
            <Table.Row>
                {Object.keys(columns).map(function (key) {
                    const column = columns[key];
                    return <Table.HeaderCell key={key}>
                        { column.name }
                    </Table.HeaderCell>
                })}
                {actions && <Table.HeaderCell>
                    Akcje
                </Table.HeaderCell>}
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {data && data.length > 0 ?
                data.map(function(datum){
                    return <Table.Row key={datum.id}>

                        {Object.keys(columns).map(function (key) {
                            const column = columns[key];
                            return <Table.Cell key={column.name}>
                                {(column.wrapper ? column.wrapper(datum) : datum[key]) || 'Brak danych' }
                            </Table.Cell>
                        })}
                        {actions && <Table.Cell>
                            {actions(datum.id)}
                        </Table.Cell>}
                    </Table.Row>
                })
                : <h2 style={{margin: '10px'}}>Brak danych</h2>}
        </Table.Body>
    </Table>
);


export const TableFromArray = ({data, columns}) => (
    <Table celled compact striped>
        <Table.Header fullWidth>
            <Table.Row>
                {columns.map(function (column) {
                    return <Table.HeaderCell key={column[0]} style={{width: column.length >= 2 ? column[2] : 'auto'}}>
                        { column[0] }
                    </Table.HeaderCell>
                })}
            </Table.Row>
        </Table.Header>

        <Table.Body>
            {Array.isArray(data) && data.length > 0 ?
                data.map(function(datum){
                    return <Table.Row key={datum.id}>
                        {columns.map(function (column) {
                            let cell = column[1](datum);
                            return <Table.Cell key={column.name}>{
                                cell !== null ? cell : 'Brak danych'
                            }</Table.Cell>
                        })}
                    </Table.Row>
                })
                : <h2 style={{margin: '10px'}}>Brak danych</h2>}
        </Table.Body>
    </Table>
);


export const SelectableTableFromArray = ({data, columns, selected, setSelected, warningConditionField, warningConditionValue, grayedConditionField, grayedConditionValue}) => {

    const handleSelect = (id) => {
        setSelected(prev => [...prev, id])
    }

    const handleUnSelect = (id) => {
        setSelected(prev => prev.filter(x => x !== id))
    }

    return (
        <Table celled compact striped>
            <Table.Header fullWidth>
                <Table.Row>
                    {columns.map(function (column) {
                        return <Table.HeaderCell key={column[0]}
                                                 style={{width: column.length >= 2 ? column[2] : 'auto'}}>
                            {column[0]}
                        </Table.HeaderCell>
                    })}
                    <Table.HeaderCell style={{width: '50px'}}>
                        <Form.Checkbox onClick={() => {
                            if(selected.length === data.length){
                                setSelected([])
                            }else{
                                setSelected(data.map(d => d.id))
                            }
                        }} checked={data.length && selected.length === data.length} indeterminate={selected.length && selected.length !== data.length}/>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {Array.isArray(data) && data.length > 0 ?
                    data.map(function (datum) {
                        const checked = selected?.includes?.(datum.id);

                        return <Table.Row key={datum.id} active={checked} 
                                style={{backgroundColor: (warningConditionField != null && datum[warningConditionField] == warningConditionValue) ? '#ffb1006b' : 'transparent',
                                        color: ((grayedConditionField != null && datum[grayedConditionField] == grayedConditionValue) ? 'lightgray' : 'black')}}>
                            {columns.map(function (column) {
                                let cell = column[1](datum);
                                let displayHtml = column.length == 4 ? column[3] : false;
                                return <Table.Cell key={`${datum.id}${column[0]}`}>{
                                    cell !== null ? (displayHtml ? parse(cell) : cell) : 'Brak danych'
                                }</Table.Cell>
                            })}
                            <Table.Cell>
                                <Form.Checkbox onClick={() => {
                                    if(checked){
                                        handleUnSelect(datum.id)
                                    }else{
                                        handleSelect(datum.id)
                                    }
                                }} checked={checked}/>
                            </Table.Cell>
                        </Table.Row>
                    })
                    : <h2 style={{margin: '10px'}}>Brak danych</h2>}
            </Table.Body>
        </Table>
    );
};



export default TableView