import React from 'react';
import {Button, Card, Icon, Popup} from "semantic-ui-react";
import {UserName} from "./UserName";


export const UserReportCard = ({data: {user, reports, reports_count}, onClickShowModalFunction}) => (
    <Card>
        <Card.Content>
            <Card.Header>
                <span style={{display: 'block ruby'}}>
                    <UserName user={user} />
                    <Button icon floated={'right'} onClick={() => onClickShowModalFunction(user)}>
                        <Icon name={'ellipsis horizontal'}/>
                    </Button>
                </span>
            </Card.Header>
            <Card.Meta>

            </Card.Meta>

            <Card.Meta>
                Zgłoszenia: {reports_count}
            </Card.Meta>
            <Card.Description>
                {reports.map( report =>
                    <ReportUser report={report} more_info={true}/> )}
            </Card.Description>
        </Card.Content>
    </Card>
)

const type_color = (type) => {
    const colors = {
        0: 'blue',
        1: 'red',
        2: 'purple',
    };
    return colors[type];
}

const report_name = (type) => {
    const reports = {
        0: 'Nieprawdziwe zdjęcie',
        1: 'Podejrzenie oszustwa',
        2: 'Niewłaściwe zachowanie',
    }
    return reports[type];
}

export const ReportUser = ({report: {id, user, date, type}, more_info=false}) => (
    <div>
        <UserName user={user} hot_icon={more_info} premium_icon={more_info} login={more_info}/>
        <Popup content={report_name(type)} trigger={
            <span style={{color: type_color(type)}}>
                {date}
            </span>
        }/>
    </div>
)