import axios from 'axios';
import {getSubscribers, resetTokenAndReattemptRequest} from "./store/axios-token-refresh";
import {getAuthToken, logout} from "./modules/app";
import {store} from "./index";

axios.interceptors.response.use(
    function(response) {
        // If the request succeeds, we don't have to do anything and just return the response
        return response
    },
     async function(error) {
        const errorResponse = error.response
        if (isTokenExpiredError(errorResponse)) {

            resetTokenAndReattemptRequest(error).catch( e => {
                store.dispatch(logout())
            })
        }
        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error)
    }
)
function isTokenExpiredError(errorResponse) {
    if(!errorResponse){
        return false;
    }

    if(errorResponse.config.url === process.env.REACT_APP_SERVER_URL+'/api/admin/logout'){
        console.log(errorResponse, getSubscribers())
        return false;
    }

    return errorResponse.status === 401;
    // Your own logic to determine if the error is due to JWT token expired returns a boolean value
}

axios.interceptors.request.use(
    config => {
        // Do something before request is sent
        config.baseURL = process.env.REACT_APP_SERVER_URL+`/api/admin`;
        config.headers["Authorization"] = "bearer " + getAuthToken();
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default axios;