import React from 'react';
import { useDispatch } from "react-redux";
import { Container, Divider, Header } from "semantic-ui-react";
import EditContentForm from "../../components/forms/edit-content-form";
import { useParams } from "react-router";
import { fetchContents, resetContent, fetchLocationData } from "../../modules/content";

export const ContentEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(fetchLocationData());
    if (id != 'new')
      dispatch(fetchContents(id));
    else
      dispatch(resetContent())
  }, [dispatch, id]);

  return <Container style={{ width: '95%' }}>
    <Divider hidden />
    <Header as='h2'>
      {id != 'new' ? 'Edycja artykułu' : 'Dodaj artykuł'}
    </Header>
    <EditContentForm />
  </Container>
};

export default ContentEdit;
