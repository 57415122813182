import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container, Divider, Header, Image, Input, Pagination, Popup, Segment, Select } from "semantic-ui-react";
import * as FiltersModule from "../../modules/filters";
import { changePage, fetchFilters, getFilters, getFiltersMeta, setFilter, resetFiltersQuery } from "../../modules/filters";
import { Link } from "react-router-dom";
import { ROUTE } from "../../route-names";
import { UserName } from "../../components/User/UserName";
import { api_url } from "../../utils";
import { request } from "../../modules/app";
import { useParams } from "react-router";
import { useAppRouter } from "../../hooks/router";

export const are_filters_equal = (filter1, filter2) => {
  return (filter1 && filter2 &&
    filter2[Object.keys(filter1)[0]] === filter1[Object.keys(filter1)[0]] &&
    Object.keys(filter1).length
  ) &&
    (
      Object.keys(filter1).length !== 0
    );
}

export const ButtonSetFilter = ({ actual_filter, setFilter, filter, content }) => (
  <Button size='mini' id='filter_filters' color={are_filters_equal(filter, actual_filter) ? 'green' : null} floated='center' onClick={() => setFilter(filter)} content={content} />
);

const Filter = () => {
  const dispatch = useDispatch();
  const actual_filter = useSelector(FiltersModule.getFiltersFilter);

  const filters = [
    ['Brzydkie słowa', FiltersModule.filters.bad_words],
    ['Adresy email', FiltersModule.filters.emails],
    ['Adresy IP', FiltersModule.filters.ips],
    ['Spam', FiltersModule.filters.spam],
    ['Potencjalny Spam Rejestracja', FiltersModule.filters.new_user_spam],
    ['Opisy', FiltersModule.filters.descriptions],
    ['Dane kontaktowe', FiltersModule.filters.contact_data],
    ['Wielkość okna', FiltersModule.filters.window_size],
    ['Blokowane słowa', FiltersModule.filters.block_words],
    ['Hosty', FiltersModule.filters.hostnames],
  ];

  const setFilterAndFetchFilters = (filter) => {
    dispatch(setFilter(filter));
    dispatch(changePage(1));
    dispatch(fetchFilters());
  };

  return <Button.Group floated={'left'}>
    {filters.map((filter) => (
      <ButtonSetFilter content={filter[0]} actual_filter={actual_filter} filter={filter[1]} setFilter={setFilterAndFetchFilters} />
    ))}
  </Button.Group>
};

const filtersOptions = [
  { value: '0', text: 'Brzydkie słowa' },
  { value: '1', text: 'Adresy email' },
  { value: '2', text: 'Adresy IP' },
  { value: '3', text: 'Spam' },
  { value: '9', text: 'Potencjalny Spam Rejestracja' },
  { value: '4', text: 'Opisy' },
  { value: '5', text: 'Dane kontaktowe' },
  { value: '6', text: 'Wielkość okna' },
  { value: '7', text: 'Blokowane słowa' },
  { value: '8', text: 'Hosty' },
];

const FilterCard = ({ filter, deleteFunction }) => (
  <Card>
    <Card.Content>
      <Card.Header>
        {filter.value}
      </Card.Header>

      <Card.Meta>
        <Button style={{ marginTop: '10px' }} content={'Usuń'} onClick={() => deleteFunction(filter.id)} />
      </Card.Meta>
    </Card.Content>
  </Card>
);

const FiltersSection = ({ filters, deleteFunction }) => {
  return <Card.Group itemsPerRow={6}>
    {filters.map(filter => (
      <FilterCard filter={filter} deleteFunction={deleteFunction} />
    ))}
  </Card.Group>;
};

export const FiltersView = () => {
  let filters = useSelector(getFilters);
  const meta = useSelector(getFiltersMeta);
  const dispatch = useDispatch();

  const setPageAndFetchFilters = (page) => {
    dispatch(changePage(page));
    dispatch(fetchFilters());
  };

  const deleteFilter = (id) => {
    request('delete', api_url(`admin/filters/${id}`), {}).then(res => {
      dispatch(fetchFilters())
    })
  }

  const addFilter = (type, value) => {
    request('post', api_url(`admin/filters`), { type, value }).then(res => {
      dispatch(fetchFilters())
    })
  }

  const [addNew, setAddNew] = useState({ type: '0' });
  const onAddTextChange = (e, { name, value }) => setAddNew(prev => Object.assign(prev, { value }));
  const onAddTypeChange = (e, { name, value }) => setAddNew(prev => Object.assign(prev, { type: value }));

  const addClick = () => {
    if (addNew.type != null && addNew.value?.length > 0)
      addFilter(addNew.type, addNew.value);
  }

  return <Container style={{ width: '99%', paddingBottom: '20px' }}>
    <Divider hidden />
    <Header as='h2'>
      Filtry
    </Header>
    <Divider />
    <Segment>
      <Select options={filtersOptions} onChange={onAddTypeChange} defaultValue={addNew.type} />
      <Input onChange={onAddTextChange} />
      <Button onClick={addClick} content={'Dodaj filtr'} style={{ marginLeft: '10px' }} />
    </Segment>
    <Divider />
    <Button.Group>
      <Filter />
    </Button.Group>
    <Divider hidden />
    <FiltersSection filters={filters} deleteFunction={deleteFilter} />
    <Divider />
    <Segment basic textAlign={"center"}>

      <Pagination activePage={meta.current_page}
        totalPages={meta.last_page}
        onPageChange={(e, { activePage }) => setPageAndFetchFilters(activePage)}
      />
    </Segment>
  </Container>;
};

export const Filters = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFilters());
  }, [dispatch]);

  return <FiltersView />
};
