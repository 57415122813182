import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { apiGetOrders, changePage, getOrders, getOrdersMeta, resetQuery, statusOptions, paymentMethodOptions } from "../../modules/orders";
import { Divider, Form, Select, Segment } from "semantic-ui-react";
import { TableWithWrapperView } from "../../components/stateless/Table";
import { Link } from "react-router-dom";
import { ROUTE } from "../../route-names";
import { Pagination } from "../../components/tables/pagination";
import { PageContainer } from "../../components/page/PageContainer";
import Moment from "moment";

const paymentMethods = paymentMethodOptions.filter(method => method.value != 'free_premium');

export const premiumTypeOptions = [
    { value: 'premium', text: 'Premium' },
    { value: 'vip', text: 'VIP' },
    { value: '', text: 'Wszystkie' },
].map((status, i) => ({ ...status, key: i }));

const sortByOptions = [
    { value: 'created_at', text: 'Rozpoczęcie zakupu' },
    { value: 'pay_date', text: 'Data zapłaty' },
    { value: 'promo_type', text: 'Promocja' },
    { value: 'date_start', text: 'Ważne od' },
    { value: 'date_end', text: 'Ważne do' },
    { value: 'payment_method', text: 'Metoda płatności' },
    { value: 'status', text: 'Status' }
].map((status, i) => ({ ...status, key: i }));

const sortOrdersOptions = [
    { value: 'asc', text: 'Rosnąco' },
    { value: 'desc', text: 'Malejąco' },
].map((status, i) => ({ ...status, key: i }));

export const Filters = ({ onChange }) => (
    <Form>
        <Form.Group>
            <Form.Field
                name={'status'}
                onChange={onChange}
                control={Select}
                options={statusOptions}
                label={{ children: 'Status', htmlFor: 'form-select-control-status' }}
                placeholder='Status'
                search
                searchInput={{ id: 'form-select-control-status' }}
            />
            <Form.Field
                name={'account_type'}
                onChange={onChange}
                control={Select}
                options={premiumTypeOptions}
                label={{ children: 'Typ premium', htmlFor: 'form-select-control-premium-type' }}
                placeholder='Typ premium'
                search
                searchInput={{ id: 'form-select-control-premium-type' }}
            />
            <Form.Field
                name={'payment_method'}
                onChange={onChange}
                control={Select}
                options={paymentMethods}
                label={{ children: 'Metoda płatności', htmlFor: 'form-select-control-payment-method' }}
                placeholder='Metoda płatności'
                search
                searchInput={{ id: 'form-select-control-payment-method' }}
            />
            <Form.Field
                name={'orderBy'}
                defaultValue={'created_at'}
                onChange={onChange}
                control={Select}
                options={sortByOptions}
                label={{ children: 'Sortuj wg', htmlFor: 'form-select-control-sort_by' }}
                placeholder='Sortuj wg'
                search
                searchInput={{ id: 'form-select-control-sort_by' }}
            />
            <Form.Field
                name={'order'}
                defaultValue={'desc'}
                onChange={onChange}
                control={Select}
                options={sortOrdersOptions}
                label={{ children: 'Typ sortowania', htmlFor: 'form-select-control-sort_order' }}
                placeholder='Typ sortowania'
                search
                searchInput={{ id: 'form-select-control-sort_order' }}
            />

        </Form.Group>
    </Form>
);

export const Orders = () => {
    const dispatch = useDispatch();
    const payments = useSelector(getOrders);
    const [query, setQuery] = useState({});

    React.useEffect(() => {
        dispatch(resetQuery(query));
        dispatch(changePage(1));
        dispatch(apiGetOrders(null, { per_page: 50 }));
    }, [dispatch, query]);

    const handleChange = (e, { name, value }) => {
        const new_query = { ...query, [name]: value };
        if (!value) {
            delete new_query[name];
        }
        setQuery(new_query);
    };

    const handleChangePage = (page) => {
        dispatch(changePage(page));
        dispatch(apiGetOrders(null, null));
    };

    return <PageContainer name={'Płatności'}>
        <Filters data={query} onChange={handleChange} />
        <Divider />
        <TableWithWrapperView data={payments} columns={{
            'user_login': {
                name: 'Login',
                wrapper: (data) => {
                    let color = 'black';
                    if (data.user.premium_date_end && data.user.premium_type != 'free premium') color = 'green';
                    else if(data.user.had_premium) color = 'red';
                    else if(data.user.premium_type == 'free premium' && !data.user.had_premium) color = 'grey';
                    const date = Moment(data.user.created_at).format('Y-MM-DD HH:mm');
                    return <div>
                        <Link to={ROUTE.user(data.user.id)}>
                            <span style={{ color: color }}>{data.user.login}</span>
                        </Link>
                        <div>{date}</div>
                    </div>
                }
            },
            order_key_id: {
                name: "Id transakcji zew.",
                wrapper: (data) => {
                    if (data.payment_method != 'sms' && data.payment_method != 'manual' && data.payment_method != 'hot_person')
                        return data.order_key_id
                    else
                        return '-';
                }
            },
            promo_type: {
                name: "Promocja",
                wrapper: (data) => {
                    switch (data.promo_type) {
                        case 1: return 'Pierwszy zakup < 30d od rej.'
                        case 2: return 'Pierwszy zakup > 30d od rej.'
                        case 3: return 'Powracający'
                        default: return '-'
                    }
                }
            },
            account_type: {
                name: 'Subskrypcja',
                wrapper: (data) => {
                    let accountType = data.account_type;
                    if (accountType == 'vip') accountType = 'VIP';
                    else if (accountType == 'premium') accountType = 'Premium';
                    if (data.description) {
                        return `${accountType} ${data.description}`;
                    } else {
                        let subscriptionLength = Math.abs(Moment(data.date_end).diff(Moment(data.date_start), 'months', false));
                        let measureType = 'M';
                        if (subscriptionLength == 0) {
                            subscriptionLength = Math.abs(Moment(data.date_end).diff(Moment(data.date_start), 'days', false));
                            measureType = 'D';
                        }
                        return `${accountType} ${subscriptionLength}${measureType}`;
                    }
                }
            },
            amount: {
                name: 'Kwota',
                wrapper: (data) => (
                    `${data.amount} ${data.currency}`
                )
            },
            status: {
                name: "Status",
                wrapper: (data) => {
                    const statusObject = statusOptions.find(status => status.value == data.status);
                    if (statusObject) {
                        let color = 'black';
                        if (data.status == 'completed') color = 'green';
                        return <span style={{ color: color }}>{statusObject.text}</span>;
                    }
                    return '';
                }
            },
            created_at: {
                name: "Ropoczęcie zakupu"
            },
            pay_date: {
                name: "Data zapłaty",
                wrapper: (data) => {
                    if (data.pay_date)
                        return data.pay_date;
                    return '-';
                }
            },
            payment_method: {
                name: "Metoda płatności",
                wrapper: (data) => {
                    const paymentObject = paymentMethods.find(status => status.value == data.payment_method);
                    if (paymentObject)
                        return paymentObject.text;
                    return '';
                }
            }
        }} />
        <Pagination pagination={useSelector(getOrdersMeta)} onChange={handleChangePage} />

        <Segment basic>
            <Link to={ROUTE.PAYMENT_PLANS} className={'ui button'}>Plany premium</Link>
            <Link to={ROUTE.PAYMENT_STATS} className={'ui button'}>Statystyki</Link>
        </Segment>
    </PageContainer>
};
export default Orders;
