import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router";
import {apiGetPaymentPlans, getPaymentPlan} from "../../modules/payment_plans";
import PaymentPlanForm from "../../components/forms/payment-plan-form";
import {Button, Container} from "semantic-ui-react";
import {goBack} from "react-router-redux";

export const PaymentPlanView = ({payment, back}) => (
    <Container>
        <PaymentPlanForm initialValues={payment}/>
        <Button onClick={back} content={"Anuluj"}/>
    </Container>
);

const PaymentPlanEdit = props => {
    const {payment, apiGetPaymentPlans, goBack} = props;
    const {id} = useParams();
    useEffect(()=>{apiGetPaymentPlans(id)}, [id]);
    return <PaymentPlanView payment={payment} back={goBack}/>
};

export default connect(state => ({
    payment: getPaymentPlan(state),
}), {
    apiGetPaymentPlans: (id) => apiGetPaymentPlans(id),
    goBack: goBack,
})(PaymentPlanEdit);