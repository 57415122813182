export const ROUTE = {
    ACTIVITIES: `/activities`,
    ADMIN: '/admin/:id',

    COMMENTS: '/comments',
    COMMENTS_SEARCH: '/comments/search/:text',
    COMMENTS_EDIT: '/comments/:id',

    NO_PERMISSION: '/no-permission',
    PAYMENT_PLANS: '/paymentPlans',
    PAYMENT_PLANS_CREATE: '/paymentPlans/create',
    PAYMENT_PLANS_EDIT: '/paymentPlans/:id',

    payment_plan: (id) => `/paymentPlans/${id}`,


    admin: id => `/admin/${id}`,
    ADMINS: '/admins',
    DASHBOARD: '/dashboard',
    IMAGE_CROP: '/imageCrop/:id',
    imageCrop: (id) => (`/imageCrop/${id}`),
    LOGIN: '/login',
    LOGOUT: '/logout',
    NEW_ADMIN: '/newAdmin',
    ROLES: `/roles`,

    MAILING: '/mailing',
    MAILING_NEW: '/mailing/new',

    MESSAGES: '/messages',
    MESSAGES_FOR_USER: '/messages/:id',
    MESSAGES_FOR_USER_URL: (id) => `/messages/${id}`,
    MESSAGES_SEARCH: '/messages/search/:body',

    user: (id) => `/users/${id}`,
    USERS: '/users',
    USER_CREATE: '/users/create',
    USER: '/users/:id',
    USER_EDIT: '/users/:id/edit',
    USER_COMMENTS: '/users/:id/comments',
    USER_PHOTOS: '/users/:id/photos',
    USER_PAYMENTS: '/users/:id/orders',
    USER_CHAT: '/users/:id/chat',
    USER_ADVANCED_INFO: '/users/:id/advanced',
    USER_VERIFICATION_PHOTOS: '/users/:id/verification_photos',
    USER_LOGIN_LOGS: '/users/:id/login_logs',
    USER_DESCRIPTIONS: '/user_descriptions',
    USERS_PLANS: '/users_plans',

    PHOTOS: '/photos',
    PHOTO: '/photos/:id',
    photo: (id)=> '/photos/'+id,

    REPORTS: '/userReports',

    ORDERS: '/orders',
    ORDER: '/orders/:id',
    ORDER_EDIT: '/orders/:id/edit',
    ORDER_CREATE: '/users/:user_id/orders/create',
    order: (id)=> '/orders/'+id,
    orderEdit: (id)=> `/payments/${id}/edit`,
    PAYMENT_STATS: '/paymentStats',

    VERIFY_PHOTOS: '/verify_photos',
    PHOTOS_TO_CROP: '/photos/photosToCrop',
    VERIFY_PHOTO: '/verify_photos/:id',
    verifyPhoto: (id)=> '/verify_photos/'+id,
    
    SUGGESTIONS: '/suggestions',
    SUGGESTION: '/suggestions/:id',
    suggestion: (id)=> '/suggestions/'+id,

    VERIFICATION_MAIL: '/verification_mail',

    FILTERS: '/filters',
    CONTENTS: '/contents',
    CONTENTS_BY_LOCATION: '/contents-by-location',
    CONTENT: '/contents/:id',
    CONTENT_BY_LOCATION: '/contents-by-location/:id',
    CONTENT_NEW: '/contents/new',
    CONTENT_BY_LOCATION_NEW: '/contents-by-location/new',
    content: (id) => '/contents/' + id,
    contentByLocation: (id) => '/contents-by-location/' + id
};
