import {handleActions} from "redux-actions";
import {put, select, takeEvery} from "redux-saga/effects";
import {createAction} from "../utils";
import {getAdminsStore} from "../store/createRootReducer";
import {API} from "../api-endpoints";
import {fetchApi} from "./app";

const FETCH_ADMINS = 'app/admins/FETCH_ADMINS';
const RESET_FILTER = 'app/admins/RESET_FILTER';
const RESET_META = 'app/admins/RESET_META';
const RESET_ADMINS = 'app/admins/RESET_ADMINS';

export const resetAdmins = (admins) => createAction(RESET_ADMINS, {admins});
export const resetAdminsFilter = (filter) => createAction(RESET_FILTER, {filter});
export const resetAdminsMeta = (meta) => createAction(RESET_META, {meta});
export const fetchAdmins = () => createAction(FETCH_ADMINS);


const initialState = {
    admins: [],
    filter: {},
    meta: {current_page: 0, last_page: 0},
    last_filter: '',
};

export default handleActions({
        [RESET_ADMINS]: (state, action) => {
            return {...state, admins: action.payload.admins}
        },
        [RESET_FILTER]: (state, action) => {
            const filter = action.payload.filter;
            return {...state, filter: filter}
        },
        [RESET_META]: (state, action) => {
            return {...state, meta: action.payload.meta}
        },
    },
    initialState
)

export const getAdmins = state => getAdminsStore(state).admins;
export const getAdminsFilter = state => getAdminsStore(state).filter;
export const getAdminsMeta = state => getAdminsStore(state).meta;

function* callFetchAdmins() {
    let filter = yield select(getAdminsFilter);

    Object.keys(filter).forEach(key => filter[key] == null && delete filter[key]);
    const meta = yield select(getAdminsMeta);

    const response = yield fetchApi(API.admin(), {
        filter: filter,
        page: meta.page,
    }, 'get');

    yield put(resetAdmins(response.data.data));
    yield put(resetAdminsMeta(response.data.meta));
}



export function* adminsSaga() {
    yield takeEvery(FETCH_ADMINS, callFetchAdmins);
}