import React from 'react'
import {Container, Divider, Header} from "semantic-ui-react";

export const PageContainer = ({name, actions, children}) => (
    <Container style={{width: '99%', background: '#f3f3f4'}}>
        <Divider hidden/>
        <Header as='h2'>
            {name}
            {actions && <div style={{float: 'right'}}>
                {React.createElement(actions)}
            </div>}
        </Header>
        {children}
    </Container>
)