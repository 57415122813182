import React, { useState } from "react";
import { Card, Divider, Header, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { ProfileSettingsReduxForm } from "../components/forms/profile_settings_form";
import AppSettingsForm from "../components/forms/app_settings";
import { PageContainer } from "../components/page/PageContainer";
import { request } from "../modules/app";
import { api_url } from "../utils";

export const Dashboard = () => {
    const user = useSelector(state => state.root.user);

    const [smsBalance, setSmsBalance] = useState({});

    const loadSmsBalance = () => {
        request('get', api_url(`admin/getSmsBalance`)).then(res => {
            setSmsBalance({ value: res.data.data })
        })
    }

    return <PageContainer name={'Strona główna'}>
        Witaj {user.email}
        <Divider />
        <div>
            <Card.Group>
                <Card>
                    <Card.Content>
                        <Header>
                            Zmień ustawienia strony
                    </Header>
                        <AppSettingsForm />
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <Header>
                            Zmień swoje hasło
                    </Header>
                        <ProfileSettingsReduxForm />
                    </Card.Content>
                </Card>

                <Card>
                    <Card.Content>
                        <Header>
                            Stan pakietów SMS
                    </Header>
                        <Button onClick={loadSmsBalance} content={'Sprawdź'} />
                        {smsBalance && smsBalance.value ? <div>Stan: {smsBalance.value}</div> : ''}
                    </Card.Content>
                </Card>
            </Card.Group>

        </div>
    </PageContainer>
};

export default Dashboard;