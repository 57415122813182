import React from 'react';
import {MailPreview} from "../../components/mail_preview/MailPreview";
import {PageContainer} from "../../components/page/PageContainer";
import {Button, Divider, Dropdown, Header, Input, Message, TextArea} from "semantic-ui-react";
import {useApi, useModal} from "../../components/hooks";
import {useApiSendMails, useMailReceiversCount} from "../../Api";
import UserFilterForm from '../../components/forms/UserFilterFormOnContext'
import _ from "lodash";
import {api_url} from "../../utils";
import {useDispatch} from "react-redux";
import {push} from "react-router-redux";
import {ROUTE} from "../../route-names";
import {UserFilter} from "./components/UsersFilter";
import {parse} from 'query-string'
import {useLocation} from "react-router";

export const SendMail = () => {
    const [text, setText_] = React.useState({raw: '', format: ''});
    const [title, setTitle] = React.useState('');
    const [filter, setFilter] = React.useState({});
    const {modal, setContentAndOpenModal, openConfirmModal} = useModal()

    const templates = useApi(api_url('admin/templates'), 'get');
    const receiversCount = useMailReceiversCount();
    const sendMails = useApiSendMails();

    const location = useLocation();

    const {email} = parse(location.search)

    var currentFilters = null;

    const getFilter = () => {
        if(email)
            return {email}
        return filter
    }

    React.useEffect( () => {
        onChangeFilter({email})
    }, [email])

    React.useEffect( () => {
        receiversCount.call({filter: getFilter()});
        templates.call({type: 'mailing'})
    }, []);

    const setText = (text) => {
        setText_({raw: text, format: text.split('\n').map(line => `<div>${line}</div>`).join('')});
    }
    const dispatch = useDispatch();

    const send_mails = () => {
        sendMails.call({filter, body: text.format, title}, () => {
            setContentAndOpenModal(<Header icon='checkmark' content='E-Mail został wysłany' />);
            dispatch(push(ROUTE.MAILING))
        }, () => {
            setContentAndOpenModal(<Header icon='exclamation' content='Wystąpił błąd' />);
        });
    }

    const onChangeFilter = (filter) => {
        setFilter(filter);
        // receiversCount.call({filter});
    };

    const changeTemplate = (template) => {
        setText( template ? template.content : '')
        setTitle(template ? template.title : '')
    }

    const onApplyFilters = () => {
        receiversCount.call({filter});
    }

    const actions = () => (<Button icon={'arrow left'} content={'Lista maili'} onClick={() => dispatch(push(ROUTE.MAILING))}/>)

    return <UserFilterForm.Provider onChange={onChangeFilter} filter={filter}>
        <PageContainer name={'Mailing'} actions={actions}>
            {modal}

            <div style={{display: 'flex'}}>
                <div style={{width: '40%'}}>
                    <Divider horizontal>Podgląd</Divider>
                    <MailPreview mail_body={text.format} />
                </div>

                <div style={{width: '60%'}}>
                    <Divider horizontal>Wiadomość</Divider>
                    <div style={{display: 'grid', height: 'fit-content', }}>

                        {!email && <UserFilter/>}

                        <Message size='mini'>
                            { !receiversCount.loading ?
                                <Message.Header style={{textAlign: 'center'}}>Wiadomość zostanie wysłana do {receiversCount.response?.valid?.count || 0} {receiversCount.response?.valid?.count === 1 ? 'użytkownika' : 'użytkowników'}, niewysłana do {receiversCount.response?.invalid?.count || 0}</Message.Header>
                                :
                                <Message.Header style={{textAlign: 'center'}}>Wiadomość zostanie wysłana do: Proszę czekać....</Message.Header>
                            }
                        </Message>
                        <Dropdown text={'Wzór'}
                                  onChange={(...data) => {
                                      const changeTemplate_ = () => changeTemplate(data[1].value)
                                      if(text.raw || title){
                                          openConfirmModal('Aktualna treść zostanie nadpisana, czy kontynuować?', changeTemplate_)
                                      }else{
                                          changeTemplate_()
                                      }
                                  }}
                                  options={_.map(templates.response, temp => ({value: temp, text: temp.name, key: temp.id})).concat({value: '', text: 'Brak'})}
                                  value={null}
                        />

                        <Input onChange={(e, d) => setTitle(d.value)} label={'Tytuł'} value={title}/>

                        <TextArea onChange={(e, v) => {
                            setText(v.value)
                        }} value={text.raw} style={{
                            height: '200px',
                            minWidth: '100%',
                            maxWidth: '40%',
                        }}/>
                        <Button content={'Sprawdź filtry'} onClick={() => onApplyFilters()}/>
                        <Button content={'Wyślij'} onClick={() => openConfirmModal("Czy zatwierdzasz?", send_mails)} color={'green'}/>
                    </div>
                    { !receiversCount.loading ?
                        <div><div>Użytkownicy: {receiversCount.response?.valid?.logins != null ? receiversCount.response?.valid?.logins?.join(', ') : ''}</div><div>Niepoprawne emaile - użytkownicy: {receiversCount.response?.invalid?.logins != null ? receiversCount.response?.invalid?.logins?.join(', ') : ''}</div></div>
                        :
                        ''
                    }
                </div>

            </div>


        </PageContainer>
    </UserFilterForm.Provider>

}