import {Button} from "semantic-ui-react";
import React from "react";

export const ButtonSetFilter = ({actual_filter, setFilter, filter, content}) => (
    <Button id='filter_comments' color={JSON.stringify(filter) === JSON.stringify(actual_filter) ? 'green' : undefined} floated='center' onClick={()=>setFilter(filter)} content={content}/>
);

export const Filter = ({onChange, actual_filter, filters}) => (
    <Button.Content floated={'left'}>
        {filters.map((filter) => (
            <ButtonSetFilter content={filter[0]} actual_filter={actual_filter} filter={filter[1]} setFilter={onChange}/>
        ))}
    </Button.Content>
);