import {createAction} from "../utils";
import {handleActions} from "redux-actions";
import {getCommentsStore} from "../store/createRootReducer";
import {put, select, takeEvery} from "@redux-saga/core/effects";
import {fetchApi} from "./app";
import {API} from "../api-endpoints";

const FETCH_COMMENTS = 'app/comments/FETCH_COMMENTS';
const RESET = 'app/comments/RESET';
const UPDATE = 'app/comments/UPDATE';

export const fetchComments = () => createAction(FETCH_COMMENTS);
const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetCommentsQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({current_page: page});

export const filters = {
    all: {},
    reported: {
        'reported': true
    },
    abuse: {
        'abuse': 1
    },
};

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
    id: undefined,
    comments: [],
    meta: {current_page: 1, last_page: 1, per_page: 100},
    filter: filters.all,
    query: {}
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
    },
    initialState
)

export const getComments = (state) => getCommentsStore(state).comments;
export const getCommentsMeta = (state) => getCommentsStore(state).meta;
export const getCommentsQuery = (state) => getCommentsStore(state).query;
export const getCommentsFilter = (state) => getCommentsStore(state).filter;

export function* callFetchComments() {

    let filter = yield select(getCommentsFilter);
    let {current_page: page, per_page} = yield select(getCommentsMeta);

    const query = yield select(getCommentsQuery);
    const response = yield fetchApi(API.comments(), {...filter, page, per_page, ...query}, 'get');
    const comments = response.data.data;
    for (let i = 0; i < comments.length; i++) {
        comments[i].text = applyEmots(comments[i].text);
    }
    yield put(reset('comments', comments));
    yield put(resetMeta(response.data.meta));
}

export function* commentsSaga() {
    yield takeEvery(FETCH_COMMENTS, callFetchComments);
}

function applyEmots(newBody) {
    Object.keys(emotsTexts).forEach(emot => {
        while (newBody.indexOf(emot) >= 0) {
            newBody = newBody.replace(emot, '<img src="' + emotsTexts[emot].getUrl() + '">');
        }
    });
    return newBody;
}

class Emot {
    emotNumber;
    emotText;

    constructor(num, text) {
        this.emotNumber = num;
        this.emotText = text;
    }

    getUrl() {
        return `/emot-0${this.emotNumber}.png`;
    }
}

const emotsTexts = {
    '[przewraca oczami]': new Emot(1, '[przewraca oczami]'),
    '[szeroko sie usmiecha]': new Emot(2, '[szeroko sie usmiecha]'),
    '[usmiecha sie]': new Emot(3, '[usmiecha sie]'),
    '[puszcza oczko]': new Emot(4, '[puszcza oczko]'),
    '[pokazuje jezyk]': new Emot(5, '[pokazuje jezyk]'),
    '[smuci sie]': new Emot(6, '[smuci sie]'),
    '[zly]': new Emot(7, '[zly]'),
    '[glosno sie smieje]': new Emot(8, '[glosno sie smieje]'),
    '[niedobrze mu]': new Emot(9, '[niedobrze mu]')
};