const mapOptions = (data) => {
    return data?.map((status, i) => ({ ...status, key: i })) || []
};

export const emptyOption = {
    value: null,
    text: 'Nie wybrano'
};

export const domainOptions = mapOptions([
    { value: 'pl', text: 'pl' },
    { value: 'co.uk', text: 'co.uk' },
    { value: 'nl', text: 'nl' },
    { value: 'de', text: 'de' }
]);

export const languageOptions = mapOptions([
    { value: 'pl', text: 'pl' },
    { value: 'en', text: 'en' }
]);
