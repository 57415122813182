import {handleActions} from "redux-actions";
import {put, takeEvery} from "redux-saga/effects";
import {api_url, createAction} from "../utils";
import {getOrdersStore} from "../store/createRootReducer";
import {API} from "../api-endpoints";
import {fetchApi} from "./app";
import {select} from "@redux-saga/core/effects";
import * as modules from "./main_module";

export const statusOptions = [
    { value: 'begin', text: 'Rozpoczęte' },
    { value: 'pending', text: 'Trwające' },
    { value: 'completed', text: 'Ukończone' },
    { value: 'fail', text: 'Zakończone błędem' },
    { value: 'cancel', text: 'Anulowane' },
    { value: '', text: 'Wszystkie' },
].map((status, i) => ({ ...status, key: i }));

export const paymentMethodOptions = [
    { value: 'transfer', text: 'Przelew' },
    { value: 'skrill', text: 'Skrill' },
    { value: 'payu', text: 'PayU' },
    { value: 'sms', text: 'SMS' },
    { value: 'manual', text: 'Ręczne' },
    { value: 'free_premium', text: 'Darmowe premium' },
    { value: 'hot_person', text: 'HOT - Gorąca osoba' },
    { value: '', text: 'Wszystkie' },
].map((status, i) => ({ ...status, key: i }));

const SAGA_API_GET = 'app/orders/SAGA_API_GET';
const SAGA_API_SAVE = 'app/orders/SAGA_API_SAVE';

const RESET = 'app/orders/RESET';
const UPDATE = 'app/orders/UPDATE';
const CHANGE_PAGE = 'app/orders/CHANGE_PAGE';

export const apiSaveOrder = (data) => createAction(SAGA_API_SAVE, {data});
export const apiGetOrders = (id, filter) => createAction(SAGA_API_GET, {id, filter});

export const reset = (key, value) => createAction(RESET, {key, value});
export const update = (key, value) => createAction(UPDATE, {key, value});
export const changePage = (page) => createAction(CHANGE_PAGE, {page});

export const resetOrders = (orders) => reset('orders', orders);
export const resetOrder = (order) => reset('order', order);
export const resetOrdersMeta = (meta) => reset('meta', meta);
export const resetQuery = (meta) => reset('query', meta);
export const resetSort = (key, order) => reset('sort', {key, order});

export const giveFreePremium = (user_id, days, onSuccess) => modules.api_call(
    'orders',
    'post',
    api_url('admin/orders'),
    {user_id, days, account_type: 'premium'},
    {onSuccess}
);

const initialState = {
    order: {},
    orders: [],
    query: {},
    meta: {current_page: 1, last_page: 1, per_page: 50},
    sort: {key: 'created_at', order: 'desc'}
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
        [CHANGE_PAGE]: (state, action) => {
            return {...state, meta: {...state.meta, current_page: action.payload.page}}
        },
    },
    initialState
)

export const getOrder = state => getOrdersStore(state).order;
export const getOrders = state => getOrdersStore(state).orders;
export const getOrdersMeta = state => getOrdersStore(state).meta;
export const getOrdersQuery = state => getOrdersStore(state).query;
export const getSort = state => getOrdersStore(state).sort;

function* callGetOrders(action) {
    const {id} = action.payload;

    const uri = API.order(id);
    if (!id){
        const query = yield select(getOrdersQuery);
        const meta = yield select(getOrdersMeta);
        const sort = yield select(getSort);

        const response = yield fetchApi(uri, {...query, page: meta.current_page, per_page: meta.per_page,
            orderBy: sort.key, order: sort.order
        }, 'get');

        yield put(resetOrdersMeta(response.data.meta))
        yield put(resetOrders(response.data.data.map(order=>({
            ...order,
            amount: order.amount / 100
        }))));
    }
    else{
        const response = yield fetchApi(uri, {},'get');

        const order = response.data.data;
        yield put(resetOrder({...order, amount: order.amount /100}));
    }
}

function* callSaveOrders(action) {
    const {data} = action.payload;

    const uri = API.order(data.id);
    if (!data.id){
        const response = yield fetchApi(uri, {
            ...data,
            amount: data.amount*100
        }, 'post');
        yield put(resetOrders(response.data.orders));
    }
    else{
        const response = yield fetchApi(uri, {
            ...data,
            amount: data.amount*100
        },'patch');

        yield put(resetOrder(response.data));
    }
}

export function* ordersSaga() {
    yield takeEvery(SAGA_API_GET, callGetOrders);
    yield takeEvery(SAGA_API_SAVE, callSaveOrders);
}