import {Form} from "semantic-ui-react";
import React from "react";

const AdminsFilterForm = ( {filter, onChange}) => (
    <Form>
        <Form.Group widths='equal' hidden>
            <Form.Input value={filter.name} fluid label='Name' placeholder='Name' onChange={onChange} id='name'/>
            <Form.Input value={filter.email} fluid label='Email' placeholder='Email' onChange={onChange} id='email'/>
        </Form.Group>
    </Form>
);

export default AdminsFilterForm