import { createAction } from "../utils";
import { handleActions } from "redux-actions";
import { getFiltersStore } from "../store/createRootReducer";
import { put, select, takeEvery } from "@redux-saga/core/effects";
import { fetchApi } from "./app";
import { API } from "../api-endpoints";

const FETCH_FILTERS = 'app/filters/FETCH_FILTERS';
const RESET = 'app/filters/RESET';
const UPDATE = 'app/filters/UPDATE';

export const fetchFilters = () => createAction(FETCH_FILTERS);
const reset = (key, value) => createAction(RESET, { key, value });
const update = (key, value) => createAction(UPDATE, { key, value });

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetFiltersQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({ current_page: page });

export const filters = {
  bad_words: { 'type': 0 },
  emails: { 'type': 1 },
  ips: { 'type': 2 },
  spam: { 'type': 3 },
  descriptions: { 'type': 4 },
  contact_data: { 'type': 5 },
  window_size: { 'type': 6 },
  block_words: { 'type': 7 },
  hostnames: { 'type': 8 },
  new_user_spam: { 'type': 9 },
};

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
  id: undefined,
  filters: [],
  meta: { current_page: 1, last_page: 1, per_page: 102 },
  filter: filters.bad_words,
  query: {}
};

export default handleActions({
  [RESET]: (state, action) => {
    return { ...state, [action.payload.key]: action.payload.value }
  },
  [UPDATE]: (state, action) => {
    return { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload.value } }
  },
},
  initialState
)

export const getFilters = (state) => getFiltersStore(state).filters;
export const getFiltersMeta = (state) => getFiltersStore(state).meta;
export const getFiltersQuery = (state) => getFiltersStore(state).query;
export const getFiltersFilter = (state) => getFiltersStore(state).filter;

export function* callFetchFilters() {

  let filter = yield select(getFiltersFilter);
  let { current_page: page, per_page } = yield select(getFiltersMeta);

  const query = yield select(getFiltersQuery);
  const response = yield fetchApi(API.filters(), { ...filter, page, per_page, ...query }, 'get');
  yield put(reset('filters', response.data.data));
  yield put(resetMeta(response.data.meta));
}

export function* filtersSaga() {
  yield takeEvery(FETCH_FILTERS, callFetchFilters);
}