import React, {useState} from "react";
import {Accordion as SemAccordion, Icon} from "semantic-ui-react";

export default function Accordion({title, ...props}){
    const [open, setOpen] = useState(false);

    return <SemAccordion>
        <SemAccordion.Title active={open} onClick={() => setOpen(p=>!p)}>
            <Icon name='dropdown' />
            {title}
        </SemAccordion.Title>
        <SemAccordion.Content active={open}>
            {props.children}
        </SemAccordion.Content>

    </SemAccordion>
}