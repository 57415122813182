import React, {useEffect} from "react";
import {connect} from "react-redux";
import {apiFetchOptions, getPaymentPlan} from "../../modules/payment_plans";
import {goBack} from "react-router-redux";
import {PaymentPlanView} from "./payment_plan_edit";

const PaymentPlanCreate = props => {
    const {apiFetchOptions, goBack} = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=>{apiFetchOptions()}, []);
    return <PaymentPlanView back={goBack}/>
};

export default connect(state => ({
    payment: getPaymentPlan(state),
}), {
    apiFetchOptions: apiFetchOptions,
    goBack: goBack,
})(PaymentPlanCreate);