import React from 'react';
import UserFilterForm, {FormFilterContext} from "../../../components/forms/UserFilterFormOnContext";
import {Button, Form, Label} from "semantic-ui-react";
import {If} from "../../../components/helpers/Condition";

export const UserFilter = () => {
    const { filterData: filter } = React.useContext(FormFilterContext);
    return <>
        <Button.Group>
            <UserFilterForm.ButtonForm content={'Każdy typ'} value={{account_type: undefined}}/>
            <UserFilterForm.ButtonForm content={'Standard'} value={{account_type: 'standard'}}/>
            <UserFilterForm.ButtonForm content={'Płatne premium'} value={{account_type: 'paid_premium'}}/>
            <UserFilterForm.ButtonForm content={'Płatne VIP'} value={{account_type: 'paid_vip'}}/>
        </Button.Group>
        {' '}
        <Button.Group>
            <UserFilterForm.ButtonForm content={'Każda płeć'} value={{gender: undefined}}/>
            <UserFilterForm.ButtonForm content={'Tylko kobiety'} value={{gender: 'f'}}/>
            <UserFilterForm.ButtonForm content={'Tylko mężczyźni'} value={{gender: 'm'}}/>
        </Button.Group>
        {' '}
        <UserFilterForm.SelectField name={'had_premium'} label={'Miał premium'} options={[
            {text: 'Tak', value: 1},
            {text: 'Nie', value: 0},
            {text: 'Niedotyczy', value: undefined},
        ]}/>
        {' '}
        <If condition={ filter && filter.had_premium === 1}>
            <UserFilterForm.Field name={'premium_ended_within'} as={
                <Form.Input type={'number'}>
                    <Label basic>Minęło od zakończenia płatnego premium mniej niż</Label>
                    <input/>
                    <Label basic>dni</Label>
                </Form.Input>
            }/>
            {' '}
            <UserFilterForm.Field name={'premium_ended_over'} as={
                <Form.Input type={'number'}>
                    <Label basic>Minęło od zakończenia płatnego premium więcej niż</Label>
                    <input/>
                    <Label basic>dni</Label>
                </Form.Input>
            }/>
        </If>
        {' '}
        <UserFilterForm.Field name={'register_within'} as={
            <Form.Input type={'number'}>
                <Label basic>Minęło od rejestracji mniej niż</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'register_over'} as={
            <Form.Input type={'number'}>
                <Label basic>Minęło od rejestracji więcej niż</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'last_login_within'} as={
            <Form.Input type={'number'}>
                <Label basic>Logował się w ciągu ostatnich</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'last_login_over'} as={
            <Form.Input type={'number'}>
                <Label basic>Nie logował się więcej niż</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'login_exact'} as={
            <Form.Input type={'text'}>
                <Label basic>Login użytkownika</Label>
                <input/>
            </Form.Input>
        }/>
    </>
}