import React from 'react';
import {ROUTE} from "../../route-names";
import {Header, Icon, Image, Popup} from "semantic-ui-react";
import {Link} from "react-router-dom";
import HotIcon from './hot_icon.png';
import {If} from "../helpers/Condition";
import {parseDate} from "../../utils";
import {PhotoSrc} from "../../pages/users/components/user-page/photos-section";

export const WarningIcon = ({title, color}) => <Icon circular name={'exclamation'} color={color || 'yellow'} title={title}/>;

export const DivInline = ({children}) => (
    <div style={{display: 'inline'}}>
        {children}
    </div>
)

export const UserStatus = ({user}) => {

    return <div style={{display: 'grid'}}>
        {/* <If condition={user.had_premium}>
            <DivInline>
                <Icon circular color={user.premium_date_end ? 'green' : 'gray'} name={'dollar'}/>
                - {user.premium_date_end ? "Premium aktywne" : 'Premium zakończone'}
            </DivInline>
        </If> */}
        <If condition={user.premium_type == 'vip' && user.premium_payment != 'hot_person'}>
            <DivInline><Icon circular color={'blue'} name={'dollar'} /> - VIP aktywne</DivInline>
        </If>
        <If condition={user.premium_type == 'premium' && user.premium_payment != 'hot_person'}>
            <DivInline><Icon circular color={'green'} name={'dollar'} /> - Premium aktywne</DivInline>
        </If>
        <If condition={user.had_premium}>
            <DivInline><Icon circular color={'red'} name={'dollar'} /> - Premium zakończone</DivInline>
        </If>
        <If condition={user.has_free_premium}>
            <DivInline><Icon circular color={'grey'} name={'dollar'} /> - Darmowe premium aktywne</DivInline>
        </If>

        <If condition={user.hot_person}>
            <DivInline>
                <Image src={HotIcon} avatar />
                - Gorąca osoba
            </DivInline>
        </If>

        <If condition={user.auto_ban}>
            <DivInline>
                <WarningIcon/>
                - Blokada spowodowana ilością zgłoszeń
            </DivInline>
        </If>

        <If condition={!user.approved_profile}>
            <DivInline>
                <WarningIcon/>
                - Użytkownik nie został zweryfikowany
            </DivInline>
        </If>


    </div>
}

export const getPopupContent = (user) => {
    return <div>
        <Header>
            {user.login} {`[${user.gender || '-'}]`}
        </Header>

        <UserStatus user={user}/>
    </div>;

}

export const UserName = ({user, premium_icon=true, hot_icon=true, login=true, link=true, gender=false, banned=false, deleted=false, messages=false, country=false, account_type=false, connected_warn=false}) => {
    let premiumIcon = '';
    if (premium_icon) {
        if (user.premium_type == 'vip' && user.premium_payment != 'hot_person') {
            premiumIcon = <><Icon circular color={'blue'} name={'dollar'} />{' '}</>;
        } else if (user.premium_type == 'premium' && user.premium_payment != 'hot_person') {
            premiumIcon = <><Icon circular color={'green'} name={'dollar'} />{' '}</>;
        } else if (user.had_premium) {
            premiumIcon = <><Icon circular color={'red'} name={'dollar'} />{' '}</>;
        } else if (user.has_free_premium) {
            premiumIcon = <><Icon circular color={'grey'} name={'dollar'} />{' '}</>;
        }
    }

    const Content = () => (
        <span style={{display: 'inline-flex'}}>
            <PhotoSrc photo_id={user.have_profile?.id} render={({originalSrc}) => (
                <Image src={originalSrc ?? process.env.PUBLIC_URL + '/user_avatar.png'} avatar/>
            )}/>
            <span style={{'marginTop': '5px', overflowWrap: 'anywhere'}}>
                <If condition={login}>
                    {user.login}
                    {' '}
                    {
                        gender &&
                        `[${user.gender?.toUpperCase() || '-'}]`
                    }
                </If>
                {' '}
                <If condition={user.last_messages && user.last_messages.length > 0 && messages}>
                    <Popup trigger={<span style={{'fontWeight': 'bold'}}>[MSG]</span>} wide='very'>
                        <span style={{'fontWeight': 'bold'}}>
                            Ostatnie wysłane wiadomości:
                        </span>
                        {user.last_messages?.map(msg => <div>
                            <span style={{'fontWeight': 'bold'}}>
                            "{msg.body}"
                            </span>
                            - {parseDate(msg.created_at)}
                        </div>)}
                    </Popup>
                    {' '}
                </If>
                <If condition={user.banned && banned}>
                    <span style={{color: 'blue', 'fontWeight': 'bold'}}>[BAN]</span>
                    {' '}
                </If>
                <If condition={user.deleted && deleted}>
                    <span style={{color: 'red', 'fontWeight': 'bold'}}>[DEL]</span>
                    {' '}
                </If>
                <If condition={user.country != null && country}>
                    <span>[{user.country?.name}]</span>
                    {' '}
                </If>
                <If condition={user.premium_type && account_type && user.premium_type == 'vip'}>
                    <span style={{color: 'blue', fontWeight: 'bold'}}>[VIP]</span>
                    {' '}
                </If>
                <If condition={user.premium_type && account_type && user.premium_type == 'premium'}>
                    <span style={{color: 'blue', fontWeight: 'bold'}}>[Premium]</span>
                    {' '}
                </If>
                <If condition={user.premium_type && account_type && (user.premium_type == 'free premium')}>
                    <span style={{color: 'blue'}}>[Free Premium]</span>
                    {' '}
                </If>
                <If condition={user.premium_type && account_type && (user.premium_type == 'standard')}>
                    <span>[Stand]</span>
                    {' '}
                </If>
            </span>
            {premiumIcon}
            {hot_icon && user.hot_person ? <Image src={HotIcon} avatar/> : null}
            <If condition={user.auto_ban}>
                <WarningIcon title={'Automatyczny ban użytkownika'}/>
            </If>
            <If condition={connected_warn && user.connected_users && user.connected_users.length > 0}>
                <WarningIcon title={'Użytkownik powiązany z innymi użytkownikami'} color={'red'}/>
            </If>
        </span>
    )

    return <div>
        {link ? (
            <Link to={ROUTE.user(user.id)} style={{color: '#000'}}>
                <Content/>
            </Link>
        ) : (
            <Content />
        )}
    </div>

    // return <Popup wide='very' trigger={
    //     <div>
    //         {link ?
    //             <MemoLink/> :
    //             <Content/>}
    //     </div>
    // }>
    //     {getPopupContent(user)}
    // </Popup>
};