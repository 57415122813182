import React, {createContext} from "react";
import {Modal} from "semantic-ui-react";
import {usePhotosApi} from "../../api/photos";
import {usePhotoCrop} from "../photo-crop";
import {useQueryCache} from "react-query";
import {EditPhotoContent} from "../../pages/photos/edit-photo";

export const CropPhotoModalContext = createContext({photo: {}, setPhoto: undefined});

export default function CropPhotoModalProvider({children}){
    const photoCrop = usePhotoCrop();
    const {setPhoto, photo, scale, crop} = photoCrop;
    const photosApi = usePhotosApi(photo?.id);
    const queryCache = useQueryCache();

    const handleSubmit = async (data) => {
        setPhoto({})
    };

    return <CropPhotoModalContext.Provider value={{photo, setPhoto}}>
        <Modal open={!!photo?.id} onClose={() => setPhoto({})}>
                <EditPhotoContent photo={photo} onSubmit={handleSubmit} />
        </Modal>
        {children}
    </CropPhotoModalContext.Provider>
}