import React from "react";
import {Button, Container, Divider, Form, Grid, GridColumn, GridRow, Header, Message} from "semantic-ui-react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Field, reduxForm } from "redux-form";
import { InputField, SelectField, Checkbox } from 'react-semantic-redux-form';
import { connect } from "react-redux";
import {getContent, getContentCountries, getContentRegions, getContentCities, submitContent} from "../../modules/content";
import 'react-image-crop/dist/ReactCrop.css';
import { useQueryCache } from "react-query";
import {domainOptions, languageOptions} from "../../constants";

const required = value => value ? undefined : 'Wymagane'
const mapOptions = (data) => data ? [{
    key: 0,
    value: null,
    text: 'Nie wybrano'
}, ...data?.map(item => ({
    key: item.id,
    value: item.id,
    text: item.name
}))] : [];

export const EditContentView = props => {
    let { change, error, onSubmit, initialValues, handleSubmit, form, countries, regions, cities, byLocation } = props;
    if (!initialValues)
        initialValues = { content: ''};

    const queryCache = useQueryCache();

    return <Container>
        <Divider hidden />
        <Form autoComplete="off" error={error}>
            {error &&
                <Message error content={'Wystąpił błąd'} />
            }

            <Field name={'title'} component={InputField} label={'Tytuł'} validate={[required]} />

            <div style={{ display: 'none' }}>
                <Field name={'content'} component={InputField} label={'Treść'} />
            </div>
            <CKEditor
                editor={ClassicEditor}
                data={initialValues?.content}
                onChange={(event, editor) => {
                    change('content', editor.getData());
                }}
            />
            <Divider hidden />

            {!byLocation && <Field name={'url'} component={InputField} label={'URL'} validate={[required]}/>}

            <Field name={'domain'} component={SelectField} label={'Strona'} validate={[required]}
                options={domainOptions}
                search={false} />

            <Field name={'lang'} component={SelectField} label={'Język'} validate={[required]}
                options={languageOptions}
                search={false} />
            <Divider hidden />
            {!byLocation && <Field
                component={Checkbox}
                name='is_article'
                label="Artykuł"
            />}
            <Divider />

            <Divider hidden />
            {byLocation && <Grid columns={2}>
                <Header as={'h4'}>Przypisz do wyszukiwarki:</Header>
                <GridRow>
                    <GridColumn>
                        <Field name={'region_id'} component={SelectField} label={'Województwo'} validate={[]}
                               options={regions}
                               search={true} />
                        <Field name={'region_priority'} component={InputField} label={'Strona'} validate={[]}
                               min={0} max={10000} type={'number'} />
                    </GridColumn>
                    <GridColumn>
                        <Field name={'city_id'} component={SelectField} label={'Miasto'} validate={[]}
                               options={cities}
                               search={true} />
                        <Field name={'city_priority'} component={InputField} label={'Strona'} validate={[]}
                               min={0} max={10000} type={'number'} />
                    </GridColumn>
                </GridRow>
            </Grid>}
            <Divider hidden />

            <Button type='button' id='submitFormButton' onClick={handleSubmit(() => {
                onSubmit(form)
                if (initialValues?.id)
                    queryCache.invalidateQueries(['contents', initialValues.id])
                else
                    queryCache.invalidateQueries(['contents'])
            })}>Zapisz</Button>
        </Form>
    </Container>
};

export const EditContentFormComponent = reduxForm({
    form: 'contentEdit',
    enableReinitialize: true,
})(EditContentView);

export default connect(state => ({
    countries: mapOptions(getContentCountries(state)),
    regions: mapOptions(getContentRegions(state)),
    cities: mapOptions(getContentCities(state)),
    initialValues: getContent(state),
    content: getContent(state),
}), { onSubmit: submitContent })(EditContentFormComponent);
