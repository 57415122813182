import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Container, Divider, Header, Pagination, Segment } from "semantic-ui-react";
import Moment from 'moment';
import { TableFromArray } from "../../components/stateless/Table";
import { changePage, fetchLoginLogs, resetLoginLogsQuery, getLoginLogs, getLoginLogsMeta } from "../../modules/login_logs";
import {useUsersRouter} from "../../hooks/router";

const LoginLogsSection = ({ view, loginLogs }) => {
    const [loading, setLoading] = useState(false);
    const usersRouter = useUsersRouter();

    useEffect(() => setLoading(false), [loginLogs])

    return <TableFromArray data={loginLogs} columns={[
        ['Data', (data) => Moment(data.date).format('Y-MM-DD HH:mm')],
        ['Adres', (data) => {
            return <div><span onClick={() => usersRouter.goToUsersPage(false, { ip: data.ip })} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                {data.ip}
            </span>:{data.port || 0}</div>
        }],
        ['Typ', (data) => {
            switch (data.type) {
                case 'normal': return 'Wprowadzenie danych';
                case 'link': return 'Link';
                case 'logged': return 'Zalogowany ciągle';
                default: return undefined;
            }
        }],
    ]} />
};

const UserLoginLogsView = () => {
    let loginLogs = useSelector(getLoginLogs);
    const [view, setView] = useState(0);
    const meta = useSelector(getLoginLogsMeta);
    const dispatch = useDispatch();

    const setPageAndFetchComments = (page) => {
        dispatch(changePage(page));
        dispatch(fetchLoginLogs());
    };

    return <Container style={{ width: '95%' }}>
        <Divider hidden />
        <Header as='h2'>
            Logowania użytkownika
        </Header>
        <Divider hidden />
        <LoginLogsSection view={view} loginLogs={loginLogs} />
        <Divider />
        <Segment basic textAlign={"center"}>

            <Pagination activePage={meta.current_page}
                totalPages={meta.last_page}
                onPageChange={(e, { activePage }) => setPageAndFetchComments(activePage)}
            />
        </Segment>
    </Container>;
};

export const UserLoginLogsPage = () => {
    const dispatch = useDispatch();

    const { id } = useParams();

    useEffect(() => {
        dispatch(changePage(1));
        dispatch(resetLoginLogsQuery({ user_id: id }));
        dispatch(fetchLoginLogs());

        return () => {
            dispatch(resetLoginLogsQuery({}));
            dispatch(changePage(1));
        }
    }, [id, dispatch]);

    return <UserLoginLogsView />
};