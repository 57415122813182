import {Button, Divider, Header, Table, Popup} from "semantic-ui-react";
import React from "react";
import {PageContainer} from "../../page/PageContainer";


export const AdminTableData = ({adminData, onClickEdit, onRemoveClick}) => {
    const Actions = () => <>
        <Button id='editUserButton' floated='right' onClick={onClickEdit}>Edytuj</Button>
        <Popup on={"click"} trigger={<Button floated={'right'} content={`Usuń`} />}>
            <div>Czy na pewno usunąć tego administratora?</div>
            <Button onClick={onRemoveClick} content={'Usuń'} />
        </Popup>
    </>

    return <PageContainer name={'Edytuj administratora'} actions={Actions}>
        <Divider hidden/>
        <Header as="h3" dividing>
            Dane
        </Header>

        <Header as="h3" dividing>
            Informacje
        </Header>
        <Table celled>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Imię</Table.Cell>
                    <Table.Cell>{adminData.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Email</Table.Cell>
                    <Table.Cell>{adminData.email}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Rola</Table.Cell>
                    <Table.Cell>{adminData.role}</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Logowanie</Table.Cell>
                    <Table.Cell>{adminData.logging ? 'Tak' : 'Nie'}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    </PageContainer>

}