import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Divider} from "semantic-ui-react";
import AdminsTableContainer from "../../containers/AdminsTableContainer";
import AdminsFilterForm from "../../components/stateless/admins/AdminsFilterForm";
import AdminsTablePagesContainer from "../../containers/AdminsTablePagesContainer";
import {fetchAdmins, getAdminsFilter, resetAdminsFilter, resetAdminsMeta} from "../../modules/admins";
import {Link} from "react-router-dom";
import {ROUTE} from "../../route-names";
import {PageContainer} from "../../components/page/PageContainer";


class Admins extends Component {
    constructor(props) {
        props.fetchAdmins();
        super(props);
    }

    handleFilterChange( event ){
        let filter = Object.assign({}, this.props.filter, { [event.target.id]: event.target.value });
        this.props.resetAdminsFilter(filter);
        this.props.fetchAdmins();
    }

    handlePageChange( ...prop){
        let page = prop[1].id;
        let filter = Object.assign({}, this.props.filter, { page });
        this.props.resetAdminsMeta(filter);
        this.props.fetchAdmins();
    }

    renderLoad(){
        // return this.props.loading ?  <Dimmer active>
        //     <Loader />
        // </Dimmer> : <UsersTableContainer/>
        return <AdminsTableContainer
            actions={(id)=><div>
                <Link to={ROUTE.admin(id)}>
                    <Button>Profil</Button>
                </Link>
            </div>}
        />
    }



    render() {
        const Actions = () => <Link to={ROUTE.NEW_ADMIN}>
            <Button id='editUserButton' floated='right'>Dodaj administratora</Button>
        </Link>

        return <PageContainer name={'Administratorzy'} actions={Actions}>
            <Divider hidden/>

            <Divider />
            <AdminsFilterForm filter={this.props.filter} onChange={this.handleFilterChange.bind(this)}/>
            {this.renderLoad()}
            <AdminsTablePagesContainer onClick={this.handlePageChange.bind(this)}/>
        </PageContainer>
    }
}

const mapStateToProps = (state) => {
    return {
        admins: state.admins.admins,
        filter: getAdminsFilter(state),
    };
};

export default connect(mapStateToProps, {fetchAdmins, resetAdminsFilter, resetAdminsMeta})(Admins);