import React from 'react'
import { Message, MessageList, MessageText, Row } from '@livechat/ui-kit'
import { getParticipantById } from "../../../utils/chat-utils";
import { parseDate } from "../../../utils";
import { UserName } from "../../User/UserName";
import { Divider } from "semantic-ui-react";

const deliveryStatusText = (message) => {
    let isRead = message.is_read ? 'Odczytana' : null;
    let deletedBySender = message.deleted_by_sender ? 'usunięta przed nadawcę' : null;
    let deletedByRecipient = message.deleted_by_recipient ? 'usunięta przed odbiorcę' : null;
    let array = [isRead, deletedBySender, deletedByRecipient].filter(text => text != null);
    if (array.length > 0)
        return array.join(', ');
}

const textColor = (message) => {
    if (message.deleted_by_sender || message.deleted_by_recipient) return '#bfbfbf';
    else return 'black';
}

export const UserMessagesView = ({ messages, participants, actual_user_id }) => {
    const otherParticipant = participants.find(participant => participant.user.id != actual_user_id);

    return <>
        <div style={{ flex: '1', padding: '15px' }}>
            { otherParticipant != null ? <><div>Rozmówca: <UserName user={otherParticipant.user} gender banned deleted country account_type /></div><Divider /></> : '' }
            <MessageList active>
                {messages.map(message => {
                    const user = getParticipantById(participants, message.user_id);
                    const own = parseInt(message.user_id) === parseInt(actual_user_id);
                    return <>
                        <Row reverse={own}>
                            <div style={{ display: 'inline-flex' }}>
                                <UserName user={user?.user} gender banned deleted country account_type />
                                <span style={{ marginTop: '5px', marginLeft: '10px' }}>{parseDate(message.created_at)}</span>
                            </div>
                        </Row>
                        <Row reverse={own}>
                            <Message isOwn={own} deliveryStatus={deliveryStatusText(message)}>
                                <MessageText
                                    style={{
                                        background: own ? '#c0e4ff' : 'white',
                                        color: textColor(message),
                                        borderRadius: '10px',
                                        fontWeight: message.is_read ? '100' : 'bold'
                                    }}>{message.body}</MessageText>
                            </Message>
                        </Row>
                    </>
                })}
            </MessageList>
        </div>
    </>
};