import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getLoggedIn, getLogoutTime, logout, resetLogoutTime} from "../../modules/app";
import Moment from "moment";
import {Header, Icon, Menu} from "semantic-ui-react";
import {Link as ReactLink, useHistory, useLocation} from "react-router-dom";
import {ROUTE} from "../../route-names";
import {store} from "../../index";

export const RefreshToken = () => {
    const dispatch = useDispatch();

    const handleRefreshToken = () => {
        dispatch(resetLogoutTime());
    };

    const [logoutTime, setLogoutTime] = useState(0);

    useEffect( () => {
        const timer = setInterval(() => setLogoutTime(getLogoutTime(store.getState())), 100)
        return () => clearInterval(timer)
    }, [])

    // eslint-disable-next-line
    return <a onClick={handleRefreshToken} style={{color: '#c6c6c6'}}>
        <Icon name={'refresh'}/>
        {Moment("2000/01/01 00:00").add(logoutTime, 's').format("mm:ss")}
    </a>
}

const Link = (props) => {
    const {pathname} = useLocation();
    return <ReactLink {...props} style={ pathname == props.to ? {color: '#2185d0'} : {}}/>
}

const MainMenu = ({children}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    window.onclick = () => dispatch(resetLogoutTime());
    const isLoggedIn = useSelector(getLoggedIn);

    useEffect( () => {
        if(!isLoggedIn)
            history.push('/login')
    }, [isLoggedIn])

    return <>
        <div style={{position:'fixed',display:'flex',flexDirection:'column',top:0,bottom:0,left:0,width:'210px',flex:1}}>
            <Menu vertical inverted style={{minHeight: '100vh', overflowY: 'auto'}}>

                <RefreshToken/>
                <Link to={'/dashboard'}>
                    <Menu.Item>
                        <Header as={'h1'} inverted style={{color: '#2185d0'}}>
                            Buziak Admin
                        </Header>
                        {/*<img src={ process.env.PUBLIC_URL + '/logo.svg'} alt={''}/>*/}
                    </Menu.Item>
                </Link>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.DASHBOARD} content={'Strona Główna'}/>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.USERS} content={'Użytkownicy'}/>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={ROUTE.REPORTS}
                                   content='Zgłoszeni Użytkownicy'
                        />
                        <Menu.Item as={Link}
                                   to={ROUTE.USER_DESCRIPTIONS}
                                   content='Opisy Użytkowników'
                        />
                        <Menu.Item as={Link}
                                   to={ROUTE.MESSAGES}
                                   content='Wiadomości Użytkowników'
                        />
                        <Menu.Item as={Link}
                                   to={ROUTE.USERS_PLANS}
                                   content='Zachcianki'
                        />
                        <Menu.Item as={Link}
                                   to={ROUTE.COMMENTS}
                                   content='Komentarze'
                        />
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={'/photos'}>Zdjęcia</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={ROUTE.PHOTOS_TO_CROP}
                                   content='Do Kadrowania'
                        />
                        <Menu.Item as={Link}
                                   to={ROUTE.VERIFY_PHOTOS}
                                   content='Zdjęcia Weryfikujące'
                        />
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.ORDERS}>Płatności</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={'/paymentPlans'}
                                   content='Plany Premium'
                        />
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.MAILING}>Mailing</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={ROUTE.SUGGESTIONS}
                                   content='Kontakt i sugestie'
                        />
                        <Menu.Item as={Link}
                                   to={ROUTE.VERIFICATION_MAIL}
                                   content='Werifikacja'
                        />
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.FILTERS} content={'Filtry'}/>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.CONTENTS} content={'Content'}/>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={ROUTE.CONTENTS_BY_LOCATION}
                                   content='Przypisane do wyszukiwarki'
                        />
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item>
                    <Menu.Header as={Link} to={ROUTE.ADMINS} content={'Administratorzy'}/>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={ROUTE.ACTIVITIES}
                                   content='Aktywność administratorów'
                        />
                    </Menu.Menu>
                    <Menu.Menu>
                        <Menu.Item as={Link}
                                   to={ROUTE.ROLES}
                                   content='Role i Pozwolenia'
                        />
                    </Menu.Menu>
                </Menu.Item>

                <Menu.Item onClick={() => dispatch(logout())}>
                    Wyloguj
                </Menu.Item>

            </Menu>
        </div>
        <div style={{marginLeft:210,minWidth:'550px'}}>
            {children}
        </div>
    </>
}

export default MainMenu;
