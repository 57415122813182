import { Method } from "axios";
import { useMutation, useQueryCache } from "react-query";
import { contentRequest } from "./requests";
import { MutationConfig } from "react-query/types/core/types";

export const useContentsApi = (id: number) => {
    const queryCache = useQueryCache();
    const [mutate, state] = useMutation(contentRequest, {
        onSuccess: () => {
            queryCache.invalidateQueries(['contents'])
        }
    });

    const withEntityId = (data: any, action?: string) => () => mutate({ id, action, data, method: 'patch' })

    return {
        delete: withEntityId({ method: "DELETE" }),
        edit: (data: any) => mutate({ id, data }),
        ...state
    }
}


export const useContentApi = () => {
    const queryCache = useQueryCache();
    const [mutate, state] = useMutation(contentRequest, {
        onSuccess: () => {
            queryCache.invalidateQueries(['contents'])
        }
    });

    const withEntityId = ({ data, action, config, method }: {
        data?: any, action?: string, method?: Method,
        config?: (id: number) => MutationConfig<any>
    }) => (id: number) => mutate({ id, action, data, method: method ?? 'patch' }, config?.(id))

    return {
        delete: withEntityId({ method: "DELETE" }),
        ...state
    }
}

type ContentsApiMethods = ReturnType<typeof useContentsApi>;

export const ContentsApiProvider = ({ id, render }: { id: number, render: (api: ContentsApiMethods) => any }) => {
    const contentsApi = useContentsApi(id);
    return render(contentsApi)
}
