import React from "react";
import {DateInput} from 'semantic-ui-calendar-react';

const Date = ({onChange, value, name, placeholder, ...others}) => (
    <DateInput
        value={value}
        name={name}
        placeholder={placeholder}
        dateFormat="YYYY-MM-DD"
        closable
        startMode='year'
        preserveViewMode={false}
        onChange={(e,{value}) => {
            onChange(value);
        }}
        {...others}
    />
);

export default Date;