import React from "react";
import {PhotosView} from "../photos/photos"; //todo move to components
import {useParams} from "react-router";
import {useGetUsersPhotos, useNewUserApi} from "../../api/users";
import {Button, Container} from "semantic-ui-react";

export const UserPhotos = () => {
    const filters = [
        ['Wszystkie', {}],
        ['Publiczne', {private: 0}],
        ['Prywatne', {private: 1}],
    ];

    const {id} = useParams();
    const {photos, setFilters, filters: actualFilter, setPage, data} = useGetUsersPhotos(id, { trashed: 'with'}, 1, 'position');
    const inputOpenFileRef = React.createRef()
    const userApi = useNewUserApi();

    return <Container fluid>
        <input ref={inputOpenFileRef} type="file" style={{ display: "none" }} onChange={(e) => {
            userApi.uploadPhoto(id, e.target.files[0]);
        }}/>

        <PhotosView
            photos={photos}
            onChangeFilter={setFilters}
            actual_filter={actualFilter}
            pagination={data?.meta ?? {}}
            onChangePage={setPage}
            filters={filters}
            actions={(
                <Button content={"Dodaj zdjęcie"} floated={"right"} onClick={() => inputOpenFileRef.current.click()}/>
            )}
        />
        </Container>
}

export default UserPhotos;