import React from "react";
import { Button, Container, Divider, Header, Popup } from "semantic-ui-react";
import { Pagination } from "../../components/tables/pagination";
import { useCustomPaginatedQuery } from "../../api/core";
import { contentsRequest } from "../../api/requests";
import { useContentApi } from "../../api/contents";
import { TableWithWrapperView } from "../../components/stateless/Table";
import { useAppRouter } from "../../hooks/router";
import { ROUTE } from "../../route-names";

export const ContentsList = ({ contents }) => {
  const api = useContentApi();
  const { history } = useAppRouter();

  return <TableWithWrapperView data={contents} columns={{
    'title': {
      name: 'Tytuł'
    },
    'url': {
      name: 'URL'
    },
    'lang': {
      name: 'Język'
    },
    'domain': {
      name: 'Strona'
    },
    'is_article': {
      name: 'Artykuł',
      wrapper: (data) => (data.is_article ? 'Tak' : 'Nie')
    },
    'options': {
      name: 'Opcje',
      wrapper: (data) => {
        return <>
          <Popup on={"click"} trigger={<Button content={'Usuń'} />}>
            Czy na pewno usunąć ten artykuł?
            <Button onClick={() => api.delete(data.id)} content={'Usuń'} />
          </Popup>
          <Button content={'Edytuj'} onClick={() => history.push(ROUTE.content(data.id))} />
        </>
      }
    }
  }} />
};

export const ContentsView = ({ contents, pagination, onChangePage }) => {
  const handlePageChange = page => {
    onChangePage(page)
  }

  return <>
    <ContentsList contents={contents} />
    <Pagination pagination={pagination} onChange={handlePageChange} />
  </>
};

export const Contents = () => {
  const { data: contents, setPage, pagination } = useCustomPaginatedQuery('contents', contentsRequest, {
    initConfig: { perPage: 1000 }
  });

  const { history } = useAppRouter();
  
  const changePageAndFetch = (page) => {
    setPage(page)
  };

  return <Container style={{ width: '95%' }}>
    <Divider hidden />
    <Header as='h2'>
      Artykuły <Button content={'Dodaj'} onClick={() => history.push(ROUTE.CONTENT_NEW)}/>
    </Header>

    <ContentsView
      contents={contents}
      pagination={pagination}
      onChangePage={changePageAndFetch}
    />
  </Container>
};

export default Contents;