import {Form} from "semantic-ui-react";
import React from "react";

export const mapArrayOptionsToObject = (options) => {
    return options.map(function (option, i) {
        return {key: i, text: option, value: i};
    });
};

const Select = ({input, options, label, onChange, ...custom}) => {
    return <Form.Select {...input} {...custom} placeholder={label} label={label} clearable={true}
               options={mapArrayOptionsToObject(options)}
                onChange={(e,{value})=>(onChange(value))}
    />
}

export default Select
