import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Checkbox,
    Divider,
    Form as SForm,
    Header,
    Icon,
    Image,
    Modal,
    Popup,
    Segment,
    Table
} from "semantic-ui-react";
import {getUserStore} from "../../store/createRootReducer";
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {ROUTE} from "../../route-names";
import {reverse} from "named-urls";
import {useParams} from "react-router";
import {UserInfoNetworkTable} from "../../components/stateless/user/UserInfoNetworkTable";
import UserBlockForm from "../../components/forms/user-block-form"
import {getBlockModalOpen, openBlockModal} from "../../modules/user";
import HotIcon from '../../components/User/hot_icon.png';
import {push} from "react-router-redux";
import {UserName, UserStatus} from "../../components/User/UserName";
import {useApi, useModal} from "../../components/hooks";
import {api_url, parseDate} from "../../utils";
import {PageContainer} from "../../components/page/PageContainer";
import useUserApi from "../../api/user";
import Form from "../../components/forms/core";
import {useForm} from "react-hook-form";
import {useGetUser, useNewUserApi} from "../../api/users";
import {useAppRouter, useUsersRouter} from "../../hooks/router";
import {useQueryCache} from "react-query";
import EdiText from '@mumot1999/react-editext'
import {usePendingChangeApi, usePendingChangesApi} from "../../api/pending-changes";
import {Subtitle} from "@livechat/ui-kit";
import {UsersPhotos} from "./components/user-page/photos-section";
import {resetUserDescriptionsQuery} from "../../modules/user_descriptions";

const PremiumTypeView = ({ account_type, payment_method, date_start, date_end, had_premium = false, has_free_premium = false }) => {
    let color = 'black';
    if (account_type == 'vip' && payment_method != 'hot_person')
        color = 'blue';
    else if (account_type == 'premium' && payment_method != 'hot_person')
        color = 'green';
    else if (had_premium)
        color = 'red';
    else if (has_free_premium)
        color = 'grey';

    let secondRowText;
    if (payment_method == 'female_premium')
        secondRowText = '- darmowe konto dla kobiet';
    else if (payment_method == 'free_premium')
        secondRowText = (date_start ? `(${date_start} - ${date_end})` : '') + ' - darmowe premium';
    else if (payment_method == 'hot_person')
        secondRowText = (date_start ? `(${date_start} - ${date_end})` : '') + ' - HOT Person';
    else if (payment_method == 'manual')
        secondRowText = (date_start ? `(${date_start} - ${date_end})` : '') + ' - nadane ręcznie';
    else
        secondRowText = date_start ? `(${date_start} - ${date_end})` : '';

    return <>
        <span style={{ textTransform: 'capitalize', color: color }}>{account_type}</span>
        <span style={{ marginLeft: '5px' }}>{secondRowText}</span>
    </>
}

const FuturePremiumsView = ({user}) => {
    const future_premiums = user.future_premiums;
    if (future_premiums && future_premiums.length > 0) {
        const rows = [];
        future_premiums.forEach(premium => {            
            rows.push(
                <li>
                    <PremiumTypeView account_type={premium.account_type} payment_method={premium.payment_method} date_start={premium.date_start} date_end={premium.date_end} />
                </li>
            );
        });

        return <div style={{marginTop: '15px'}}>
            <div>Pozostałe premium:</div>
            <ul>
                {rows.map(row => row)}
            </ul>
        </div>

    } else {
        return <div></div>
    }
}

export const UserInfoView = props => {
    const {blockUser} = props;
    const {id} = useParams();
    const dispatch = useDispatch();
    const userApi = useNewUserApi(id);
    const usersRouter = useUsersRouter();
    const {user_id} = usersRouter;
    const {user_data: data} = useGetUser(user_id);
    const oldUserApi = useUserApi();
    const queryCache = useQueryCache();
    const openModal = (mode=true) => dispatch(openBlockModal(mode));
    const {modal, setContentAndOpenModal, openConfirmModal} = useModal();

    const [isNewDescriptionEdit, setIsNewDescriptionEdit] = useState(false)
    const [isNewNameEdit, setIsNewNameEdit] = useState(false)

    const pendingChangesApi = usePendingChangesApi();
    const pendingChangeApi = usePendingChangeApi();

    const options = [
        { key: 'edit', icon: 'edit', text: 'Otwórz formularz', onClick: () => dispatch(push(reverse(ROUTE.ORDER_CREATE, {user_id: id}))) }
    ]

    const appRouter = useAppRouter();
    return <PageContainer>
        {modal}
        {/*<Modal
            open={blockUser}
            onClose={() => openModal(false)}
            size='small'
        >
            <Header icon='browser' content={`Blokowanie użytkownika ${data.login}`} />
            <Modal.Content>
                <UserBlockForm/>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' onClick={() => openModal(false)} inverted>
                    <Icon name='checkmark' /> Anuluj
                </Button>
            </Modal.Actions>
        </Modal>*/}

        <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: '50%'}}>
                <Divider horizontal>
                    Opis
                </Divider>
                <Segment>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>Aktualny opis</Card.Header>
                            <Card.Description>
                                <EdiText
                                    showButtonsOnHover
                                    type='textarea'
                                    inputProps={{
                                        className: 'textarea',
                                        placeholder: 'Wpisz opis',
                                        style: {
                                            outline: 'none',
                                            width: '100%'
                                        },
                                        rows: 5
                                    }}
                                    value={data.description}
                                    onSave={value => userApi.edit(id, {description: value})}
                                />
                            </Card.Description>
                        </Card.Content>
                    </Card>

                    {data.description_pending ? (
                        <Card fluid>
                            <Card.Content>
                                <Card.Header>Nowy opis</Card.Header>
                                <Card.Meta>{parseDate(data.description_pending.created_at)}</Card.Meta>
                                <Card.Description>
                                    <EdiText
                                        showButtonsOnHover
                                        type='textarea'
                                        inputProps={{
                                            className: 'textarea',
                                            placeholder: 'Wpisz opis',
                                            style: {
                                                outline: 'none',
                                                width: '100%'
                                            },
                                            rows: 5
                                        }}
                                        onEditingStart={() => setIsNewDescriptionEdit(true)}
                                        onCancel={() => setIsNewDescriptionEdit(false)}
                                        value={data.description_pending.value}
                                        onSave={value => pendingChangesApi.acceptChange(data.description_pending.id, value).then(_ => dispatch(resetUserDescriptionsQuery({id: data.description_pending.id})))}
                                    />
                                </Card.Description>
                            </Card.Content>
                            {!isNewDescriptionEdit ? (
                                <Card.Content extra>
                                    <Button basic color='green' onClick={() => pendingChangesApi.acceptChange(data.description_pending.id, data.description_pending.value).then(_ => dispatch(resetUserDescriptionsQuery({id: data.description_pending.id})))}>
                                        Zaakceptuj
                                    </Button>

                                    <Button basic color='red' onClick={() => pendingChangesApi.denyChange(data.description_pending.id, data.description).then(_ => dispatch(resetUserDescriptionsQuery({id: data.description_pending.id})))}>
                                        Odrzuć
                                    </Button>

                                </Card.Content>
                            ): ''}

                        </Card>
                    ): ''}

                    {data.name_pending ? (
                        <Card fluid>
                            <Card.Content>
                                <Card.Header>Nowe imię</Card.Header>
                                <Card.Meta>{parseDate(data.name_pending.created_at)}</Card.Meta>
                                <Card.Description>
                                    <EdiText
                                        showButtonsOnHover
                                        type='textarea'
                                        inputProps={{
                                            className: 'textarea',
                                            placeholder: 'Wpisz opis',
                                            style: {
                                                outline: 'none',
                                                width: '100%'
                                            },
                                            rows: 5
                                        }}
                                        onEditingStart={() => setIsNewNameEdit(true)}
                                        onCancel={() => setIsNewNameEdit(false)}
                                        value={data.name_pending.value}
                                        onSave={value => pendingChangesApi.acceptChange(data.name_pending.id, value).then(_ => dispatch(resetUserDescriptionsQuery({id: data.name_pending.id})))}
                                    />
                                </Card.Description>
                            </Card.Content>
                            {!isNewNameEdit ? (
                                <Card.Content extra>
                                    <Button basic color='green' onClick={() => pendingChangesApi.acceptChange(data.name_pending.id, data.name_pending.value).then(_ => dispatch(resetUserDescriptionsQuery({id: data.name_pending.id})))}>
                                        Zaakceptuj
                                    </Button>

                                    <Button basic color='red' onClick={() => pendingChangesApi.denyChange(data.name_pending.id, data.name).then(_ => dispatch(resetUserDescriptionsQuery({id: data.name_pending.id})))}>
                                        Odrzuć
                                    </Button>

                                </Card.Content>
                            ): ''}

                        </Card>
                    ): ''}                    
                </Segment>
            </div>
            <div style={{width: '50%'}}>
                <Divider horizontal>
                    Status
                </Divider>
                <Segment>
                    <Table collapsing style={{border: 'none'}}>
                        <Table.Body>
                            <Table.Row>
                                <Table.HeaderCell>Typ konta</Table.HeaderCell>
                                <Table.Cell>
                                    <PremiumTypeView account_type={data.premium_type} payment_method={data.premium_payment} date_start={data.premium_date_start} date_end={data.premium_date_end} had_premium={data.had_premium} has_free_premium={data.has_free_premium}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Status konta</Table.HeaderCell>
                                <Table.Cell>
                                    <span style={data.getStatus().style}>{data.getStatus().name}</span>

                                </Table.Cell>
                            </Table.Row>
                            {data.ban_type ? (
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                    <Table.Cell>
                                        Ostatni Ban {data.ban_type} nadany dnia {parseDate(data.ban_start)}
                                    </Table.Cell>
                                </Table.Row>
                            ) : ''}
                        </Table.Body>
                    </Table>
                    <UserStatus user={data}/>

                    <FuturePremiumsView user={data} />
                </Segment>
            </div>
        </div>

        <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: '50%'}}>
                <Divider horizontal>
                    Powiązani użytkownicy
                </Divider>
                {/*<Button onClick={() => userApi.edit(id, {}, 'reset_cookies')}
                    content="Odłącz użytkownika" secondary size={"mini"}/>*/}
                          
                { data.connected_users?.length > 0 ? (
                    <Button 
                        onClick={() => openConfirmModal("Czy na pewno rozbić grupe?",
                                 () => userApi.edit(id, {}, 'break_group'))}
                        content="Rozbij grupe" secondary size={"mini"} />
                ) : ('')}
                <Segment>
                    { data.connected_users?.map(user=>
                        <div style={{marginLeft: '10px'}}>
                            <UserName user={user} premium_icon={false} hot_icon={false} gender={true} banned={true} deleted={true} messages/>
                        </div>) ?? "Brak"}
                </Segment>
            </div>
            <div style={{width: '50%'}}>
                <Divider horizontal>
                    Dane użytkownika
                </Divider>
                <Segment>
                    <Table collapsing style={{border: 'none'}}>
                        <Table.Body>
                            <Table.Row>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.Cell>
                                    <EdiText
                                        showButtonsOnHover
                                        type='text'
                                        value={data.email}
                                        validation={ async value => {
                                            const response = await userApi.edit(id, {email: value});
                                            console.log({response})
                                            return response?.status === 200;
                                        }}
                                        onSave={console.log}
                                    />
                                    <Button onClick={() => appRouter.goToSendMailPage({email: data.email, login: data.login, user_id: data.id}, false)} content={'Wyślij wiadomość'} secondary size={"mini"}/>


                                    <Subtitle>
                                        { data.emailConfirmation == 1 ? <span style={{color: 'green'}}>Potwierdzony NEW</span> : ''}
                                        { data.emailConfirmation == 2 ? <span style={{color: 'green'}}>Potwierdzony OLD</span> : ''}
                                        { data.emailConfirmation == -1 ? <span>Niepotwierdzony NEW</span> : ''}
                                        { data.emailConfirmation == -2 ? <span>Niepotwierdzony OLD</span> : ''}
                                    </Subtitle>
                                    { data.emailConfirmation < 0 ? <Link to={{pathname : reverse(ROUTE.VERIFICATION_MAIL), search:'?login_exact='+data.login}}>Wyślij weryfikacje e-mail</Link> : ''}
                                    
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Numer tel</Table.HeaderCell>
                                <Table.Cell>
                                    <EdiText
                                        showButtonsOnHover
                                        type='text'
                                        value={data.phone}
                                        validation={ async value => {
                                            const response = await userApi.edit(id, {phone: value});
                                            console.log({response})
                                            return response?.status === 200;
                                        }}
                                        onSave={console.log}
                                    />
                                    {' '}
                                    {data.phone_pending && data.phone_pending.length > 0 ? (
                                        <Popup wide
                                            trigger={
                                                <Button>Zmiana numeru w trakcie</Button>
                                            }
                                            content={
                                                data.phone_pending.map(phone_pending => (
                                                    <div>
                                                        <div>
                                                            {phone_pending.created_at}
                                                            <EdiText
                                                                showButtonsOnHover
                                                                type='text'
                                                                value={phone_pending.value}
                                                                validation={async value => {
                                                                    await pendingChangeApi.denyChange(phone_pending.id)
                                                                    const response = await userApi.edit(id, { phone: value });
                                                                    console.log({ response })
                                                                    return response?.status === 200;
                                                                }}
                                                                onSave={console.log}
                                                            />
                                                        </div>
                                                        <div>
                                                            Kod: {phone_pending.confirmation_value}
                                                        </div>
                                                        <Button.Group>
                                                            <Button icon onClick={() => pendingChangeApi.acceptChange(phone_pending.id)}>
                                                                <Icon name={'checkmark'} color={'green'} />
                                                            </Button>
                                                            <Button icon onClick={() => pendingChangeApi.denyChange(phone_pending.id)}>
                                                                <Icon name={'close'} color={'red'} />
                                                            </Button>
                                                        </Button.Group>
                                                        <hr/>
                                                    </div>
                                                ))
                                            }
                                            on={'focus'}
                                        />

                                    ) : ''}
                                    {data.previous_phones && data.previous_phones.length > 0 ? (
                                        <Popup wide
                                        trigger={
                                            <Button>Wszystkie numery</Button>
                                        }
                                        content={
                                            data.previous_phones.map(phone_pending => (
                                                <div>
                                                    <div>
                                                        {phone_pending.created_at}
                                                    </div>
                                                    <div>
                                                        {phone_pending.value}
                                                    </div>
                                                    <hr/>
                                                </div>
                                            ))
                                        }
                                        on={'focus'}
                                    />
                                    ) : ''}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Data rej</Table.HeaderCell>
                                <Table.Cell>{parseDate(data.created_at)}</Table.Cell>
                            </Table.Row>
                            { data.deleted_at ?                                 
                                <Table.Row>
                                    <Table.HeaderCell>Data del</Table.HeaderCell>
                                    <Table.Cell>
                                        {parseDate(data.deleted_at)}
                                        <br/>
                                        {data.delete_reason}
                                    </Table.Cell>
                                </Table.Row>
                                : ''    
                            }
                            <Table.Row>
                                <Table.HeaderCell>Last login</Table.HeaderCell>
                                <Table.Cell>{data.last_online && parseDate(data.last_online)}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Rej IP</Table.HeaderCell>
                                <Table.Cell>
                                    <span onClick={() => usersRouter.goToUsersPage(false, {ip: data.registration_ip})} style={{cursor: 'pointer', textDecoration: 'underline'}}>
                                    {data.registration_ip}
                                    </span>{' '}
                                    port: {data.registration_port} {data.registration_host_name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Last IP</Table.HeaderCell>
                                <Table.Cell>
                                    <span onClick={() => usersRouter.goToUsersPage(false, {ip: data.last_ip})} style={{cursor: 'pointer', textDecoration: 'underline'}}>
                                        {data.last_ip}
                                    </span>{' '}
                                    port: {data.last_port} {data.last_host_name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Last UA</Table.HeaderCell>
                                <Table.Cell>({data.last_user_window_size}) {data.last_user_agent}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>Ostatni email wymaganej zmiany hasła</Table.HeaderCell>
                                <Table.Cell>{data.required_password_change_mail}</Table.Cell>
                            </Table.Row> 
                            <Table.Row>
                                <Table.HeaderCell>Ostatnia zmiana hasła</Table.HeaderCell>
                                <Table.Cell>{data.password_change_date}</Table.Cell>
                            </Table.Row>                         
                        </Table.Body>
                    </Table>
                </Segment>
            </div>
        </div>

        <Divider horizontal>Zdjęcia Publiczne</Divider>
        <UsersPhotos user_id={id}/>

        <Divider horizontal>Zdjęcia Prywatne</Divider>
        <UsersPhotos privatePhotos user_id={id}/>

        <Header as="h3" dividing>
            Informacje Dodatkowe
        </Header>

        <UserInfoNetworkTable data={data}/>

    </PageContainer>
};


const ActionButton = ({content, icon, onClick=undefined, color=undefined}) => <Button icon={icon} circular size={'medium'} content={content} onClick={onClick} color={color}/>

export const PopupAction = ({content, children, icon=undefined, registerChild, position='top right', color=undefined, ...props}) => {
    const [popupMenuOpen, setPopupMenuOpen] = React.useState(false);
    const [registeredChildren, setRegisteredChildren] = React.useState({});


    const registerChild_ = (content, state) => {
        console.log('register', content, state);
        setRegisteredChildren(p => ({...p, [content]: state}))
    };

    const handleClose = () => {
        if(Object.values(registeredChildren).filter(x => x).length === 0)
            setPopupMenuOpen(false)
    }

    useEffect(() => {
        registerChild && registerChild(content, popupMenuOpen);
        return () => registerChild && registerChild(content, false);
    }, [popupMenuOpen]);

    const mapChildren = (child) => {
        return React.createElement(child.type, {...child.props, registerChild: registerChild_, position})
    }

    const transformedChildren = React.Children.map(children, (child) => {
        if(child.props.children){
            const children_ = mapChildren(child.props.children);
            return React.createElement(child.type, {...child.props, children_})
        }
        return mapChildren(child)
    })
    return (
        <Popup trigger={
            <ActionButton icon={icon} content={content} color={color} onClick={() => setPopupMenuOpen(p => !p)}/>
        }
               on='focus'
               onOpen={() => setPopupMenuOpen(true)}
               onClose={handleClose}
               open={popupMenuOpen}
               position={position}               
               flowing
               {...props}
        >
            {transformedChildren}
        </Popup>
    );
}


export const ApiActionButton = ({apiHook, apiData, content, onSuccess, onError, ...other}) => <Button
    content={content}
    onClick={() => apiHook.call({data: apiData}, onSuccess, onError)}
    loading={apiHook.loading}
    color={ apiHook.successFlag && 'green'}
    disabled={apiHook.loading || apiHook.successFlag}
    {...other}
/>

export const UserActionsButtons = () => {
    const {id} = useParams();
    const {user_data: data} = useGetUser(id)
    const dispatch = useDispatch();

    const apiLogin = useApi(api_url(`admin/users/${id}?action=login`), 'get');
    const userApi = useUserApi();

    const newUserApi = useNewUserApi();


    const {user_id} = userApi;

    return (
        <span style={{position: 'fixed', right: '20px', bottom: '20px', zIndex: 10}}>

                <PopupAction content={'Inne akcje'}>
                    <Button onClick={() => {
                        apiLogin.call({}, (response) => {
                            window.open(response.link, "_blank")
                        })
                    }} content='Zaloguj się jako użytkownik'/>
                    <Link to={reverse(ROUTE.USER_EDIT, {id})}>
                        <Button id='editUserButton' content='Edytuj'/>
                    </Link>
                </PopupAction>

                <PopupAction content={'Status'}>
                    <div>
                        <Checkbox slider onClick={(e, data) => {
                            !data.disabled && newUserApi.setHotUser(id, data.checked);
                        } } floated='right' checked={data.hot_person}
                                  disabled={!data.have_profile}
                        />
                        <Image src={HotIcon} avatar />
                    </div>
                    <div>
                        <Checkbox slider onClick={(e, data) => newUserApi.edit(id, {suspect: data.checked})} floated='right' checked={data.suspect} label={'Podejrzany'}/>
                    </div>
                    <div>
                        <Checkbox slider onClick={(e, data) => newUserApi.edit(id, {approved_profile: data.checked})} floated='right' checked={data.approved_profile} label={'Zweryfikowany'}/>
                    </div>

                </PopupAction>

                <PopupAction content={'Ban'}>
                     {data.is_banned ?
                         <ApiActionButton content={'Odblokuj'} apiHook={userApi.ban} apiData={{
                             action: 'remove_ban',
                         }}/> :
                         <BanPopupAdvanced/>
                     }
                </PopupAction>

                <PopupAction content={'Premium'}>

                    <ApiActionButton content={'Premium 5 dni'} apiHook={userApi.givePremium} apiData={{
                        account_type: 'premium',
                        days: 5,
                        user_id
                    }} onSuccess={() => userApi.fetchOrders.call()}/>
                    <PremiumPopupAdvanced/>

                </PopupAction>

                <Link to={reverse(ROUTE.USER_EDIT, {id})}>
                    <ActionButton icon='edit'/>
                </Link>

            </span>
    );
}

export const PremiumPopupAdvanced = ({registerChild, ...props}) => {
    const {user_id, ...userApi} = useUserApi();
    const methods = useForm({defaultValues: {
            days: 5,
            account_type: 'premium',
        }
    });

    const values = methods.watch();

    return <PopupAction content={'Podaruj premium'} registerChild={registerChild} {...props}>
        <Form methods={methods}>
            <SForm.Input label={'Ilosc dni'} type={'number'} name={'days'}/>
            <SForm.Input label={'Typ premium'} name={'account_type'}/>
        </Form>
        <ApiActionButton content={'Akceptuj'} apiHook={userApi.givePremium} apiData={{
            account_type: values.account_type,
            days: values.days,
            user_id
        }} onSuccess={() => userApi.fetchOrders.call()}/>
    </PopupAction>;
}

export const BanPopupAdvanced = ({registerChild, ...props}) => {
    const {ban} = useUserApi();
    const methods = useForm({defaultValues: {
            days: 5,
        }
    });

    const {days} = methods.watch();

    return <div>
        <PopupAction content={'Niestandardowe'} registerChild={registerChild} {...props}>
            <Form methods={methods}>
                <SForm.Input label={'Ilosc dni'} type={'number'} name={'days'}/>
            </Form>
            <ApiActionButton content={'Akceptuj'} apiHook={ban} apiData={{
                ban_type: days*24
            }} onSuccess={() => console.log('success')}/>
        </PopupAction>
        <ApiActionButton content={'1 Dzień'} apiHook={ban} apiData={{
            ban_type: 24
        }}/>
        <ApiActionButton content={'2 Dni'} apiHook={ban} apiData={{
            ban_type: 2*24
        }}/>
        <ApiActionButton content={'3 Dni'} apiHook={ban} apiData={{
            ban_type: 3*24
        }}/>
        <ApiActionButton content={'7 Dni'} apiHook={ban} apiData={{
            ban_type: 7*24
        }}/>
    </div>;
}

export default connect(state=>({
    data: getUserStore(state).data,
    blockUser: getBlockModalOpen(state),
}))(UserInfoView);