import React from "react";
import {Form} from "semantic-ui-react";
import {useControlledComponent} from "../hooks";

export function useDebounce(value, delay = 500) {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export const handleChangeForm = (setter) => (e, {name, value}) => setter(state=>({...state, [name]: value}));

// export const UsersSearchForm = ({query, onChange}) => {
//
//     const [state, setState] = useControlledComponent(query ?? {}, onChange)
//
//     const handleChange = (e, {name, value}) => {
//         setState( p => ({...p, [name]: value}) );
//     };
//
//     return <Form autoComplete="off">
//             <Form.Input name={'login'} label={'Login'} onChange={handleChange} value={state.login}/>
//             <Form.Input name={'email'} label={'Email'} onChange={handleChange} value={state.email}/>
//             <Form.Input name={'phone'} label={'Telefon'} onChange={handleChange} value={state.phone}/>
//         </Form>
// };


export const UsersSearchForm = ({query, onChange}) => {
    const [state, setState] = useControlledComponent(query, onChange)

    const handleChange = (e, {name, value}) => {
        setState( p => ({...p, [name]: value ? value : undefined}) );
    };

    return <Form autoComplete="off">
            <Form.Input name={'login'} label={'Login'} onChange={handleChange} value={state.login ?? ""}/>
            <Form.Input name={'email'} label={'Email'} onChange={handleChange} value={state.email ?? ""}/>
            <Form.Input name={'phone'} label={'Telefon'} onChange={handleChange} value={state.phone ?? ""}/>
            <Form.Input type={'text'} name={'ip'} label={'IP'} onChange={handleChange} value={state.ip ?? ""}/>
        </Form>
};
