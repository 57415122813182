import {createAction} from "../utils";
import {handleActions} from "redux-actions";
import {getLoginLogsStore} from "../store/createRootReducer";
import {put, select, takeEvery} from "@redux-saga/core/effects";
import {fetchApi} from "./app";
import {API} from "../api-endpoints";

const FETCH_LOGIN_LOGS = 'app/login_logs/FETCH_LOGIN_LOGS';
const RESET = 'app/login_logs/RESET';
const UPDATE = 'app/login_logs/UPDATE';

export const fetchLoginLogs = () => createAction(FETCH_LOGIN_LOGS);
const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetLoginLogsQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({current_page: page});

const initialState = {
  id: undefined,
  login_logs: [],
  meta: {current_page: 1, last_page: 1},
  query: {}
};

export default handleActions({
      [RESET]: (state, action) => {
          return {...state, [action.payload.key]: action.payload.value}
      },
      [UPDATE]: (state, action) => {
          return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
      },
  },
  initialState
)

export const getLoginLogs = (state) => getLoginLogsStore(state).login_logs;
export const getLoginLogsMeta = (state) => getLoginLogsStore(state).meta;
export const getLoginLogsQuery = (state) => getLoginLogsStore(state).query;

export function* callFetchLoginLogs() {
  let {current_page: page} = yield select(getLoginLogsMeta);

  const query = yield select(getLoginLogsQuery);
  const response = yield fetchApi(API.user_login_logs(), { page, ...query }, 'get');
  yield put(reset('login_logs', response.data.data));
  yield put(resetMeta(response.data.meta));
}

export function* loginLogsSaga() {
  yield takeEvery(FETCH_LOGIN_LOGS, callFetchLoginLogs);
}