import React, {Component} from "react";
import {connect} from "react-redux";
import {Divider} from "semantic-ui-react";
import TableView from "../../components/stateless/Table";
import {fetchActivities} from "../../modules/activities";
import {PageContainer} from "../../components/page/PageContainer";


const columns = [
    {displayName: 'Id', name: 'id'},
    {displayName: 'Aktywność', name: 'text'},
    {displayName: 'Administrator', name: 'user'},
    {displayName: 'Data', name: 'date'},
];

class Activities extends Component {
    constructor(props) {
        super(props);
        props.dispatch(fetchActivities());
    }

    render() {
        return <PageContainer name={'Aktywność'}>
            <Divider hidden/>
            <Divider />
            <TableView columns={columns} data={this.props.activities}/>
        </PageContainer>
    }
}

const mapStateToProps = (state) => {
    return {
        activities: state.activities,
    };
};

export default connect(mapStateToProps)(Activities);