import React, {Component} from "react";
import {connect} from "react-redux";

import {AdminTableData} from "../../components/stateless/admin/AdminTableData";
import {EditAdminForm} from "../../components/forms/edit-admin-form";
import {fetchAdmin, getAdmin, isAdminEdit, resetAdmin, submitFormAdmins, toggleEditAdmin} from "../../modules/admin";
import {fetchRolesSaga} from "../../modules/roles";
import {getAdminStore} from "../../store/createRootReducer";
import {bindActionCreators} from "redux";
import { useDispatch } from "react-redux";
import { api_url } from "../../utils";
import { request } from "../../modules/app";

class Admin extends Component {

    constructor(props) {
        super(props);
        const {fetchRolesSaga, fetchAdmin, toggleEditAdmin, resetAdmin} = props;
        fetchRolesSaga();
        if(this.isNewAdmin()){
            resetAdmin({});
            toggleEditAdmin(true);
        }
        else{
            toggleEditAdmin(false);
            fetchAdmin(this.getId());
        }
    }

    isNewAdmin(){
        return !(this.getId() > 0);
    }

    getId() {
        return this.props.match.params.id;
    }

    render() {
        const {edit, adminData} = this.props;
        const {toggleEditAdmin, submitFormAdmins} = this.props;
        // const dispatch = useDispatch();

        const onRemoveAdminClick = () => {
            console.log(adminData);
            // request('delete', api_url(`admin/admins/${adminData.id}`), {}).then(res => {
            //   dispatch(resetAdmin())
            // })
          }

        return !edit ?
                    <AdminTableData adminData={adminData} onClickEdit={toggleEditAdmin} onRemoveClick={onRemoveAdminClick}/> :
                    <EditAdminForm onSubmit={(...all) => submitFormAdmins(all[2].form, all[2].values)} isNew={this.isNewAdmin()}/>
    }
}

const mapStateToProps = (state) => {
    return {
        adminData: getAdmin(getAdminStore(state)),
        edit: isAdminEdit(getAdminStore(state)),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAdmin,
        fetchRolesSaga,
        toggleEditAdmin,
        submitFormAdmins,
        resetAdmin,
    },dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);