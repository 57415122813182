import React from 'react';
import {useForm} from "react-hook-form";
import {Button, Divider, Form, FormInput, Icon} from "semantic-ui-react";
import {RHFInput} from 'react-hook-form-input';
import {api_url} from "../../utils";
import _ from 'lodash';
import {useTranslation} from "react-i18next";
import {request} from "../../modules/app";

const useBackendAppSettings = () => {
    const [state, setState] = React.useState({
        loading: false,
        response: undefined,
        success: undefined,
    });

    const fetch = () => {
        setState({loading: true});
        request('get', api_url('admin/settings'), {}).then(res => {
            setState(s=>({
                ...s,
                loading: false,
                response: res.data,
            }))
        }).catch(e => {
            setState({
                loading: false,
                response: e.data,
            })
        });
    }

    const save = (data) => {
        setState({loading: true});
        request('post', api_url('admin/settings'), data).then(res => {
            setState({loading: false, response: res.data, success: true})
        });
        fetch();
    }

    return {
        loading: state.loading,
        response: state.response,
        success: state.success,
        fetch,
        save
    }
}

export const AppSettingsForm = () => {

    const {response, success, fetch, save} = useBackendAppSettings();
    const {t} = useTranslation('app_settings_form')

    React.useEffect( fetch, []);

    const { register, handleSubmit, setValue } = useForm();
    const onSubmit = data => {
        save(data);
    };

    const build_form = (data_, path='') => {
        return data_ && <div>
            <Divider horizontal>{path && t([path, 'name'].join('.'))}</Divider>
            {Object.keys(data_).map(key => {
                if (_.isObject(data_[key])) {
                    return <>
                        {build_form(data_[key], [path, key].filter(x=>x).join('.'))}
                    </>
                } else {
                    return <RHFInput
                        as={<FormInput label={t([path,key].join('.'))} type={'number'} min={0} max={10000}/>}
                        name={path + '.' + key}
                        register={register}
                        setValue={setValue}
                        defaultValue={data_[key]}
                    />
                }
            })}
        </div>
    }

    return <Form onSubmit={handleSubmit(onSubmit)}>
        {success === true && <>
            <Icon name={'checkmark'}/> Zapisano
        </>}
        {build_form(response)}

        <Button type="submit" content={'Zapisz'}/>
    </Form>
}

export default AppSettingsForm;