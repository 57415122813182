import React from 'react';
import {PageContainer} from "../../components/page/PageContainer";
import {Button, Header, Input, Message} from "semantic-ui-react";
import {useModal} from "../../components/hooks";
import {useApiSendVerificationMails, useMailReceiversCount} from "../../Api";
import UserFilterForm from '../../components/forms/UserFilterFormOnContext'
import {UserFilter} from "./components/UsersFilter";
import {parse} from 'query-string'
import {useLocation} from "react-router";

export const VerificationMail = () => {
    const [filter, setFilter] = React.useState({});
    const {modal, setContentAndOpenModal, openConfirmModal} = useModal()

    const receiversCount = useMailReceiversCount();
    const sendVerificationMails = useApiSendVerificationMails();

    const location = useLocation();

    const {login_exact} = parse(location.search)

    var currentFilters = null;

    const getFilter = () => {
        filter.verified = 0;
        if(login_exact)
            filter.login_exact = login_exact

        return filter
    }

    React.useEffect( () => {
        onChangeFilter({login_exact})
    }, [login_exact])

    React.useEffect( () => {
        receiversCount.call({filter: getFilter()});
    }, []);

    const send_mails = () => {
        sendVerificationMails.call({filter}, () => {
            setContentAndOpenModal(<Header icon='checkmark' content='E-Mail został wysłany' />);
        }, () => {
            setContentAndOpenModal(<Header icon='exclamation' content='Wystąpił błąd' />);
        });
    }

    const onChangeFilter = (filter) => {
        setFilter(filter);
    };

    const onApplyFilters = () => {
        receiversCount.call({filter});
        console.log(receiversCount);
    }

    return <UserFilterForm.Provider onChange={onChangeFilter} filter={filter}>
        <PageContainer name={'Verifikacja'}>
            {modal}

            <div style={{display: 'flex'}}>
                <div style={{width: '60%'}}>
                    <div style={{display: 'grid', height: 'fit-content', }}>

                        <UserFilter/>

                        <Message size='mini'>
                            { !receiversCount.loading ?
                                <Message.Header style={{textAlign: 'center'}}>Wiadomość zostanie wysłana do {receiversCount.response.valid?.count || 0} {receiversCount.response.valid?.count === 1 ? 'użytkownika' : 'użytkowników'}</Message.Header>
                                :
                                <Message.Header style={{textAlign: 'center'}}>Wiadomość zostanie wysłana do: Proszę czekać....</Message.Header>
                            }
                        </Message>

                        <Button content={'Sprawdź filtry'} onClick={() => onApplyFilters()}/>
                        <Button content={'Wyślij weryfikacje email'} onClick={() => openConfirmModal("Czy zatwierdzasz?", send_mails)} color={'green'}/>
                    </div>
                    { !receiversCount.loading ?
                        <div>Użytkownicy: {receiversCount.response.valid?.logins != null ? receiversCount.response.valid?.logins.join(', ') : ''}</div>
                        :
                        ''
                    }
                </div>

            </div>

        </PageContainer>
    </UserFilterForm.Provider>
}