import React from "react";
import {SelectField} from 'react-semantic-redux-form';
import {Container, Divider, Form, Message} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {useDispatch} from "react-redux";
import {banUser} from "../../modules/user";

const ban_types = [
    {
        key: 1,
        value: 7 * 24,
        text: '7 dni',
    },
    {
        key: 2,
        value: 24,
        text: '24 godziny',
    }
];

const UserBlockFormView = (props) => {
    const {submitting, submitErrors,submitFailed, handleSubmit, form, options} = props;
    const dispatch = useDispatch();

    return <Container>
        <Divider hidden/>
        <Form autoComplete="off" error={submitErrors} loading={submitting} onSubmit={handleSubmit(values=>dispatch(banUser(form, values)))}>
            {submitFailed &&
            <Message
                error
                content={'Wystąpił błąd'}/>
            }
            <Form.Group>
                <Field name={'ban_type'}  component={SelectField} options={ban_types} label={'Typ'}/>
            </Form.Group>
            <Form.Button type='submit' id='submitFormButton' content='Zablokuj'/>
        </Form>
    </Container>
};

export default reduxForm({
    form: 'user-block-form',
    enableReinitialize: true,
})(UserBlockFormView);