import React, {useContext, useMemo, useState} from "react";
import {
    Button,
    Card,
    Checkbox,
    Confirm,
    Container,
    Divider,
    Header,
    Icon,
    Popup,
    Radio,
    Segment
} from "semantic-ui-react";
import {Pagination} from "../../components/tables/pagination";
import {Filter} from "../../components/tables/filter";
import Moment from "moment";
import {api_url} from "../../utils";
import {request} from "../../modules/app";
import {DeletedPhoto, Photo} from "../users/components/user-page/photos-section";
import {useGetUser, useNewUserApi} from "../../api/users";
import {useCustomPaginatedQuery} from "../../api/core";
import {photosRequest} from "../../api/requests";
import {UserName} from "../../components/User/UserName";
import {PhotosApiProvider, usePhotoApi, usePhotosApi} from "../../api/photos";
import CropPhotoModalProvider, {CropPhotoModalContext} from "../../components/modals/CropPhotoModal";
import {SelectedEntitiesContext, useSelectedEntities} from "../../hooks";
import {RotatePhotoPopup} from "../../components/photo/actions";
import {useConfirm} from "../../components/photo/original-image-crop";

async function searchByImage(image, onLoadingStateChange) {
    onLoadingStateChange('start');
    request('get', api_url('admin/photos/'+image.id+'?action=search_by_image'), []).then(res => {
        const win = window.open(res.data.link, '_blank');
        // win.focus();
        onLoadingStateChange('end');
    })
}

export function Gbutton({image, ...props}){

    const [mode, setMode] = useState(undefined);

    function handleOnLoadingStateChange(mode) {
        setMode(mode);
    }

    const loading = !(mode === undefined || mode === 'end');

    return <Button {...props} icon secondary onClick={() => searchByImage(image, handleOnLoadingStateChange)} disabled={loading} loading={loading}>
        <Icon name='google' size='small'/>
    </Button>
}

const PhotoView = ({data}) => {
    const {isSelected, handleClick, selected, setSelected} = useContext(SelectedEntitiesContext);
    const {user_data} = useGetUser(`${data.user_id}`, {withPhotos: false, withThreads: false}, {staleTime: 60*1000});
    const userApi = useNewUserApi(data.user_id);

    const photoCropModalContext = useContext(CropPhotoModalContext);

    const isActualPhotoSelected = useMemo( () => isSelected(data.id), [selected])

    const photosApi = usePhotosApi(data.id);
    const {methods, ...deletePhotoConfirmation} = useConfirm(() => photosApi.delete());
    return (
        <Card color={data.status === 1 ? 'blue' : 'red'} style={isActualPhotoSelected ? {background: '#c0c1c2'} : {}}>
            <Confirm {...methods} content={"Czy na pewno usunąć?"}/>
            <Button fluid content={isActualPhotoSelected ? "Odznacz" : "Zaznacz"} active={isActualPhotoSelected}
                    onClick={() => handleClick(data.id)}/>
            <Segment basic textAlign={"center"}>
                <Photo photo_id={data.id} fluid size={undefined} fetchSize={'original'} cropped={0}/>
            </Segment>
            <Card.Content>
                <Card.Header>
                    <div style={{display: 'inline-flex'}}>
                        <UserName user={user_data} hot_icon={false} banned/>
                    </div>
                    <Gbutton image={data} floated={'right'}/>

                </Card.Header>

                <Card.Meta style={{margin: '10px 0'}}>
                    <Popup
                        content={"Nie można ustawić jako gorący użytkownik, ponieważ użytkownik nie ma zdjęcia profilowego"}
                        disabled={user_data.can_set_as_hot_person != null} trigger={
                        <span style={{filter: `saturate(${user_data.hot_person ? 1 : 0})`}}>
                                    <Button size={'tiny'} content={"HOT"}
                                            onClick={() => userApi.setHotUser(user_data.id, !user_data.hot_person)}
                                            color={user_data.hot_person ? "pink" : undefined}
                                            disabled={!user_data.can_set_as_hot_person}/>
                                    </span>
                    }
                    />
                    <RotatePhotoPopup photo_id={data.id}/>

                    <Button icon content={"Przytnij"} size={'tiny'} onClick={() => photoCropModalContext.setPhoto(data)}>
                        <Icon name={'cut'}/>
                    </Button>
                    {!data.private && !(data.main_photo || (!data.main_photo && data.choose_as_profile_photo)) ?
                        <Button content="Główne" size={'tiny'}  onClick={() => photosApi.setAsProfilePhoto()} />
                        : ''
                    }
                    <Button icon size={'tiny'} onClick={deletePhotoConfirmation.showConfirmationModal}>
                        <Icon name={'trash'}/>
                    </Button>
                </Card.Meta>

                <Card.Meta style={{margin: '10px 0'}}>
                    <PhotosApiProvider id={data.id} render={(api) => (
                        <Checkbox label={'Zatwierdzone'} onChange={() => data.status ? api.reject() : api.approve()}
                                  disabled={api.isLoading} checked={data.status ? true : false}/>
                    )}/>
                </Card.Meta>

                <Card.Description>
                    <div>
                        <span style={!data.cropped ? {color: 'red'} : {}}>
                            {data.cropped ? "Skadrowane" : "Nie skadrowane"}
                        </span>
                        {data.main_photo ? <span style={{color: 'blue'}}>, zdjęcie główne</span> : ''}
                        {!data.main_photo && data.choose_as_profile_photo ? <span style={{color: 'violet'}}>, oczekujące główne</span> : ''}
                        {data.confirmed ? <Icon name="check circle" color="green" style={{marginLeft: '8px'}} /> : ''}
                    </div>
                    <div>
                        <PhotosApiProvider id={data.id} render={(api) => (
                            <>
                                <span onClick={() => { if (!api.isLoading) api.setPublic() }}
                                    style={{ marginRight: '5px', fontWeight: !data.private ? 'bold' : 'normal' }}>
                                    Publiczne
                                </span>
                                <Radio slider checked={data.private}
                                    onChange={(event, result) => result.checked ? api.setPrivate() : api.setPublic()}
                                    disabled={api.isLoading} />

                                <span onClick={() => { if (!api.isLoading) api.setPrivate() }}
                                    style={{ marginLeft: '5px', fontWeight: data.private ? 'bold' : 'normal' }}>
                                    Prywatne
                                </span>
                            </>
                        )} />
                    </div>
                </Card.Description>

                <Card.Meta>
                    {Moment(data.created_at).format('Y-MM-DD HH:mm')}
                </Card.Meta>
                <Card.Description>
                    {data.description}
                </Card.Description>
            </Card.Content>
        </Card>
    );
};


const DeletedPhotoView = ({data}) => {
    const {isSelected, handleClick, selected, setSelected} = useContext(SelectedEntitiesContext);
    const {user_data} = useGetUser(`${data.user_id}`, {withPhotos: false, withThreads: false}, {staleTime: 60*1000});
    // const userApi = useNewUserApi(data.user_id);

    // const photoCropModalContext = useContext(CropPhotoModalContext);

    const isActualPhotoSelected = useMemo( () => isSelected(data.id), [selected])

    const photosApi = usePhotosApi(data.id);
    const {methods, ...deletePhotoConfirmation} = useConfirm(() => photosApi.delete());
    return (
        <Card color={data.status === 1 ? 'blue' : 'red'} style={isActualPhotoSelected ? {background: '#c0c1c2'} : {}}>
            {/* <Confirm {...methods} content={"Czy na pewno usunąć?"}/> */}
            <Button fluid content={isActualPhotoSelected ? "Odznacz" : "Zaznacz"} active={isActualPhotoSelected}
                    onClick={() => handleClick(data.id)}/>
            <Segment basic textAlign={"center"}>
                <DeletedPhoto photo_id={data.id} fluid size={undefined} fetchSize={'original'} cropped={0}/>
            </Segment>
            <Card.Content>
                <Card.Header>
                    <div style={{display: 'inline-flex'}}>
                        <UserName user={user_data} hot_icon={false} banned/>
                    </div>
                    {/* <Gbutton image={data} floated={'right'}/> */}

                </Card.Header>

                {/* <Card.Meta style={{margin: '10px 0'}}> */}
                    {/* <Popup
                        content={"Nie można ustawić jako gorący użytkownik, ponieważ użytkownik nie ma zdjęcia profilowego"}
                        disabled={user_data.can_set_as_hot_person != null} trigger={
                        <span style={{filter: `saturate(${user_data.hot_person ? 1 : 0})`}}>
                                    <Button size={'tiny'} content={"HOT"}
                                            onClick={() => userApi.setHotUser(user_data.id, !user_data.hot_person)}
                                            color={user_data.hot_person ? "pink" : undefined}
                                            disabled={!user_data.can_set_as_hot_person}/>
                                    </span>
                    }
                    /> */}
                    {/* <RotatePhotoPopup photo_id={data.id}/>

                    <Button icon content={"Przytnij"} size={'tiny'} onClick={() => photoCropModalContext.setPhoto(data)}>
                        <Icon name={'cut'}/>
                    </Button>
                    {!data.private && !(data.main_photo || (!data.main_photo && data.choose_as_profile_photo)) ?
                        <Button content="Główne" size={'tiny'}  onClick={() => photosApi.setAsProfilePhoto()} />
                        : ''
                    }
                    <Button icon size={'tiny'} onClick={deletePhotoConfirmation.showConfirmationModal}>
                        <Icon name={'trash'}/>
                    </Button> */}
                {/* </Card.Meta> */}

                {/* <Card.Meta style={{margin: '10px 0'}}>
                    <PhotosApiProvider id={data.id} render={(api) => (
                        <Checkbox label={'Zatwierdzone'} onChange={() => data.status ? api.reject() : api.approve()}
                                  disabled={api.isLoading} checked={data.status ? true : false}/>
                    )}/>
                </Card.Meta> */}

                {/* <Card.Description> */}
                    {/* <div>
                        <span style={!data.cropped ? {color: 'red'} : {}}>
                            {data.cropped ? "Skadrowane" : "Nie skadrowane"}
                        </span>
                        {data.main_photo ? <span style={{color: 'blue'}}>, zdjęcie główne</span> : ''}
                        {!data.main_photo && data.choose_as_profile_photo ? <span style={{color: 'violet'}}>, oczekujące główne</span> : ''}
                        {data.confirmed ? <Icon name="check circle" color="green" style={{marginLeft: '8px'}} /> : ''}
                    </div> */}
                    {/* <div>
                        <PhotosApiProvider id={data.id} render={(api) => (
                            <>
                                <span onClick={() => { if (!api.isLoading) api.setPublic() }}
                                    style={{ marginRight: '5px', fontWeight: !data.private ? 'bold' : 'normal' }}>
                                    Publiczne
                                </span>
                                <Radio slider checked={data.private}
                                    onChange={(event, result) => result.checked ? api.setPrivate() : api.setPublic()}
                                    disabled={api.isLoading} />

                                <span onClick={() => { if (!api.isLoading) api.setPrivate() }}
                                    style={{ marginLeft: '5px', fontWeight: data.private ? 'bold' : 'normal' }}>
                                    Prywatne
                                </span>
                            </>
                        )} />
                    </div> */}
                {/* </Card.Description> */}

                <Card.Meta>
                    Dodane: {Moment(data.created_at).format('Y-MM-DD HH:mm')}
                </Card.Meta>
                <Card.Meta>
                    USUNIĘTE: {Moment(data.deleted_at).format('Y-MM-DD HH:mm')}
                </Card.Meta>
                {/* <Card.Description>
                    {data.description}
                </Card.Description> */}
            </Card.Content>
        </Card>
    );
};

export const PhotosList = ({photos}) => (
    <Card.Group>
        {photos.map(column => (
            column.deleted_at ?
                <DeletedPhotoView data={column}/>
                :
                <PhotoView data={column}/>
        ))}
    </Card.Group>
);

const filters = [
    ['Wszystkie', {}],
    ['Zatwierdzone', {status: 1}],
    ['Do zatwierdzenia', {status: 0}],
    ['Do przycięcia', {crop_request: 1}],
    ['Publiczne', {private: 0}],
    ['Publiczne do zatw.', {private: 0, status: 0}],
    ['Prywatne', {private: 1}],
    ['Prywatne do zatw.', {private: 1, status: 0}],
    ['Aktywnych użytkowników', {active_users: 1}],
    ['Użytkownicy premium', {premium_users: 1}],
    ['Dodatkowe', {additional: 1, private: 0}],
    ['Dodatkowe do zatw.', {additional: 1, private: 0, status: 0}],
    ['HOT [f]', {hot_person: 1, gender: 'f', position: 1}],
    ['HOT [m]', {hot_person: 1, gender: 'm', position: 1}]
];

export const PhotosView = ({photos, onChangeFilter, actual_filter, pagination, onChangePage, filters, actions}) => {
    const selectedEntities = useSelectedEntities();
    const api = usePhotoApi();

    const handleFilterChange = filter => {
        selectedEntities.setSelected([])
        onChangeFilter(filter)
    }

    const handlePageChange = page => {
        selectedEntities.setSelected([])
        onChangePage(page)
    }

    return (

        <div>
            <Button.Group>
                <Popup on={"click"} disabled={!selectedEntities.selected.length}
                       trigger={<Button content={`Zaznaczone ${selectedEntities.selected.length}`}/>}>
                    <Button.Group>
                        {/*<Button disabled={api.isLoading} loading={api.isLoading} content={"Usuń"} onClick={ () => selectedEntities.selected.forEach(id => {*/}
                        {/*    api.delete(id)*/}
                        {/*})}/>*/}
                        <Button disabled={api.isLoading} loading={api.isLoading} content={"Odrzuć"}
                                onClick={() => selectedEntities.selected.forEach(id => {
                                    api.reject(id)
                                })}/>
                        <Button disabled={api.isLoading} loading={api.isLoading} content={"Zatwierdź"}
                                onClick={() => selectedEntities.selected.forEach(id => {
                                    api.approve(id)
                                })}/>
                    </Button.Group>
                </Popup>
            </Button.Group>
            <Button.Group>
                <Button content={"Zaznacz wszystkie"}
                        onClick={() => selectedEntities.setSelected(photos.map(p => p.id))}
                        active={selectedEntities.selected.length === photos.length}/>
                <Button content={"Odznacz wszystkie"} onClick={() => selectedEntities.setSelected([])}
                        active={selectedEntities.selected.length === 0}/>
            </Button.Group>
            {actions && React.createElement(actions.type, actions.props)}
            <Divider/>
            <SelectedEntitiesContext.Provider value={selectedEntities}>
                <CropPhotoModalProvider>
                    {filters &&
                    <Filter onChange={handleFilterChange} actual_filter={actual_filter} filters={filters}/>
                    }
                    <Divider/>
                    {pagination.total ? <div>Razem: {pagination.total}</div> : ''}
                    <Divider hidden/>
                    <PhotosList photos={photos}/>
                    <Pagination pagination={pagination} onChange={handlePageChange}/>
                </CropPhotoModalProvider>
            </SelectedEntitiesContext.Provider>
        </div>
    );
};

export const Photos = () => {
    const {data: photos, setFilters, setPage, pagination, filters: actualFilter} = useCustomPaginatedQuery('photos', photosRequest);

    const resetFilterAndFetch = (filter) => {
        setPage(1)
        setFilters(filter)
    };

    const changePageAndFetch = (page) => {
        setPage(page)
    };

    return <Container style={{width: '95%'}}>
        <Divider hidden/>
        <Header as='h2'>
            Zdjęcia
        </Header>

        <PhotosView
            photos={photos}
            onChangeFilter={resetFilterAndFetch}
            actual_filter={actualFilter}
            filters={filters}
            pagination={pagination}
            onChangePage={changePageAndFetch}
        />
    </Container>
};

export default Photos;