import React from 'react';
import {Button, Container, Tab} from "semantic-ui-react";
import {useHistory, useParams} from "react-router";
import {Gbutton} from "./photos";
import {OriginalImageCrop} from "../../components/photo/original-image-crop";
import {PageContainer} from "../../components/page/PageContainer";
import {useFetchPhoto, usePhotosApi} from "../../api/photos";
import {EditPhoto as EditPhotoForm} from '../../components/forms/edit-photo-form'


export function EditPhotoContent({photo, onSubmit}) {
    const photosApi = usePhotosApi(photo.id);

    const handleSubmit = (data) => {
        photosApi.edit(data).then(() => {
            onSubmit && onSubmit();
        });
    };

    const panes = [
        {
            menuItem: 'Edycja zdjęcia',
            render: () => <Tab.Pane attached={false}>
                <Gbutton image={photo}/>
                {!photo.private && !(photo.main_photo || (!photo.main_photo && photo.choose_as_profile_photo)) ?
                    <Button content="Ustaw jako profilowe" onClick={() => photosApi.setAsProfilePhoto()} />
                    : ''
                }
                {photo.main_photo ? <span style={{color: 'blue'}}>Zdjęcie główne</span> : ''}
                {!photo.main_photo && photo.choose_as_profile_photo ? <span style={{color: 'violet'}}>Oczekujące główne</span> : ''}
                <EditPhotoForm onSubmit={ handleSubmit } photo={photo} config={{hideStatusField: false, hidePrivateField: false}}/>
            </Tab.Pane>,
        },
        {
            menuItem: 'Przytnij oryginalne zdjęcie',
            render: () => <Tab.Pane attached={false}>
                <OriginalImageCrop photo={photo}/>
            </Tab.Pane>,
        },
    ]

    return <Tab menu={{secondary: true, pointing: true}} panes={panes}/>;
}

export const EditPhoto = () => {
    const {id} = useParams();
    const {photo} = useFetchPhoto(id);
    const history = useHistory();

    return <PageContainer name={`Edycja zdjęcia`}>
        {photo.id ? (
            <Container>
                <EditPhotoContent photo={photo} onSubmit={() => history.goBack()}/>
            </Container>
        ) : (
            <span>Brak</span>
        )}
    </PageContainer>
};

export default EditPhoto;
