import React from "react";
import {Button} from "semantic-ui-react";

const Pages = ( {numbers, active_page, onClick} ) => (
    <Button.Group floated='left'>
        {numbers.map(function (number) {
            return <Button onClick={onClick} active={ number === active_page } key={number} id={number}>{number}</Button>
        })}
    </Button.Group>
);

export default Pages