import {Table} from "semantic-ui-react";
import React from "react";

export const gender = {
    m: 'Mężczyzna',
    f: 'Kobieta'
};

export const MyTable = ({data}) => (
    <Table celled striped>
        <Table.Body>
            {data.map(datum => (
                <Table.Row>
                    <Table.Cell>{datum[0]}</Table.Cell>
                    <Table.Cell>{datum[1] || "Brak danych" }</Table.Cell>
                </Table.Row>
            ))}
        </Table.Body>
    </Table>
);

export const UserInfoBasicTable = ({data}) => (
    <MyTable data={[
        ['Imię', data.name],
        ['Nazwa Użytkownika', data.login],
        ['Email', data.email],
        ['Data Urodzin', data.birth_date],
        ['Telefon', data.phone],
        ['Nowy telefon', data.last_phone_change && data.last_phone_change.phone],
        ['Wysłany kod weryfikacyjny', data.last_phone_change && data.last_phone_change.verification_code],
        ['Płeć', gender[data.gender]],
    ]}/>
);