import React from "react";
import {Button, Container, Divider, Form, Header, Message} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {InputField, SelectField} from 'react-semantic-redux-form';
import {connect} from "react-redux";
import {apiSaveOrder} from "../../modules/orders";


export const PaymentFormView = props => {
    const {error, onSubmit, handleSubmit} = props;
    return <Container>
        <Divider hidden/>
        <Form autoComplete="off" error={error}>
            {error &&
            <Message
                error
                content={'Wystąpił błąd'}/>
            }

            <Header as="h3" dividing>
                Informacje
            </Header>

            <Form.Group widths="equal">

                <Field name={'account_type'} component={SelectField} label={'Typ'} options={['Premium', 'VIP'].map((o,i) => ({
                    key: i,
                    value: i+1,
                    text: o,
                }))}/>

                <Field name={'amount'} component={InputField} label={'Kwota'}/>
            </Form.Group>

            <Button type='button' id='submitFormButton' onClick={handleSubmit((values)=>onSubmit(values))}>Zapisz</Button>
        </Form>
    </Container>
};

export const EditPhotoFormComponent = reduxForm({
    form: 'paymentForm',
    enableReinitialize: true,
})(PaymentFormView);

export const OrderForm = connect(null, {onSubmit: (data) => apiSaveOrder(data)})(EditPhotoFormComponent);