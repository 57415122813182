import React from "react";
import {useParams} from "react-router";
import {useDispatch} from "react-redux";
import {submitForm} from "../../modules/app";
import {api_url} from "../../utils";
import {Button, Form} from "semantic-ui-react";
import {change, Field, reduxForm} from "redux-form";
import {InputField, SelectField} from "react-semantic-redux-form";
import {goBack} from "react-router-redux";
import {premiumTypeOptions} from "../orders/orders";
import {PageContainer} from "../../components/page/PageContainer";

export const UserOrderCreate = () => {
    return <PageContainer name={'Podaruj premium'}>
        <NewOrderFormRedux/>
    </PageContainer>;
}

export const NewOrderForm = (props) => {
    const {user_id} = useParams();
    const {form, submitFailed, submitting} = props;
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(change(form, 'account_type', 'premium'))
    });

    const onSubmitSuccess = () => {
        dispatch(goBack());
    }

    const handleSubmit = () => {
        dispatch(submitForm(form, api_url('admin/orders/'), 'post', {user_id}, onSubmitSuccess));
    };

    return <Form autoComplete="off" error={submitFailed} loading={submitting} onSubmit={handleSubmit}>
        <Field name={'account_type'} component={SelectField} options={premiumTypeOptions.filter(o=>o.value)} label={'Typ konta'}/>
        <Field name={'days'} component={InputField} type={'number'} label={'Ilość dni'}/>
        <Button type={'submit'} content={'Zapisz'}/>
    </Form>
};

export const NewOrderFormRedux = reduxForm({
    form: 'new_order_user_form',
    enableReinitialize: true,
})(NewOrderForm);