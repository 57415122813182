import {handleActions} from "redux-actions";
import {put, takeEvery} from "redux-saga/effects";
import {createAction} from "../utils";
import {getPaymentPlansStore} from "../store/createRootReducer";
import {API} from "../api-endpoints";
import {fetchApi} from "./app";
import {push} from "react-router-redux";
import {ROUTE} from "../route-names";
import {startSubmit, stopSubmit} from "redux-form";


const SAGA_API_GET = 'app/payment_plans/SAGA_API_GET';
const SAGA_API_SAVE = 'app/payment_plans/SAGA_API_SAVE';
const SAGA_API_FETCH_OPTIONS = 'app/payment_plans/SAGA_API_FETCH_OPTIONS';
const SAGA_API_DELETE = 'app/payment_plans/SAGA_API_DELETE';

const RESET_OPTIONS = 'app/payment_plans/RESET_OPTIONS';
const RESET_PAYMENT = 'app/payment_plans/RESET_PAYMENT';
const RESET_PAYMENTS = 'app/payment_plans/RESET_PAYMENTS';

export const apiSavePayment = (form_id, data) => createAction(SAGA_API_SAVE, {form_id, data});
export const apiGetPaymentPlans = (id=null) => createAction(SAGA_API_GET, {id});
export const apiFetchOptions = () => createAction(SAGA_API_FETCH_OPTIONS);
export const apiDeletePaymentPlan = (paymentPlan) => createAction(SAGA_API_DELETE, {paymentPlan});

export const resetPaymentPlans = (payments) => createAction(RESET_PAYMENTS, {payments});
export const resetPaymentPlan = (payment) => createAction(RESET_PAYMENT, {payment});
export const resetOptions = (options) => createAction(RESET_OPTIONS, {options});


const initialState = {
    payment: {},
    payments: [],
    options: {
        account_type: ['typ'],
        currency: ['typ'],
    },
};

export default handleActions({
        [RESET_PAYMENT]: (state, action) => {
            return {...state, payment: action.payload.payment}
        },
        [RESET_PAYMENTS]: (state, action) => {
            return {...state, payments: action.payload.payments}
        },
        [RESET_OPTIONS]: (state, action) => {
            return {...state, options: action.payload.options}
        },
    },
    initialState
)

export const getPaymentPlan = state => getPaymentPlansStore(state).payment;
export const getPaymentPlans = state => getPaymentPlansStore(state).payments;
export const getPaymentPlansOptions = state => getPaymentPlansStore(state).options;

function* callGetPayments(action) {
    const {id} = action.payload;
    console.log('url', API.payment_plan(id));
    const response = yield fetchApi(API.payment_plan(id), {
        per_page: 100,
    }, 'get');

    if(id){
        yield put(resetPaymentPlan(response.data.data));
        yield put(resetOptions(response.data.options));
    }
    else
        yield put(resetPaymentPlans(response.data.data));

}

function* callSavePayments(action) {
    const {form_id, data} = action.payload;

    yield put(startSubmit(form_id));

    let uri = API.payment_plan(data.id);
    try{
        if (!data.id){
            const response = yield fetchApi(uri, {
                ...data,
                amount: data.amount
            }, 'post');
            yield put(resetPaymentPlan(response.data.data));
        }
        else{
            const response = yield fetchApi(uri, {
                ...data,
                amount: data.amount
            },'patch');

            yield put(resetPaymentPlan(response.data.data));
        }
        yield put(stopSubmit(form_id));
        yield put(push(ROUTE.PAYMENT_PLANS));

    }catch (e) {
        yield put(stopSubmit(form_id, e.response.data.errors));
    }
}

function* callFetchOptions(){
    try{
        const response = yield fetchApi(API.payment_plan(), {}, 'options');
        yield put(resetOptions(response.data));
    }catch (e) {
        console.log("Pobieranie opcji nie powiodło się")
    }
}

function* callDeletePayment(action) {
    const {paymentPlan} = action.payload;
    try{
        yield fetchApi(API.payment_plan(paymentPlan), {}, 'delete');
        yield put(apiGetPaymentPlans());
    }catch (e) {
        console.log("Usunięcie planu nie powiodło się")
    }

}
export function* paymentPlansSaga() {
    yield takeEvery(SAGA_API_GET, callGetPayments);
    yield takeEvery(SAGA_API_FETCH_OPTIONS, callFetchOptions);
    yield takeEvery(SAGA_API_SAVE, callSavePayments);
    yield takeEvery(SAGA_API_DELETE, callDeletePayment);
}