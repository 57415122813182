export const ALCOHOL = [
    'Lubi',
    'Lubi tylko okazjonalnie',
    'Nie lubi',
];

export const BODY = [
    'Szczupła',
    'Normalna',
    'Puszysta',
    'Atletyczna',
];

export const CHILDREN = [
    'Ma',
    'Nie ma',
];

export const WANT_CHILDREN = [
    'Tak',
    'Nie',
    'Nie wie',
];

export const EDUCATION = [
    'Podstawowe',
    'Zawodowe',
    'Średnie',
    'Pomaturalne',
    'Wyższe niepełne',
    'Wyższe',
    'Podyplomowe',
];

export const EYE_COLOR = [
    'Piwne',
    'Niebieskie',
    'Zielone',
    'Czarne',
    'Szare',
    'Inne',
];

export const HAIR_COLOR = [
    'Jasny blond',
    'Ciemny blond',
    'Pasemka',
    'Jasne kasztanowe',
    'Ciemne kasztanowe',
    'Czarne',
    'Rude',
    'Brak włosów',
    'Inne',
];

export const JOB = [
    'Pracownik fizyczny',
    'Pracownik biurowy',
    'Student/uczeń',
    'Emeryt/rencista',
    'Kierownik/dyrektor',
    'Właściciel',
    'Nie pracuje/szukam pracy',
    'Pracownik naukowy/nauczyciel',
    'Specjalista/wolny zawód',
    'Pracownik handlu i usług',
    'Pracownik marketingu/reklamy',
    'Pracownik finansów/bankowości',
    'Inny',
];

export const LOOKING_FOR = [
    'Przyjaźni',
    'Miłości',
    'Przygody',
    'Seksu',
    'Zabawy',
    'Kobiet',
    'Mężczyzn',
    'Niczego nie szukam',
];

export const MARTIAL_STATUS = [
    'Wolny',
    'Mężatka/Żonaty',
    'Po rozwodzie',
    'W separacji',
    'Wdowiec/Wdowa',
];

export const SMOKE = [
    'Pali',
    'Nie pali',
];