import React from "react";
import {CheckboxField, InputField, SelectField} from 'react-semantic-redux-form';

import {connect} from "react-redux";
import {Container, Divider, Form, Message} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {apiSavePayment, getPaymentPlansOptions} from "../../modules/payment_plans";

const CashField = (all) => {
    const [value, set_value] = React.useState(undefined);

    const set_cash_value = (value) => {
        const parsed_value = parseFloat(value);

        if(isNaN(parsed_value)){
            set_value(0);
            return;
        }

        const digits = value.split('.')[1] ? value.split('.')[1].length : 0;

        let new_value = value;
        if(digits > 2)
            new_value = parsed_value.toFixed(2);

        set_value(new_value);
        all.input.onChange(Math.ceil(parsed_value*100))
    }

    React.useEffect( () => {
        if(isNaN(value)){
            set_value((parseInt(all.input.value)/100).toFixed(2))
        }
    }, [all.input.value, value]);

    return <InputField
            {...all}
            input={{...all.input, value: value}}
            onBlur={null}
            onChange={ (e, v)=>{
                set_cash_value(v.value);
            }}
        >
        </InputField>
}

const PaymentPlanFormView = (props) => {
    const {submitting, submitErrors,submitFailed, handleChange, handleSubmit, onSubmit, form, options, ...other} = props;
    return <Container>
        <Divider hidden/>
        <Form autoComplete="off" error={true} loading={submitting} onSubmit={handleSubmit(values=>onSubmit(form, values))}>
            {submitFailed &&
            <Message
                error
                content={'Wystąpił błąd'}/>
            }
            <Form.Group>

                <Field name={'account_type'}  component={SelectField} options={options.account_type.map((o,i) => ({
                    key: i,
                    value: o,
                    text: o,
                }))} label={'Typ'}/>
                <Field name={'amount'} component={CashField} label={'Kwota'}/>
                <Field name={'promotion_amount'} component={CashField} label={'Kwota promocyjna'}/>
                <Field name={'duration'} component={InputField} type={'number'} label={'Czas trwania'}/>
                <Field name={'currency'}  component={SelectField} options={options.currency.map((o,i) => ({
                    key: i,
                    value: o,
                    text: o,
                }))} label={'Waluta'}/>
            </Form.Group>
            <Field name={'promotion'} component={CheckboxField} label={'Promocja'}/>
            <Field name={'is_sms'} component={CheckboxField} label={'SMS'}/>
            <Field name={'best_offer'} component={CheckboxField} label={'Najlepsza oferta'}/>
            <Form.Button type='submit' id='submitFormButton' content='Zapisz'/>
        </Form>
    </Container>
};

export default connect(
    state => ({
        options: getPaymentPlansOptions(state)
    }),
    {
        onSubmit: apiSavePayment
    }
)(
    reduxForm({
        form: 'payment-plan-form',
        enableReinitialize: true,
    })(PaymentPlanFormView)
);