import React, {useState} from "react";
import {useCustomPaginatedQuery} from "../../api/core";
import {photosRequest} from "../../api/requests";
import {usePhotosApi} from "../../api/photos";
import {EditPhoto} from "../../components/forms/edit-photo-form";
import {PageContainer} from "../../components/page/PageContainer";
import {Button, Checkbox, Container, Divider, Tab} from "semantic-ui-react";
import {Gbutton} from "./photos";
import {useNewUserApi} from "../../api/users";
import {OriginalImageCrop} from "../../components/photo/original-image-crop";

export const PhotosToCropPage = () => {
    const {
        rawData: photos,
        queryCache,
        pagination
    } = useCustomPaginatedQuery('photos-to-approve', photosRequest, {
        initConfig: {
            sortBy: 'premium_type,created_at',
            filters: {
                cropped: 0,
                position: 1,
                private: 0,
            },
            perPage: 1,
        }
    });
    
    const [setAsHot, setSetAsHot] = useState(false)
    const photosApi = usePhotosApi(photos[0]?.id);
    const photo = photos?.[0] ?? {};

    const userApi = useNewUserApi();

    const handleSubmit = async (data) => {
        setSetAsHot(false);
        await photosApi.edit({...data, status: true});
        await userApi.setHotUser(photo.user_id, setAsHot);
        await queryCache.invalidateQueries(['photos-to-approve'])
    };

    const panes = [
        {
            menuItem: 'Edycja zdjęcia',
            render: () => <Tab.Pane attached={false}>
                <Container>
                    <Gbutton image={photo}/>
                    <Checkbox label={"Oznacz użytkownika jako hot"} checked={setAsHot} 
                              onChange={((event, data) => setSetAsHot(data.checked))} style={{marginLeft: '10px', marginRight: '10px'}}/>
                    <Button content={"Przenieś do prywatnych"} onClick={() => photosApi.setPrivate().then(() => queryCache.invalidateQueries('photos-to-approve'))}/>
                    <EditPhoto onSubmit={ handleSubmit } photo={photo} config={{hideStatusField: true, hidePrivateField: true}}/>
                </Container>
            </Tab.Pane>,
        },
        {
            menuItem: 'Przytnij oryginalne zdjęcie',
            render: () => <Tab.Pane attached={false}>
                <Container>
                    <OriginalImageCrop photo={photo}/>
                </Container>
            </Tab.Pane>,
        },
    ]

    return <PageContainer name={`Zdjęcia do przycięcia (${pagination?.total})`}>
        {photo.id ? (
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        ) : (
            <span>Brak</span>
        )}
    </PageContainer>
}

export default PhotosToCropPage;