import {List} from "semantic-ui-react";
import * as Advanced from "./AdvancedProperties/constants";
import React from "react";
import {gender, MyTable} from "./UserInfoBasicTable";

export const UserInfoAdditionalTable = ({data}) => (
    <MyTable data={[
        ['Szukana płeć', gender[data.looking_gender]],
        ['Wzrost', gender[data.height]],
        ['Stan cywilny', Advanced.MARTIAL_STATUS[data.martial_status]],
        ['Wykształcenie', Advanced.EDUCATION[data.education]],
        ['Zawód', Advanced.JOB[data.job]],
        ['Stosunek do palenia', Advanced.SMOKE[data.smoke]],
        ['Stosunek do alkoholu', Advanced.ALCOHOL[data.alcohol]],
        ['Dzieci', Advanced.CHILDREN[data.has_children]],
        ['Budowa ciała', Advanced.BODY[data.body]],
        ['Kolor oczu', Advanced.EYE_COLOR[data.eye_color]],
        ['Kolor włosów', Advanced.HAIR_COLOR[data.hair_color]],
        ['Czego szuka na stronie', data.looking_for && data.looking_for.split(',').map(el => (
            <List.Item>{Advanced.LOOKING_FOR[el]}</List.Item>
        ))],
    ]}/>
);