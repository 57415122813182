import {call} from 'redux-saga/effects';

export const apiSafeCall = (func) => call(function* () {
    try {
        yield call(func);
    } catch (e) {
        const message = e.response.message;
        if(message){
            console.log(message);
        }else{
            console.log('Nieznany błąd');
        }
    }
});