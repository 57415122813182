import axios from '../axios-interceptor';
import {apiQuery} from "../utils";
import {useMutation, usePaginatedQuery, useQuery, useQueryCache} from 'react-query';
import {useEffect, useState} from "react";
import {useUsersRouter} from "../hooks/router";
import moment, {now} from 'moment'
import {fetchPhotosRequest} from "./photos";
import {fetchThreads} from "./chat";
import {CancelToken} from 'axios'
import {useCustomPaginatedQuery} from "./core";
import {photosRequest} from "./requests";

const getUsers = (key, page=1, filters={}, action=undefined) => {
    const source = CancelToken.source()

    const promise = axios({
        url: apiQuery().for('users').page(page).where(filters).params({action}).get(),
        method: 'get',
        cancelToken: source.token,
    })

    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
    }

    return promise;
}

const getUser = async (key, id) => {
    const res = await axios({
        url: `users/${id}`,
        method: 'get',
    })
    return res.data;
}

const callUser = ({id, action, data, method='patch'}) => {
    return  axios({
        url: apiQuery().for(`users/${id}`).params({action}).get(),
        method,
        data
    })
}

export const useGetUsers = (config) => {
    const [page, setPage] = useState(1);
    const queryCache = useQueryCache()
    const [filters, setFilters] = useState({});

    const using_filtering = !Object.values(filters).every(x => x === undefined || x === "");

    const api = usePaginatedQuery({
        queryKey: ['users', page, using_filtering ? filters : {}],
        queryFn: () => getUsers('key', page, filters),
        config: {
            ...config,
            onSuccess: (data => {
                data.data.data.forEach( d => {
                queryCache.setQueryData(['users', `${d.id}`], () => ({data: d}))
            })
        })
    }});

    const prefetchPage = async (page_) => {
        await queryCache.prefetchQuery(['users', page_, filters], getUsers);
    }
    return {...api, page, setPage, setFilters, filters, prefetchPage,
        queryCache,
        users: api.resolvedData?.data?.data ?? [],
        cancelQueries: () => queryCache.cancelQueries(['users', page]
    )};
}

export const useGetUsersGroups = () => {
    const queryCache = useQueryCache()
    const [page, setPage] = useState(1);

    const api = usePaginatedQuery({
        queryKey: ['users-groups', page],
        queryFn: () => getUsers('key', page, undefined, 'get_groups'),
    });

    return {...api, page, setPage,
        queryCache,
        groups: api.resolvedData?.data?.data ?? [],
    };
}

export const useGetUsersPhotos = (id, filter={}, initialPage=1, sortValue='') => {
    const [page, setPage] = useState(initialPage);
    const [filters, setFilters] = useState(filter);
    const [sort, setSort] = useState(sortValue);

    useEffect( () => setPage(initialPage), [filters])

    const paginatedQueryResult = usePaginatedQuery({
        queryKey: ['photos', {user_id: id, page, filters, sort}],
        queryFn: () => fetchPhotosRequest({
            data:
                {filter: {user_id: id, ...filters},
                    page,
                    per_page: 15,
                    sort
                },
        })
    });

    return {
        ...paginatedQueryResult,
        page, setPage,
        filters, setFilters,
        photos: paginatedQueryResult.resolvedData?.data ?? []
    }
}

export const useGetUser = (id, options={withThreads:true, withPhotos:true}, config={}) => {
    const {withThreads, withPhotos} = {...options}

    const api = useQuery(['users', `${id}`], getUser, {...config});

    const photos = useCustomPaginatedQuery('photos', photosRequest, {
        config: {
            enabled: withPhotos
        },
        initConfig: {filters: {
                user_id: id
            }}
    })

    const threads = useQuery({
        queryKey: ['users', id, 'threads'],
        queryFn: () => fetchThreads({user_id: id}),
        config: {
            enabled: withThreads
        }
    })

    const user_data = api.data?.data ?? {}
    const user_photos = photos.data ?? []
    const user_threads = threads.data?.data ?? []

    user_data.translateGender = (g) => g === 'm' ? 'Mężczyzna' : g === 'f' ? 'Kobieta' : '';

    user_data.gender_name = user_data.translateGender(user_data.gender)

    user_data.can_set_as_hot_person = user_data.have_profile
    user_data.deleted = user_data.email?.indexOf("deleted") === 0

    user_data.is_time_banned = user_data.ban_end && moment(user_data.ban_end) > now()
    user_data.is_banned = user_data.is_time_banned || user_data.banned

    user_data.getStatus = () => {
        let name = "Aktywne";
        let style = {};

        if(user_data.deleted)
            return {name: "Usunięty", style: {color: 'red', textTransform: 'uppercase'}}


        if(user_data.banned){
            return {name: "Zablokowane", style: {color: 'red'}}
        }

        if(user_data.is_time_banned){
            return {name: "Ban " + user_data.ban_type, style: {color: 'red'}}
        }

        if(user_data.freeze){
            return {name: "Zawieszone", style: {}}
        }

        if(!user_data.approved_profile)
            name = "Do zatwierdzenia"

        if(user_data.auto_ban)
            return {name: "Blokada spowodowana ilością zgłoszeń", style: {}}

        return {name, style}
    }

    return {...api,
        user_data,
        photos,
        user_photos,
        user_threads
    }
}

export const useNewUsersApi = () => {
    return {
        useGetUsers,
        useGetUser,
    }
}

export const useNewUserApi = () => {
    const {user_id} = useUsersRouter();
    const queryCache = useQueryCache();
    const [mutate] = useMutation(callUser, {onSuccess: (data, variables) => {
        console.log("SUCCESS,", {data, variables})
            queryCache.setQueryData(['users', `${variables.id}`], () => ({data: data.data.data}))
            queryCache.invalidateQueries(['users', `${variables.id}`])
        }});

    return {
        callUser: mutate,
        setHotUser: (user_id, isHot) => mutate({id: user_id, action: 'hot_person', data: {value: isHot}}),
        edit: (user_id, data, action=undefined) => mutate({id: user_id, data, action}, {throwOnError: false}),
        delete: (user_id) => mutate({id: user_id, method: "delete"}),
        fetchActiveUser: async () => {
            console.log("invalidate queries", ['users', user_id])
            await queryCache.invalidateQueries(['users', user_id])

        },
        uploadPhoto(id, file) {
            let formData = new FormData();
            formData.append("file", file);
            axios.post(`users/${id}/photos`, formData).then(r => {
                queryCache.invalidateQueries(['users', user_id])
                queryCache.invalidateQueries('photos')
            })
        }
    }
}

export default useNewUsersApi;