import React from 'react'
import {connect} from "react-redux";
import {Button} from "semantic-ui-react";
import TableView from "../../components/stateless/Table";
import {apiDeletePaymentPlan, apiGetPaymentPlans, getPaymentPlans} from "../../modules/payment_plans";
import {Link} from "react-router-dom";
import {ROUTE} from "../../route-names";
import {PageContainer} from "../../components/page/PageContainer";

export class PaymentPlansView extends React.Component{

    constructor(props) {
        super(props);
        props.apiGetPaymentPlans();
    }

    render() {

        const {deletePaymentPlan} = this.props;

        const Actions = () => <Link to={ROUTE.PAYMENT_PLANS_CREATE}>
            <Button id='create_payment_plan' floated='right'>Dodaj plan premium</Button>
        </Link>

        return <PageContainer name={'Plany premium'} actions={Actions}>

            <TableView data={this.props.payments} columns={[
                {displayName: 'Typ', view: (data) => (data.account_type)},
                {displayName: 'Kwota', view: (data) => (`${(data.amount/100).toFixed(2)} ${data.currency}`)},
                {displayName: 'Kwota promocyjna', view: (data) => (`${(data.promotion_amount/100).toFixed(2)} ${data.currency}`)},
                {displayName: 'Promocja', view: (data) => (data.promotion ? "Tak" : "Nie")},
                {displayName: 'SMS', view: (data) => (data.is_sms ? "Tak" : "Nie")},
                {displayName: 'Czas trwania', view: (data) => (`${data.duration} dni` )},
                {displayName: 'Najlepsza oferta', view: (data) => (`${data.best_offer ? "Tak" : "Nie" }` )},
                {displayName: 'Akcje', view: (data) => (<div>
                        <Link to={ROUTE.payment_plan(data.id)}>
                            <Button>Edytuj</Button>
                        </Link>
                        <Button onClick={() => deletePaymentPlan(data.id)}>Usuń</Button>
                    </div>)},
            ]}/>

        </PageContainer>
    }
}

export const PaymentPlans = connect(state => ({
    payments: getPaymentPlans(state),
}), {
    apiGetPaymentPlans: () => apiGetPaymentPlans(),
    deletePaymentPlan: (id) => apiDeletePaymentPlan(id),
})(PaymentPlansView);

export {default as PaymentPlanEdit} from "./payment_plan_edit";
export {default as PaymentPlanCreate} from "./payment_plan_create";





