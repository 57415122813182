import React from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {request} from "../../modules/app";

export const useControlledComponent = (inputValue, onChange, debounce=0) => {
    const [state, setState] = React.useState(inputValue);
    React.useEffect( () => {
        if(JSON.stringify(inputValue) !== JSON.stringify(state)){
            onChange && onChange(state);
        }
    }, [JSON.stringify(state)])

    React.useEffect( () => {
        if(JSON.stringify(inputValue) !== JSON.stringify(state)){
            setState(inputValue);
        }
    }, [JSON.stringify(inputValue)])

    return [state, setState]
}


export const useModal = (content=null) => {
    const [open, setOpen] = React.useState(false);
    const [object, setObject] = React.useState(null);
    const [content_, setContent] = React.useState(content);

    React.useEffect( () => {
        if(open)
            setObject(<Modal open={open}
                             onClose={()=>setOpen(false)} size='small'>
                {content_}
            </Modal>)
        else
            setObject(<></>)
    }, [open])

    const setContentAndOpenModal = (content) => {
        setContent(content);
        setOpen(true)
    };

    const openConfirmModal = (content, onAccept, onDeny) => {
        setContentAndOpenModal(
            <>
                <Modal.Content>
                    <Header>
                        {content}
                    </Header>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => {
                        setOpen(false);
                        onAccept && onAccept();
                    }} content={'Tak'}/>
                    <Button onClick={() => {
                        setOpen(false);
                        onDeny && onDeny();
                    }} content={'Nie'}/>
                </Modal.Actions>
            </>
        )
    }

    return {
        modal: object,
        openModal: () => setOpen(true),
        close: () => setOpen(false),
        setContent,
        setContentAndOpenModal,
        setOpen,
        openConfirmModal: (content, onAccept, onDeny) => openConfirmModal(content, onAccept, onDeny),
    }
}

export const useApi = (url, method) => {
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState({});

    const onDataReady = (function_) => response.data ? function_(response.data) : null;

    const call = (data, onSuccess=null, onError=null) => {
        setLoading(true);
        request(method, url, data).then(res => {
            setLoading(false);
            setResponse(res.data)
            onSuccess && onSuccess(res.data);
        }).catch(e=>{
            onError && onError();
        });
    }

    return {call, loading, response, onDataReady};
}