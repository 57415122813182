import React from 'react'
import {Button, Header, Image, Menu, Popup, Container} from "semantic-ui-react";
import {ROUTE} from "../../route-names";
import {Link, useLocation, useParams} from "react-router-dom";
import {reverse} from "named-urls";
import {matchPath} from "react-router";
import {ApiActionButton, BanPopupAdvanced, PopupAction} from "../../pages/users/user";
import {useGetUser, useNewUserApi} from "../../api/users";
import {If} from "../helpers/Condition";
import useUserApi from "../../api/user";
import {useQueryCache} from "react-query";
import {useApi, useModal} from "../hooks";
import {api_url} from "../../utils";
import {usePendingChangesApi} from "../../api/pending-changes";

const InlineHeader = ({children, style, ...props}) =>
    <Header  style={{...style, display: 'inline'}} {...props} >
        {children}
    </Header>

const UserMenu = props => {
    const {id} = useParams();
    const {children } = props;
    const {pathname} = useLocation();
    const oldUserApi = useUserApi();
    const queryCache = useQueryCache();
    const {user_data: user} = useGetUser(id);
    const userApi = useNewUserApi();
    const apiLogin = useApi(api_url(`admin/users/${id}?action=login`), 'get');
    const pendingChangeApi = usePendingChangesApi();
    const {modal, setContentAndOpenModal, openConfirmModal} = useModal()

    const getMessagesLines = (data) => {
        var text = '';
        if ((data.limited_sent != undefined || data.limited_received != undefined))
            text += data.limited_sent + ' / ' + data.limited_received + ' ORAZ ';
        text += data.total_sent + ' / ' + data.total_received + ' / ' + data.total_threads;
        return text;
    }

    const links = [
        {
            name: 'Informacje',
            path: ROUTE.USER,
        },
        {
            name: 'Komentarze',
            path: ROUTE.USER_COMMENTS,
        },
        {
            name: 'Zdjęcia',
            path: ROUTE.USER_PHOTOS,
        },
        {
            name: 'Chaty (' + getMessagesLines(user) + ')',
            path: ROUTE.USER_CHAT,
        },
        {
            name: 'Płatności',
            path: ROUTE.USER_PAYMENTS,
        },
        {
            name: 'Kogo szukam',
            path: ROUTE.USER_ADVANCED_INFO,
        },
        {
            name: 'Zdjęcia weryfikujące',
            path: ROUTE.USER_VERIFICATION_PHOTOS,
        },
        {
            name: 'Logowania',
            path: ROUTE.USER_LOGIN_LOGS,
        },
    ];

    const FemalePremiumButtons = () => {
        let button;
        if (user.has_female_premium)
            button = <Button content={"Zabierz premium kobiet"} onClick={() => userApi.edit(id, {}, 'remove_female_premium')} />;
        else if (user.gender == 'f')
            button = <Button content={"Nadaj premium kobiet"} onClick={() => userApi.edit(id, {}, 'give_female_premium')} />;
        if (button) {
            return <div><div>Premium kobiet:</div>{button}</div>
        } else {
            return <></>
        }
    };

    let premiumColor = undefined;
    if (user.premium_payment != 'female_premium') {
        if (user.premium_type == 'premium')
            premiumColor = 'green';
        else if (user.premium_type == 'vip')
            premiumColor = 'blue';
    }

    return <Container style={{ width: '99%' }}>
        {modal}
        {user.deleted_at ? (
            <Button.Group floated='right'>
                <Button content={"Przywróć"}
                        onClick={() => userApi.edit(id, undefined, 'restore')}/>
            </Button.Group>
        ) : (
            <Button.Group>
                <PopupAction content={'Premium'} position={'bottom'} color={premiumColor} wide>
                    <FemalePremiumButtons/>

                    <div>
                        <div>Darmowe premium:</div>
                        <Button content={"Dodaj 2 dni"} onClick={() => userApi.edit(id, { days: 2 }, 'give_free_premium')}/>
                        {user.future_premiums?.findIndex(premium => premium.payment_method == 'free_premium') >= 0 ? (
                            <Button content={"Zabierz darmowe premium"} onClick={() => userApi.edit(id, {}, 'remove_free_premium')}/>
                        ) : ''}
                    </div>

                    <div>
                        <div>Konto premium:</div>
                        <ApiActionButton content={'Premium 2 dni'} apiHook={oldUserApi.givePremium} apiData={{
                            account_type: 'premium',
                            days: 2,
                            user_id: id
                        }} onSuccess={() => queryCache.invalidateQueries(['users', `${id}`])}/>
                        <ApiActionButton content={'Premium 30 dni'} apiHook={oldUserApi.givePremium} apiData={{
                            account_type: 'premium',
                            days: 30,
                            user_id: id
                        }} onSuccess={() => queryCache.invalidateQueries(['users', `${id}`])}/>

                        {/* <PremiumPopupAdvanced/> */}
                    </div>

                    <div>
                        <div>Konto VIP:</div>
                        <ApiActionButton content={'VIP 2 dni'} apiHook={oldUserApi.givePremium} apiData={{
                            account_type: 'vip',
                            days: 2,
                            user_id: id
                        }} onSuccess={() => queryCache.invalidateQueries(['users', `${id}`])}/>
                        <ApiActionButton content={'VIP 30 dni'} apiHook={oldUserApi.givePremium} apiData={{
                            account_type: 'vip',
                            days: 30,
                            user_id: id
                        }} onSuccess={() => queryCache.invalidateQueries(['users', `${id}`])}/>
                    </div>

                </PopupAction>

                <PopupAction content={'Ban'} position={'bottom'} color={user.is_banned && 'black'}>
                    {user.is_banned ?
                        <ApiActionButton content={'Odblokuj'} apiHook={oldUserApi.ban} apiData={{
                            action: 'remove_ban',
                        }}/> :
                        <BanPopupAdvanced/>
                    }
                </PopupAction>
                <Button content={"Podejrzany"} onClick={() => userApi.edit(id, {suspect: !user.suspect})}
                        color={user.suspect && "red"}/>
                <Button content={"Zaufany"} onClick={() => userApi.edit(id, {confidential: !user.confidential}, 'confidential_user')}
                        color={user.confidential && "green"}/>
                <Popup content={"Nie można ustawić jako gorący użytkownik, ponieważ użytkownik nie ma zdjęcia profilowego"}
                       disabled={user.can_set_as_hot_person} trigger={
                    <span style={{filter: `saturate(${user.hot_person ? 1 : 0})`}}>
                        <Button content={"HOT"} onClick={() => userApi.setHotUser(id, !user.hot_person)}
                                color={user.hot_person && "pink"} disabled={!user.can_set_as_hot_person}/>
                    </span>
                }/>
                <If condition={user.auto_ban}>
                    <Button onClick={() => userApi.edit(id, undefined, 'remove_auto_ban')} floated='right'
                            content='Anuluj automatyczną blokadę'
                            color={'red'}/>
                </If>
                <Button
                    onClick={() => userApi.edit(id, {}, 'reset_password')}
                    content='Zresetuj hasło'
                />
                <Button
                    onClick={() => userApi.edit(id, {}, 'reset_cookies')}
                    content='Zresetuj cookies'
                />

                {user.freeze ? (
                    <Button
                        onClick={() => openConfirmModal("Czy na pewno anulować zawieszenie konta tego użytkownika?", () => userApi.edit(id, {freeze: 0}))}
                        content='Anuluj zawieszenie'
                        color={'violet'}
                    />
                ) : (
                    <Button
                        onClick={() => openConfirmModal("Czy na pewno zawiesić tego użytkownika?", () => userApi.edit(id, {freeze: 1}))}
                        content='Zawieś'
                    />
                )}

                {!user.confirmed_by_photo ? 
                    user.verification_required ? (
                        <Button
                            onClick={() =>  userApi.edit(id, {}, 'cancel_verification_required')}
                            content='Anuluj wymuszenie weryf.'
                            color={'red'}
                        />
                    ) : (
                        <Button
                            onClick={() => userApi.edit(id, {}, 'verification_required')}
                            content='Wymuś weryf.'
                        />
                    )
                : ''}

                <Link to={reverse(ROUTE.USER_EDIT, {id})}>
                    <Button id='editUserButton' floated='right' content='Edytuj'/>
                </Link>

                <Button onClick={() => {
                    apiLogin.call({}, (response) => {
                        window.open(response.link, "_blank")
                    })
                }} floated='right' content='Zaloguj'/>
                {user.banned ? (
                    <Button
                        onClick={() => openConfirmModal("Czy na pewno odblokować tego użytkownika?", () => userApi.edit(id, {}, 'remove_ban'))}
                        content='Odblokuj'
                        color="black"
                    />
                ) : (
                    <Button
                        onClick={() => userApi.edit(id, {ban_type: 0}, 'ban')}
                        content='Zablokuj'
                    />
                )}

                <Button onClick={() => openConfirmModal("Czy na pewno usunąć tego użytkownika?", () => userApi.delete(id))} content='Usuń'/>
            </Button.Group>
        )}

        <Header as='h1'>
            <a href={`${process.env.REACT_APP_FRONTEND_URL}/profil/${user.login}`} target="_blank" rel="noopener noreferrer">
                <Image src={user.profile_photo || '/user_avatar.png'} size='tiny' circular style={{display: 'inline'}}/>
            </a>
            <InlineHeader>{user.login}</InlineHeader>, <InlineHeader as={'h3'}>{user.name}</InlineHeader>
            {' '}
            <InlineHeader as={'h3'}>({user.age}, {user.city?.name}, {user.country?.name})</InlineHeader>
            {' '}
            <InlineHeader as={'h3'} style={
                {
                    color: `${user.premium_type === 'premium' || user.premium_type === 'vip' ? 'rgb(33, 133, 208)' : undefined}`,
                    textTransform: 'capitalize'
                }
            }>{user.premium_type}</InlineHeader>
        </Header>

        <Header style={{color: "#e10957"}}>
            {user.gender_name}
            <Button
                onClick={() => openConfirmModal(`Czy na pewno zmienić płeć na ${(user.gender === 'm' ? 'zeńską' : 'męską')}?`,
                    () => userApi.edit(id, {gender: user.gender === 'm' ? 'f' : 'm'})
                )}
                content={"Zmień płeć"}
            />
            {user.privileged ? (
                <Button
                    onClick={() => userApi.edit(id, {privileged: 0})}
                    content='Zabierz przywilej'
                />
            ) : (
                <Button
                    onClick={() => userApi.edit(id, {privileged: 1})}
                    content='Przyznaj przywilej'
                />
            )}
        </Header>
        <div>
            <InlineHeader as={'h3'} style={{color: user.confirmed_by_photo && 'green'}} > {user.confirmed_by_photo ? 'Zweryfikowany zdjęciem' : 'Niezweryfikowany zdjęciem'} </InlineHeader>
            {/* <Button size={'mini'} onClick={() => userApi.edit(id, {}, user.confirmed_by_photo ? 'cancel_confirmed_by_photo' : 'confirmed_by_photo')} content={user.confirmed_by_photo ? 'Usuń weryfikację' : 'Zweryfikuj'}/> */}
            <InlineHeader as={'h3'} style={{color: user.confirmed_by_sms && '#37a3ff', marginLeft: '10px'}} > {user.confirmed_by_sms ? 'Zweryfikowany SMSem' : 'Niezweryfikowany SMSem'} </InlineHeader>
            <Button size={'mini'} onClick={() => userApi.edit(id, {}, user.confirmed_by_sms ? 'cancel_confirmed_by_sms': 'confirmed_by_sms')} content={user.confirmed_by_sms ? 'Usuń weryfikację' : 'Zweryfikuj'}/>

        </div>

        <div>
            <Menu fluid tabular>
                {links.map(link=> {
                    return <Link to={reverse(link.path, {id})}
                                 children={<Menu.Item active={matchPath(pathname, {
                                     path: link.path,
                                     exact: true
                                 })} name={link.name}/>}/>
                })}
            </Menu>
        </div>

        <div>
            {children}
        </div>
    </Container>
};

export default UserMenu
