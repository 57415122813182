import {Form} from "semantic-ui-react";
import React from "react";

const TextArea = ({onChange, ...all}) => {
    return <Form.TextArea {...all}
                onChange={(e,{value})=>(onChange(value))}
    />
};

export default TextArea
