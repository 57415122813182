import React from 'react';
import {ChatList, ChatListItem, Column, defaultTheme, Row, ThemeProvider, Title} from "@livechat/ui-kit";
import {Button, Dimmer, Divider, Header, Loader, Message} from "semantic-ui-react";
import {api_url} from "../../utils";
import {ROUTE} from "../../route-names";
import {PageContainer} from "../../components/page/PageContainer";
import {MailPreview} from "../../components/mail_preview/MailPreview";
import {useApi} from "../../components/hooks";
import {UserFilter} from "./components/UsersFilter";
import UserFilterForm from "../../components/forms/UserFilterFormOnContext";
import {push} from "react-router-redux";
import {useDispatch} from "react-redux";

export const SentMails = () => {

    const dispatch = useDispatch();
    const sentMails = useApi(api_url('admin/mailManager'), 'get');

    const [activeMail, setActiveMail] = React.useState({
        filter: {}
    });

    React.useEffect( () => {
        sentMails.call();
    }, []);

    const actions = () => (<Button icon={'add'} content={'Nowy'} onClick={() => dispatch(push(ROUTE.MAILING_NEW))}/>)

    return <PageContainer name={'Mailing'} actions={actions}>
        <Dimmer active={sentMails.loading} >
            <Loader />
        </Dimmer>
        <ThemeProvider theme={{...defaultTheme,
            ChatListItem: {
                css: {display: 'block'}
            }
        }}>

            <div style={{display: 'flex'}}>
                <div>
                    <Divider horizontal>Wysłane maile</Divider>
                    <SentMailsView data={sentMails.response.data} active={activeMail.id} onSelect={setActiveMail} style={{'min-width': '40vw'}}/>
                </div>
                <div>
                    <Divider horizontal>Podgląd</Divider>
                    <Header style={{textAlign: 'center'}}>
                        {activeMail.title}
                    </Header>
                    <MailPreview mail_body={activeMail.body} style={{minWidth: '40vw'}}/>
                </div>
                <UserFilterForm.Provider filter={activeMail.filter}>
                    <div>
                        <Divider horizontal>Użyty filtr</Divider>
                        <UserFilter/>
                        <Message>
                            <Message.Header style={{textAlign: 'center'}}>Wiadomość została wysłana do {activeMail.users_count || 0} użytkowników</Message.Header>
                        </Message>
                    </div>
                </UserFilterForm.Provider>
            </div>
        </ThemeProvider>
    </PageContainer>
}

const SentMailsView = ({data, active, onSelect}) => (
    <ChatList>
        {data && data.map(function (mail, id) {
            const {title} = mail;
            return <ChatListItem active={id === active} onClick={()=>onSelect({...mail, id})}>
                <Column fill>
                    <Row justify>
                        <Title ellipsis>
                            <LongText content={title} limit={30}/>
                        </Title>
                    </Row>
                </Column>
            </ChatListItem>
        })}
    </ChatList>
);

const LongText = ({content, limit}) => {
    if(content.length<=limit) {
        return <div>
            {content}
        </div>
    }else{
        const toShow = content.substring(0,limit)+"...";
        return <div>
            {toShow}
        </div>
    }
}
