import {handleActions} from "redux-actions";
import {put, select, takeEvery} from "redux-saga/effects";
import {goBack} from "react-router-redux";
import {createAction} from "../utils";
import {getPhotosStore} from "../store/createRootReducer";
import {API} from "../api-endpoints";
import {fetchApi} from "./app";
import {getFormValues, startSubmit} from "redux-form";

const DELETE_PHOTO = 'app/photos/DELETE_PHOTO';
const FETCH_PHOTOS = 'app/photos/FETCH_PHOTOS';
const ROTATE_PHOTO = 'app/photos/ROTATE_PHOTO';

const SUBMIT_UPDATE_PHOTO = 'app/photos/SUBMIT_UPDATE_PHOTO';

const RESET = 'app/photos/RESET';
const UPDATE = 'app/photos/UPDATE';
const CHANGE_PAGE = 'app/photos/CHANGE_PAGE';

export const submitPhoto = (form) => createAction(SUBMIT_UPDATE_PHOTO, {form});
export const fetchPhotos = (id=null) => createAction(FETCH_PHOTOS, {id});
export const deletePhoto = (id) => createAction(FETCH_PHOTOS, {id});
export const rotatePhoto = (rotation) => createAction(ROTATE_PHOTO, {rotation});

const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);
export const resetPhoto = (photo) => reset('photo', photo);
export const resetPhotos = (photos) => reset('photos', photos);
export const resetQuery = (query) => reset('query', query);

export const changePage = (page=1) => createAction(CHANGE_PAGE, {page});
const setPhotoId = (id) => reset('id', id);

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
    id: undefined,
    photo: {},
    photos: [],
    meta: {current_page: 1, last_page: 1},
    filter: { status:0},
    query: {},
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
        [CHANGE_PAGE]: (state, action) => {
            return {...state, meta: {...state.meta, current_page: action.payload.page}}
        },
    },
    initialState
)

export const getPhotos = state => getPhotosStore(state).photos;
export const getPhotosFilter = state => getPhotosStore(state).filter;
export const getPhotosQuery = state => getPhotosStore(state).query;
export const getPhotosMeta = state => getPhotosStore(state).meta;
export const getPhoto = state => getPhotosStore(state).photo;
export const getPhotoId = state => getPhotosStore(state).id;

function* callFetchPhotos(action) {
    const {id} = action.payload;
    let query = {...yield select(getPhotosFilter), ...yield select(getPhotosQuery)};
    const meta = yield select(getPhotosMeta);

    const response = yield fetchApi(API.photo(id), {
        per_page: 14,
        page: meta.current_page,
        ...query
    }, 'get');

    if(id){
        yield put(setPhotoId(id));

        const data = response.data.data;
        const {width, height} = data;

        const crop = {
            x: data.crop_x,
            y: data.crop_y,
            width: data.crop_width,
            height: data.crop_height,
        };

        const photo = Object.assign({}, data, {crop: {
                crop_pixels: crop,
                crop_percent: {
                    x: crop.x  / width * 100,
                    y: crop.y / height * 100,
                    width: crop.width / width  * 100,
                    height: crop.height / height  * 100,
                    unit: '%',
                }
            }});

        const original_photo_url = yield fetchPhotoAndGetBlobUrl(photo.id, {
            size: 'original',
            cropped: 0,
        });

        const preview_photo_url = yield fetchPhotoAndGetBlobUrl(photo.id, {
            size: 'small',
            cropped: 1
        });

        photo.url = original_photo_url;
        photo.preview_url = preview_photo_url;
        yield put(resetPhoto(photo));
    }else {
        const photos = response.data.data;

        for (let i = 0; i < photos.length; i++) {
            const photo_response = yield fetchApi(API.photo_base_64(photos[i].id), {
                cropped: 1,
                size: 'small'
            }, 'get');
            const blob = yield fetch(photo_response.data.image)
                .then(res => res.blob());
            photos[i].url = window.URL.createObjectURL(blob);
        }

        yield put(resetPhotos(photos));
        yield put(resetMeta(response.data.meta));
    }
}

export function *get_blob(image) {
    const blob = yield fetch(image)
        .then(res => res.blob());
    return window.URL.createObjectURL(blob);
}

function* fetchPhotoAndGetBlobUrl(photo_id, data={}){
    const photo_response = yield fetchApi(API.photo_base_64(photo_id), data, 'get');
    return yield get_blob(photo_response.data.image);
}

function* callUpdatePhoto(action) {
    const {id} = yield select(getPhotoId);
    const photo = action.payload.photo;

    photo.crop = {...photo.crop.crop_pixels};

    try {
        yield fetchApi(API.photo(photo.id), photo, 'patch');
        yield put(fetchPhotos());
        yield put(goBack())

    }catch (e) {
    }
}

function* callSubmitUpdatePhoto(action){
    const {form} = action.payload;
    const values = yield select(getFormValues(form));
    console.log("FORM", form, values);
    yield put(startSubmit(form));

    try{
        yield callUpdatePhoto({payload: {photo: values}})
    }catch (e) {
    }
}

function* callRotatePhoto(action) {
    const photo_id = yield select(getPhotoId);
    console.log(action);
    const response = yield fetchApi(API.photo(photo_id), {action: 'rotate', rotation: action.payload.rotation}, 'put');
}

export function* photosSaga() {
    yield takeEvery(FETCH_PHOTOS, callFetchPhotos);
    yield takeEvery(SUBMIT_UPDATE_PHOTO, callSubmitUpdatePhoto);
    yield takeEvery(ROTATE_PHOTO, callRotatePhoto);
}