import {useHistory as useHistory_, useParams} from "react-router";
import * as queryString from "query-string";
import {encodeObject} from 'serialize-query-params';

const useHistory = () => {
    const history = useHistory_();

    function goTo(address, replace = false){
        if(!replace)
            history.push(address)
        else{
            history.replace(address)
        }
    }

    return {push: history.push, replace: history.replace, goTo}
}

export const useUsersRouter = () => {
    const params = useParams();
    const history = useHistory()
    const {id: user_id} = params

    return {
        user_id,
        goToUsersPage: (replace=false, query={}) => history.goTo(`/users?${query ? `query=${encodeObject(query)}` : ''}`, replace),
        goToUserPage: (id, replace=false) => history.goTo(`/users/${id}`, replace),
        goToUserEditPage: (id, replace=false) => history.goTo(`/users/${id}/edit`, replace),
        goToUserCommentsPage: (id, replace=false) => history.goTo(`/users/${id}/comments`, replace),
        goToUserPhotosPage: (id, replace=false) => history.goTo(`/users/${id}/photos`, replace),
        goToUserOrdersPage: (id, replace=false) => history.goTo(`/users/${id}/orders`, replace),
        goToUserChatPage: (id, replace=false) => history.goTo(`/users/${id}/chat`, replace),
        goToUserAdvancedInfosPage: (id, replace=false) => history.goTo(`/users/${id}/advanced`, replace),
        goToUserVerificationPhotosPage: (id, replace=false) => history.goTo(`/users/${id}/verification_photos`, replace),
        goToCreateUserPage: (replace=false) => history.goTo(`/users/create`, replace),
        history,
        params
    }
}


export const useAppRouter = () => {
    const usersRouter = useUsersRouter();
    const history = useHistory()

    return {
        usersRouter,
        goToSendMailPage: (filters, replace=false) => history.goTo(`/suggestions?`+queryString.stringify(filters), replace),
        goToUserPage: (user_id, replace=false) => history.goTo(`/users/${user_id}`, replace),
        goToCommentsSearch: (text) => history.goTo(`/comments/search/${text}`),
        goToMessagesSearch: (body) => history.goTo(`/messages/search/${body}`),
        history
    }

}