import {createAction} from "../utils";
import {handleActions} from "redux-actions";
import {getUsersPlansStore} from "../store/createRootReducer";
import {put, select, takeEvery} from "@redux-saga/core/effects";
import {fetchApi} from "./app";
import {API} from "../api-endpoints";

const FETCH_USERS_PLANS = 'app/users_plans/FETCH_USERS_PLANS';
const RESET = 'app/users_plans/RESET';
const UPDATE = 'app/users_plans/UPDATE';

export const fetchUsersPlans = () => createAction(FETCH_USERS_PLANS);
const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetUsersPlansQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({current_page: page});

export const filters = {
    all: {},
    active: {
        'active': 1
    },
    custom: {
        'type': 0
    }
};

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
    id: undefined,
    users_plans: [],
    meta: {current_page: 1, last_page: 1},
    filter: filters.all,
    query: {}
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {    
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
    },
    initialState
)

export const getUsersPlans = (state) => getUsersPlansStore(state).users_plans;
export const getUsersPlansMeta = (state) => getUsersPlansStore(state).meta;
export const getUsersPlansQuery = (state) => getUsersPlansStore(state).query;
export const getUsersPlansFilter = (state) => getUsersPlansStore(state).filter;

export function* callFetchUsersPlans() {

    let filter = yield select(getUsersPlansFilter);
    let {current_page: page} = yield select(getUsersPlansMeta);

    const query = yield select(getUsersPlansQuery);
    const response = yield fetchApi(API.users_plans(), {...filter, page, ...query}, 'get');
    yield put(reset('users_plans', response.data.data));
    yield put(resetMeta(response.data.meta));
}

export function* usersPlansSaga() {
    yield takeEvery(FETCH_USERS_PLANS, callFetchUsersPlans);
}