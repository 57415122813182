import connect from "react-redux/es/connect/connect";
import TableView from "../components/stateless/Table";

const columns = [
    {displayName: 'Id', name: 'id'},
    {displayName: 'E-Mail', name: 'email'},
    {displayName: 'Imię', name: 'name'},
];

const mapStateToProps = state => {
    return {
        data: state.admins.admins,
        columns: columns,
    };
};

export default connect(mapStateToProps)(TableView);