import React from "react";
import {Button, Checkbox, Form, Header, List, Segment} from "semantic-ui-react";


export const RoleView = ({role, all_permissions, onClickSave, onClickEdit, onChange, onClickPermission, onDelete}) => (
    <Segment compact style={{background: '#d3dee4'}}>
        {role.edit ?
            <Form.Input focus placeholder='Nazwa roli' name='name' value={role.name} onChange={(e,v)=>onChange(role.id, v.name, v.value)}/>
            :
            <Header>{role.name}</Header>
        }
        {!role.edit ?
            <Button onClick={()=>onClickEdit(role.id)}>
                Edytuj
            </Button>
            :
            <Button onClick={()=>onClickSave(role.id)}>
                Zapisz
            </Button>
        }

        <Button onClick={()=>onDelete(role.id)}>
            Usuń
        </Button>
        {all_permissions.map((permission)=>(
            <List key={permission.id}>
                <List.Item>
                    <Checkbox disabled={!role.edit} checked={role.permissions.indexOf(permission.name) > -1} onClick={()=>onClickPermission(role.id, permission.name)} label={permission.display_name}>
                    </Checkbox>
                </List.Item>
            </List>
        ))}
    </Segment>
);