import {PhotosApiProvider} from "../../api/photos";
import {Button, Popup, Icon} from "semantic-ui-react";
import React from "react";


export function PhotoRotateButtons({photo_id}) {
    return <PhotosApiProvider id={photo_id} render={(api) => (
        <>

            {[90, 180, 270].map(r => (
                <Button size={'tiny'} onClick={() => api.rotate(r)} content={r} loading={api.isLoading}
                        disabled={api.isLoading} key={r}/>
            ))}
        </>
    )}/>;
}

export function RotatePhotoPopup({photo_id}) {

    return <Popup positionFixed on={"click"} hoverable trigger={<Button icon size={'tiny'} content={"Obróć"}><Icon name={'redo'}/></Button>}>
        <PhotoRotateButtons photo_id={photo_id} />
    </Popup>;
}