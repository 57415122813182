import ApiQuery from "../ApiQuery";
import Moment from "moment";
import 'moment/locale/pl';
import _ from "lodash";

const DOMAIN = process.env.REACT_APP_SERVER_URL;

export function api_url(url, id='') {
    let s = `${DOMAIN}/api/${url}`;
    if(id){
        return s + "/" + id;
    }
    else
        return s;
}

export function apiQuery(){
    return new ApiQuery();
}

export function server_url(url=null) {
    return url ? DOMAIN : `${DOMAIN}/${url}`;
}

export function isActivePermission(permission, active_permissions){
    return !!active_permissions.find(el => el === permission.name);
}

export function createAction(type, payload=[]) {
    return{
        type,
        payload
    }
}

Moment.locale('pl')

export const parseDate = (date) => date ? Moment(date).locale('pl').format('Y-MM-DD HH:mm') : ''

export const keysToCamel = function (o) {
    if (_.isObject(o)) {
        const n = {};

        Object.keys(o)
            .forEach((k) => {
                if(o[k] !== undefined)
                    n[_.camelCase(k)] = keysToCamel(o[k]);
            });

        return n;
    } else if (_.isArray(o)) {
        return o.map((i) => {
            return keysToCamel(i);
        });
    }

    return o;
};

export const keysToSnake = function (o) {
    if (_.isObject(o)) {
        const n = {};

        Object.keys(o)
            .forEach((k) => {
                n[_.snakeCase(k)] = keysToSnake(o[k]);
            });

        return n;
    } else if (_.isArray(o)) {
        return o.map((i) => {
            return keysToSnake(i);
        });
    }

    if (o === "1" || o === "0" || Number.parseInt(o) == o)
    {
        return _.toInteger(o)
    }

    return o;
};