import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Container, Divider, Grid, Header} from "semantic-ui-react";
import {RoleView} from "../../components/stateless/routes/roles/RoleView";
import {
    addRole,
    deleteRoleSaga,
    editRole,
    fetchRolesSaga,
    saveRoleSaga,
    toggleEdit,
    togglePermission
} from "../../modules/roles"
import {getPermissions, getRoles} from "../../store/createRootReducer";

class Roles extends Component {
    constructor(props) {
        props.fetchRoles();
        super(props);
    }

    render() {
        const {roles, handlePermissionChange, handleChange, handleEditClick, all_permissions, addRole, handleDeleteClick, handleSaveClick} = this.props;
        return <Container style={{width: '95%'}}>
            <Divider hidden/>
            <Header as='h2'>
                Role i Pozwolenia
            </Header>
            <Button onClick={addRole} disabled={!!roles.find(el=>el.edit === true)}>
                Dodaj role
            </Button>
            <Divider/>

            <Grid>
            {roles.map((role) => (
                <div style={{marginTop: '20px'}}>
                    <RoleView
                        all_permissions={all_permissions}
                        onClickEdit={handleEditClick}
                        onClickSave={handleSaveClick}
                        onChange={handleChange}
                        role={role}
                        onClickPermission={handlePermissionChange}
                        onDelete={handleDeleteClick}
                    />
                </div>
            ))}
            </Grid>
        </Container>
    }
}

const mapStateToProps = (state) => {
    return {
        roles: getRoles(state),
        all_permissions: getPermissions(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    addRole: ()=>dispatch(addRole()),
    handleEditClick: (id)=>dispatch(toggleEdit(id)),
    handleSaveClick: (id)=>dispatch(saveRoleSaga(id)),
    handleChange: (id, key, value)=>dispatch(editRole(id, key, value)),
    handlePermissionChange: (id, name)=>dispatch(togglePermission(id, name)),
    fetchRoles: ()=>dispatch(fetchRolesSaga()),
    handleDeleteClick: (id)=>dispatch(deleteRoleSaga(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);