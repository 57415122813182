import { handleActions } from "redux-actions";
import { put, select, takeEvery } from "redux-saga/effects";
import { createAction } from "../utils";
import { getPaymentStatsStore } from "../store/createRootReducer";
import { API } from "../api-endpoints";
import { fetchApi } from "./app";

const FETCH_PAYMENT_STATS = 'app/content/FETCH_PAYMENT_STATS';

const RESET = 'app/content/RESET';
const UPDATE = 'app/content/UPDATE';
const CHANGE_PAGE = 'app/content/CHANGE_PAGE';

export const fetchPaymentStats = (id = null) => createAction(FETCH_PAYMENT_STATS, { id });

const reset = (key, value) => createAction(RESET, { key, value });
const update = (key, value) => createAction(UPDATE, { key, value });

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);
export const resetPaymentStats = (payment_stats) => reset('payment_stats', payment_stats);
export const resetQuery = (query) => reset('query', query);

export const changePage = (page = 1) => createAction(CHANGE_PAGE, { page });
const setContentId = (id) => reset('id', id);

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
  id: undefined,
  payment_stats: [],
  meta: { current_page: 1, last_page: 1 },
  filter: {},
  query: {},
};

export default handleActions({
  [RESET]: (state, action) => {
    return { ...state, [action.payload.key]: action.payload.value }
  },
  [UPDATE]: (state, action) => {
    return { ...state, [action.payload.key]: { ...state[action.payload.key], ...action.payload.value } }
  },
  [CHANGE_PAGE]: (state, action) => {
    return { ...state, meta: { ...state.meta, current_page: action.payload.page } }
  },
},
  initialState
)

export const getPaymentStats = state => getPaymentStatsStore(state).payment_stats;
export const getPaymentStatsFilter = state => getPaymentStatsStore(state).filter;
export const getPaymentStatsQuery = state => getPaymentStatsStore(state).query;
export const getPaymentStatsMeta = state => getPaymentStatsStore(state).meta;

function* callFetchPaymentStats() {
  let query = { ...yield select(getPaymentStatsFilter), ...yield select(getPaymentStatsQuery) };
  const meta = yield select(getPaymentStatsMeta);

  const response = yield fetchApi(API.payment_stats(), {
    per_page: 1000,
    page: meta.current_page,
    ...query
  }, 'get');
  const payment_stats = response.data.data;
  yield put(resetMeta(response.data.meta));
}

export function* paymentStatsSaga() {
  yield takeEvery(FETCH_PAYMENT_STATS, callFetchPaymentStats);
}