import React from 'react';
import {Controller} from "react-hook-form";
import {Form as SFrom} from "semantic-ui-react";

export default function Form ({methods, onSubmit, ...props}){

    function register_element(element){
        if(element.type.name === "FormCheckbox"){

            const Element = ({onChange, checked, ...props}) => {
                return React.createElement(element.type, {...element.props, checked: !!checked, onClick: (e, d) => {
                    onChange(d.checked)
                    }})
            }

            const controller = <Controller
                name={element.props.name}
                as={Element}
                control={methods.control}
            />;
            return controller
        }
        return <Controller name={element.props.name} as={React.createElement(element.type, element.props)} control={methods.control}/>
    }

    function register_children(children){
        const array = React.Children.map(children, child => {
            if(child?.props?.name)
            {
                const registerElement = register_element(child);
                return registerElement ?? child
            }
            if(child?.props?.children){
                const children = register_children(child.props.children);
                return React.createElement(child.type, {...child.props, children})
            }
            return child
        });
        return array
    }

    return (
        <SFrom onSubmit={() => onSubmit && onSubmit(methods.getValues())}>
            {register_children(props.children)}
        </SFrom>
    );
}