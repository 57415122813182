import {createAction} from "../utils";
import {API} from "../api-endpoints";
import {call, put, takeEvery} from "@redux-saga/core/effects";
import {handleActions} from "redux-actions";
import {apiAuthRequest} from "../store/sagas";


const RESET = 'app/activities/RESET';

const FETCH_SAGA = 'app/activities/FETCH_SAGA';



export const resetActivities = (activities) => createAction(RESET, {activities});
export const fetchActivities = () => createAction(FETCH_SAGA);



const initialState = [];

export default handleActions({
        [RESET]: (permissions, action) => {
            return action.payload.activities;
        }
    },
    initialState,
);



function* callFetchActivities() {
    const response = yield call(apiAuthRequest, API.GET_ACTIVITIES, {per_page: 100});
    yield put(resetActivities(response.data.data));
}

export function* activitiesSaga() {
    yield takeEvery(FETCH_SAGA, callFetchActivities);
}
