import React, {useContext, useState} from "react";
import {Header, Loader, Segment, TransitionablePortal} from "semantic-ui-react";

export const PageLoaderContext = React.createContext({loading: false, setLoading: undefined});
export const PageLoaderProvider = ({children}) => {
    const [loading, setLoading] = useState(false);
    return <PageLoaderContext.Provider value={{loading, setLoading}}>
        {children}
        <TransitionablePortal open={loading}>
            <Segment
                style={{ right: '5px', position: 'fixed', bottom: '5px', zIndex: 1000 }}
            >
                <Header as={'h4'}><Loader active inline /> Pobieranie danych </Header>
            </Segment>
        </TransitionablePortal>
    </PageLoaderContext.Provider>
};

export const usePageLoader = () => {
    const {setLoading} = useContext(PageLoaderContext);
    return {setLoading}
}