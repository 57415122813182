import React from 'react';
import {Field, reduxForm} from "redux-form";
import {Button, Form} from "semantic-ui-react";
import {InputField} from "react-semantic-redux-form";
import {useDispatch, useSelector} from "react-redux";
import {getAdmin, submitForm} from "../../modules/app";
import {api_url} from "../../utils";

export const ProfileSettingsForm = (props) => {
    const {form, submitFailed, submitting} = props;
    const {id} = useSelector(getAdmin);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        dispatch(submitForm(form, api_url('admin', id), 'put'));
    };

    return <Form autoComplete="off" error={submitFailed} loading={submitting} onSubmit={handleSubmit}>
            <Field name={'password'} component={InputField} label={'Hasło'}/>
            <Field name={'password_confirmation'} component={InputField} label={'Powtórz hasło'}/>
            <Button type={'submit'} content={'Zapisz'}/>
    </Form>
};

export const ProfileSettingsReduxForm = reduxForm({
    form: 'settings-profile-form',
    enableReinitialize: true,
})(ProfileSettingsForm);