import {handleActions} from "redux-actions";
import {createAction} from "../utils";
import {getVerifyPhotosStore} from "../store/createRootReducer";
import {put, takeEvery} from "@redux-saga/core/effects";

import {fetchApi} from "./app";
import {API} from "../api-endpoints";
import {get_blob} from "./photos";
import ApiQuery from "../ApiQuery";

const RESET = 'app/verify_photos/RESET';
const UPDATE = 'app/verify_photos/UPDATE';
const API_GET = 'app/verify_photos/API_GET';
const API_UPDATE = 'app/verify_photos/API_UPDATE';

export const reset = (key, value) => createAction(RESET, {key, value});
export const update = (key, value) => createAction(UPDATE, {key, value});
export const api_get = (id=0, body={}) => createAction(API_GET, {id, body});
export const api_update = (id, body={}, onSuccess=null) => createAction(API_UPDATE, {id, body, onSuccess});

const resetVerifyPhotos = (data) => reset('data', data);
const resetMeta = (data) => reset('meta', data);


const initialState = {
    data: [],
    meta: {},
};

export default handleActions({
    [RESET]: (state, action) => {
        return {...state, [action.payload.key]: action.payload.value}
    },
    [UPDATE]: (state, action) => {
        return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
    },
}, initialState)

export const getVerifyPhotos = state => getVerifyPhotosStore(state).data;
export const getPagination = state => getVerifyPhotosStore(state).meta;



function* callApiGet(action) {
    const {id, body} = action.payload;

    const {page, per_page, ...query} = body;

    const response = yield fetchApi(new ApiQuery({baseURL: 'api/admin'}).for('verify_photos').where(query).page(page).limit(per_page).get(), {}, 'get');

    const photos = response.data.data;

    for (let i = 0; i < photos.length; i++) {
        photos[i].uploaded_image = yield get_blob(photos[i].uploaded_image);
        photos[i].gesture_image = yield get_blob(photos[i].gesture_image);
        photos[i].main_photo = yield get_blob(photos[i].main_photo);
    }

    yield put(resetVerifyPhotos(photos));
    yield put(resetMeta(response.data.meta));
}

function* callApiUpdate(action) {
    const {id, body, onSuccess} = action.payload;

    yield fetchApi(API.verify_photos(id), body, 'put');
    if(onSuccess)
        onSuccess();
}

export function* verifyPhotosSaga() {
    yield takeEvery(API_GET, callApiGet);
    yield takeEvery(API_UPDATE, callApiUpdate);
}

