import axios from '../axios-interceptor';
import {AxiosRequestConfig, Method} from "axios";
import {apiQuery} from "../utils";
import {useMutation, useQuery, useQueryCache} from "react-query";
import {PaginatedRequest} from "./core";
import {buildRequest, photoRequest, request, RestResponse} from "./requests";
import {MutationConfig} from "react-query/types/core/types";
import {useState} from "react";

export type PhotoSize =
    | 'small'
    | 'big'
    | 'original'

interface CropData {
    x: number, y: number, height: number, width: number
}

interface PhotosRequestData extends PaginatedRequest{
    filter?: {
        user_id?: number,
        private?: boolean,
    }
}

export const fetchPhotosRequest = async ({config, data}:  {config?: AxiosRequestConfig, data?: PhotosRequestData}) => {
    const res = await axios({
        method: 'get',
        ...config,
        // @ts-ignore
        url: apiQuery().for('photos').where(data?.filter).page(data?.page).sort(data?.sort).limit(data?.per_page).get()
    })
    return res.data
}


interface PhotoRequestData {

}

export const downloadPhotoRequest = async ({config, data, id, size, cropped=1}:  {id: number, config?: AxiosRequestConfig, data?: PhotoRequestData, size?: PhotoSize, cropped?: number}) => {
    const res = await axios({
        method: 'get',
        ...config,
        // @ts-ignore
        url: apiQuery().for('photo_base_64/'+id).params({
            size,
            cropped
        }).get()
    })
    return res.data
}

export const useDownloadPhoto = (id: number, photoSize: PhotoSize, cropped = 1) => {

    const size = photoSize ?? 'big'
    const api = useQuery({
        queryKey: ['photos-base64', id, {size, cropped}],
        queryFn: () => downloadPhotoRequest({id, size, cropped }),
        config: {
            staleTime: 60*1000,
            enabled: id
        }
    });

    return api;
}

export const usePhotosApi = (id: number) => {
    const queryCache = useQueryCache();
    const [mutate, state] = useMutation(photoRequest, {
        onSuccess: () => {
            queryCache.invalidateQueries(['photos'])
        }
    });

    const [mutate_, state_] = useMutation(request, {
        onSuccess: () => {
            queryCache.invalidateQueries(['photos'])
        }
    });

    const withEntityId = (data: any, {action, uri_action, method} : {action?: string, uri_action?: string, method?: Method} = {}) => () => mutate({id, action, data, method: method ?? 'patch', uri_action})

    return {
        approve: () => withEntityId({status: true})().then(() =>
            queryCache.invalidateQueries('users')
        ),
        reject: () => withEntityId({status: false})().then(() =>
            queryCache.invalidateQueries('users')
        ),
        setPrivate: () => withEntityId({private: 1})().then(() =>
            queryCache.invalidateQueries('users')
        ),
        setPublic: () => withEntityId({private: 0})().then(() =>
            queryCache.invalidateQueries('users')
        ),
        rotate: (rotation: number) => mutate({id,  data: {rotation}, action: 'rotate'}, {onSuccess: () => queryCache.invalidateQueries(['photos-base64', id])}),
        crop: (crop: any) => mutate({id,  data: {crop}}, {onSuccess: () => queryCache.invalidateQueries(['photos-base64', id])}),
        edit: (data: any) => mutate({id, data}, {onSuccess: () => {
                queryCache.invalidateQueries(['photos-base64', id])
                queryCache.invalidateQueries('users')
            }}),
        cropOriginalPhoto: (cropData: CropData) => {
            withEntityId(cropData, {uri_action: 'cropPhoto'})().then(() => {
                queryCache.invalidateQueries(['photos-base64', id])
            })
        },
        delete: withEntityId(undefined, {method: "delete"}),
        ...state,
        setAsProfilePhoto(photo: any) {
            withEntityId({}, {uri_action: 'setAsProfile', method: "get"})().then(() => {
                queryCache
                    .invalidateQueries(['photos-base64', id])
                queryCache.invalidateQueries('users')
            })
        }
    }
}


export const usePhotoApi = () => {
    const queryCache = useQueryCache();
    const [mutate, state] = useMutation(photoRequest, {
        onSuccess: () => {
            queryCache.invalidateQueries(['photos'])
        }
    });

    const withEntityId = ({data, action, config, method} : { data?: any, action?: string, method?: Method, config?: (id: number) => MutationConfig<any> }) => (id: number) => mutate({id, action, data, method: method ?? 'patch'}, config?.(id))

    return {
        approve: withEntityId({data: {status: true}}),
        reject: withEntityId({data: {status: false}}),
        delete: withEntityId({method: "DELETE"}),
        rotate: (rotation: number) => withEntityId({ data: {rotation}, action: 'rotate', config: (id) => ({
                onSuccess: () => queryCache.invalidateQueries(['photos-base64', id])
            })}),
        crop: (crop: any) => withEntityId({data: {crop}, config: (id) => ({
                onSuccess: () => queryCache.invalidateQueries(['photos-base64', id])
            })}),
        ...state
    }
}

type PhotosApiMethods = ReturnType <typeof usePhotosApi>;

export const PhotosApiProvider = ({id, render} : {id: number, render: (api: PhotosApiMethods) => any}) => {
    const photosApi = usePhotosApi(id);
    return render(photosApi)
}

export const useFetchPhoto = (photo_id=0) => {
    const [id, setId] = useState(photo_id);
    const query = useQuery<RestResponse>(['photos', {id}], buildRequest({method: "get", url: ["photos", id]}));

    return {
        ...query,
        photo: query.data?.data?.data ?? {}
    }
}
