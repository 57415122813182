import {useApi} from "./components/hooks";
import {api_url} from "./utils";

export const useMailReceiversCount = () => useApi(api_url('admin/mailManager/receivers'), 'post');
export const useApiSendMails = () => useApi(api_url('admin/mailManager'), 'post');
export const useApiSendVerificationMails = () => useApi(api_url('admin/mailManager/verification'), 'post');

export const useApiCreateCustomFilter = () => useApi(api_url('admin/customFilter'), 'post');
export const useApiUpdateCustomFilter = (id) => useApi(api_url(`admin/customFilter/${id}`), 'put');
export const useApiShowCustomFilters = () => useApi(api_url('admin/customFilter'), 'get');
export const useApiDeleteCustomFilter = (id) => useApi(api_url(`admin/customFilter/${id}`), 'delete');
