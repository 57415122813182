import {handleActions} from "redux-actions";
import {put, select, takeEvery} from "redux-saga/effects";
import {goBack} from "react-router-redux";
import {createAction} from "../utils";
import {getContentStore} from "../store/createRootReducer";
import {API} from "../api-endpoints";
import {fetchApi} from "./app";
import {getFormValues, startSubmit} from "redux-form";

const DELETE_CONTENT = 'app/content/DELETE_CONTENT';
const FETCH_CONTENTS = 'app/content/FETCH_CONTENTS';
const FETCH_LOCATION_DATA = 'app/content/FETCH_LOCATION_DATA';

const SUBMIT_UPDATE_CONTENT = 'app/content/SUBMIT_UPDATE_CONTENT';

const RESET = 'app/content/RESET';
const UPDATE = 'app/content/UPDATE';
const CHANGE_PAGE = 'app/content/CHANGE_PAGE';

export const submitContent = (form) => createAction(SUBMIT_UPDATE_CONTENT, {form});
export const fetchContents = (id=null) => createAction(FETCH_CONTENTS, {id});
export const fetchLocationData = () => createAction(FETCH_LOCATION_DATA, {});
export const deleteContent = (id) => createAction(DELETE_CONTENT, {id});

const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);
export const resetContent = (content) => reset('content', content);
export const resetContents = (contents) => reset('contents', contents);
export const resetQuery = (query) => reset('query', query);

export const changePage = (page=1) => createAction(CHANGE_PAGE, {page});
const setContentId = (id) => reset('id', id);

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
    id: undefined,
    content: {},
    contents: [],
    meta: {current_page: 1, last_page: 1},
    filter: {},
    query: {},
    country: null,
    country_priority: 0,
    region: null,
    region_priority: 0,
    city: null,
    city_priority: 0,
    locationData: {
        cities: [],
        regions: [],
        countries: []
    },
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
        [CHANGE_PAGE]: (state, action) => {
            return {...state, meta: {...state.meta, current_page: action.payload.page}}
        },
    },
    initialState
)

export const getContents = state => getContentStore(state).contents;
export const getContentFilter = state => getContentStore(state).filter;
export const getContentQuery = state => getContentStore(state).query;
export const getContentMeta = state => getContentStore(state).meta;
export const getContent = state => getContentStore(state).content;
export const getContentId = state => getContentStore(state).id;
export const getContentCountries = state => getContentStore(state).locationData?.countries;
export const getContentRegions = state => getContentStore(state).locationData?.regions;
export const getContentCities = state => getContentStore(state).locationData?.cities;

function* callFetchContents(action) {
    const {id} = action.payload;
    let query = {...yield select(getContentFilter), ...yield select(getContentQuery)};
    const meta = yield select(getContentMeta);

    const response = yield fetchApi(API.content(id), {
        per_page: 1000,
        page: meta.current_page,
        ...query
    }, 'get');

    if(id){
        yield put(setContentId(id));
        const content = response.data.data;
        yield put(resetContent(content));
    }else {
        const contents = response.data.data;
        yield put(resetContents(contents));
        yield put(resetMeta(response.data.meta));
    }
}

function* callFetchLocationData() {
    const response = yield fetchApi(API.contentLocationData(), {}, 'get');
    if (response?.status === 200) {
        yield put(update('locationData', response.data));
    }
}

function* callUpdateContent(action) {
    const content = action.payload.content;

    try {
        yield fetchApi(API.content(content.id), content, 'patch');
        yield put(fetchContents());
        yield put(goBack())
    } catch (e) {
        console.error(e);
    }
}

function* callSubmitUpdateContent(action){
    const {form} = action.payload;
    const values = yield select(getFormValues(form));
    yield put(startSubmit(form));

    try {
        yield callUpdateContent({payload: {content: values}})
    } catch (e) {
        console.error(e);
    }
}

export function* contentsSaga() {
    yield takeEvery(FETCH_CONTENTS, callFetchContents);
    yield takeEvery(FETCH_LOCATION_DATA, callFetchLocationData);
    yield takeEvery(SUBMIT_UPDATE_CONTENT, callSubmitUpdateContent);
}
