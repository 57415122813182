import {useEffect, useRef} from "react";
import {useRequest} from "./core";
import {useParams} from "react-router";
import {resetOrders} from "../modules/orders";
import {resetUser} from "../modules/user";
import ApiQuery from "../ApiQuery";

export default function useUserApi (user_id=undefined) {
    const {id} = useParams();
    const user_id_ = user_id ? user_id : id;

    const _url = (url='') => `api/admin/users/${user_id}/${url}`;

    const deleteUser = useRequest({url: _url(), method: 'DELETE'})
    const editUser = useRequest({url: _url(), method: 'PATCH'})
    const givePremium = useGiveUserPremium(user_id_);

    return {
        deleteUser,
        editUser,
        givePremium,
        fetchOrders: useFetchUserOrders(user_id_),
        ban: useBanUser(user_id_),
        fetchVerificationPhotos: useFetchVerificationPhotos(user_id_),
        user_id: user_id_
    }
}

export const useGiveUserPremium = (user_id) => useRequest(
    {
        url: `api/admin/orders`,
        method: 'POST',
        data: {user_id}
    },
    undefined,
    undefined,
);

export const useFetchUserOrders = (user_id) => {
    const ref = useRef({loading: 0});

    const api = useRequest(
        {
            url: `api/admin/users/${user_id}/orders`,
            method: 'GET',
        },
        (response, {dispatch, userApi}) => {
            dispatch(resetOrders(response.data.data))
        },
    )

    useEffect( () => {
        if( api.loading )
            ref.current.loading++;
        else
            ref.current.loading--;

        if(ref.current.loading < 0){
            ref.current.loading = 0;
        }

    }, [api.loading])

    return {...api, globalLoading: ref.current.loading}
}

export const useBanUser = (user_id) => useRequest(
    {
        url: `api/admin/users/${user_id}`,
        method: 'PATCH',
        data: {
            action: 'ban',
            ban_type: undefined
        }
    },
    (response, {dispatch, queryCache}) => {
        dispatch(resetUser(response.data.data))
        queryCache.invalidateQueries(['users', `${user_id}`])
    }
)

export const useFetchVerificationPhotos = (user_id) => useRequest({
    url: new ApiQuery({baseURL: 'api/admin'}).for('verify_photos').where('user_id', user_id).where('status', [0,1]).get(),
    method: 'GET'
})