import {Button} from "semantic-ui-react";
import React from "react";
import {ButtonSetFilter} from "../../pages/comments";

export const Filter = ({filters, actual_filter, onChange}) => {
    return <Button.Content>
        {filters.map((filter) => (
            <ButtonSetFilter key={filter[0]} content={filter[0]} filter={filter[1]} actual_filter={actual_filter} setFilter={onChange}/>
        ))}
    </Button.Content>
};