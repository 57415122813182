import {api_call, getData} from "./main_module";
import {api_url} from "../utils";

export const REPORTED_USERS = 'REPORTED_USERS';
export const REPORTS = 'REPORTS';

export const fetchReportedUsers = (query={}) => {
    return api_call(REPORTED_USERS, 'get', api_url('admin/reportedUsers'), {...query});
}

export const fetchReportsOfUser = (user_id, query) =>
    api_call(REPORTS, 'get', api_url('admin/users', user_id), {action: 'reports', ...query})

export const removeAutoBan = (user_id) =>
    api_call(REPORTS, 'put', api_url('admin/users', user_id), {action: 'remove_auto_ban'}, {onSuccess: () => window.location.reload()})

export const checkReport = (report_id, onSuccess) =>
    api_call(REPORTS, 'put', api_url('admin/reports', report_id), {action: 'check'}, {onSuccess})

export const getReportedUsers = getData(REPORTED_USERS);
export const getReports = getData(REPORTS);