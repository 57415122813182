import React from "react";
import { Container, Divider, Header } from "semantic-ui-react";
import { Pagination } from "../../components/tables/pagination";
import { useCustomPaginatedQuery } from "../../api/core";
import { paymentStatsRequest } from "../../api/requests";
import { TableWithWrapperView } from "../../components/stateless/Table";

export const PaymentStatsList = ({ payment_stats }) => {
  return <TableWithWrapperView data={payment_stats} columns={{
    'date': {
      name: '-'
    },
    'pln': {
      name: 'PLN',
      wrapper: (data) => (data.values.PLN/100.0 + 'zł')
    },
    'eur': {
      name: 'EUR',
      wrapper: (data) => (data.values.EUR/100.0 + '€')
    },
    'gbp': {
      name: 'GBP',
      wrapper: (data) => (data.values.GBP/100.0 + '£')
    },
    'usd': {
      name: 'USD',
      wrapper: (data) => (data.values.USD/100.0 + '$')
    }
  }} />
};

export const PaymentStatsView = ({ payment_stats, pagination, onChangePage }) => {
  const handlePageChange = page => {
    onChangePage(page)
  }

  return <>
    <PaymentStatsList payment_stats={payment_stats} />
    <Pagination pagination={pagination} onChange={handlePageChange} />
  </>
};

export const PaymentStats = () => {
  const { data: payment_stats, setPage, pagination } = useCustomPaginatedQuery('payment_stats', paymentStatsRequest, {
    initConfig: { perPage: 1000 }
  });

  const changePageAndFetch = (page) => {
    setPage(page)
  };

  return <Container style={{ width: '50%' }}>
    <Divider hidden />
    <Header as='h2'>
      Statystyki płatności
    </Header>

    <PaymentStatsView
      payment_stats={payment_stats}
      pagination={pagination}
      onChangePage={changePageAndFetch}
    />
  </Container>
};

export default PaymentStats;