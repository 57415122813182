import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Divider, Header, Image, Input, Pagination, Popup, Segment } from "semantic-ui-react";
import Moment from 'moment';
import { SelectableTableFromArray } from "../../components/stateless/Table";
import * as MessagesModule from "../../modules/messages";
import { changePage, fetchMessages, getMessages, getMessagesMeta, setFilter, resetMessagesQuery } from "../../modules/messages";
import { Link } from "react-router-dom";
import { ROUTE } from "../../route-names";
import { UserName } from "../../components/User/UserName";
import { api_url } from "../../utils";
import { request } from "../../modules/app";
import { useParams } from "react-router";
import {useAppRouter} from "../../hooks/router";

export const are_filters_equal = (filter1, filter2) => {
  return (filter1 && filter2 &&
    filter2[Object.keys(filter1)[0]] === filter1[Object.keys(filter1)[0]] &&
    Object.keys(filter1).length
  ) &&
    (
      Object.keys(filter1).length !== 0
    );
}

export const ButtonSetFilter = ({ actual_filter, setFilter, filter, content }) => (
  <Button size='mini' id='filter_messages' color={are_filters_equal(filter, actual_filter) ? 'green' : null} floated='center' onClick={() => setFilter(filter)} content={content} />
);

const Filter = () => {
  const dispatch = useDispatch();
  const actual_filter = useSelector(MessagesModule.getMessagesFilter);

  const filters = [
    ['Wszystkie', MessagesModule.filters.all],
    ['Spam', MessagesModule.filters.spam],
    ['Usunięte', MessagesModule.filters.deleted],
    ['Dane kontaktowe?', MessagesModule.filters.contact_data],
  ];

  const setFilterAndFetchMessages = (filter) => {
    dispatch(setFilter(filter));
    dispatch(changePage(1));
    dispatch(fetchMessages());
  };

  return <Button.Group floated={'left'}>
    {filters.map((filter) => (
      <ButtonSetFilter content={filter[0]} actual_filter={actual_filter} filter={filter[1]} setFilter={setFilterAndFetchMessages} />
    ))}
  </Button.Group>
};

const setUserIdParam = (dispatch) => {
  dispatch(setFilter({}));
}

const MessagesSection = ({ messages }) => {

  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
 
  const update_multiple_messages = (ids, field, value) => {
    setLoading(true)
    request('post', api_url(`admin/messages/updateMultiple`), { ids, field, value }).then(res => {
      dispatch(fetchMessages())
    })
  }

  useEffect(() => {
    setLoading(false)
    setSelected([]);
  }, [messages])

  const DeleteSelectedButton = useCallback(() =>
    <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Usuń (${selected.length})`} />}>
      <div>Czy na pewno usunąć wybrane wiadomości?</div>
      <Button onClick={() => {
        const foundReadMessage = selected.some(messageId => {
          const message = messages.find(message => message.id == messageId);
          if (message && message.is_read)
            return true;
          return false
        });

        if (foundReadMessage)
          alert('Co najmniej jedna z zaznaczonych wiadomości do usunięcia została już przeczytana przez odbiorcę. Nie można jej usunąć.');
        else
          update_multiple_messages(selected, 'deleted_by_admin', true);

      }} content={'Usuń'} loading={loading} disabled={loading} />
    </Popup>
    , [messages, selected, loading])

  // const SetAsSpamButton = useCallback(() =>
  //   <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Spam (${selected.length})`} />}>
  //     <div>Czy na pewno wybrane wiadomości odznaczyć jako spam?</div>
  //     <Button onClick={() => {
  //       set_as_spam(selected);
  //     }} content={'Odznacz jako spam'} loading={loading} disabled={loading} />
  //   </Popup>
  //   , [messages, selected, loading])

  const SetAsNotSpamButton = useCallback(() =>
    <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Poprawne (${selected.length})`} />}>
      <div>Czy na pewno wybrane wiadomości odznaczyć jako poprawne?</div>
      <Button onClick={() => {
        update_multiple_messages(selected, 'spam', false);
      }} content={'Odznacz jako poprawne'} loading={loading} disabled={loading} />
    </Popup>
    , [messages, selected, loading])

  return <>
    <SetAsNotSpamButton />
    <DeleteSelectedButton />
    <SelectableTableFromArray selected={selected} setSelected={setSelected} data={messages} warningConditionField={'spam'} warningConditionValue={1}
      grayedConditionField={'deleted_by_recipient'} grayedConditionValue={1} columns={[
        ['Autor', (data) => {
          return <>
            <UserName user={data.sender} gender banned deleted />
            <div>
              {data.deleted_by_sender ? <span style={{ color: 'red' }}>(USUNIĘTO)</span> : ''}
              {data.deleted_by_admin ? <span style={{ color: 'green' }}> (del admin)</span> : ''}
              {data.deleted_by_automat ? <span style={{ color: 'blue' }}> (del automat)</span> : ''}
            </div>
            { !params.id ? <div><Link to={ROUTE.MESSAGES_FOR_USER_URL(data.sender.id)} onClick={ ()=> setUserIdParam(dispatch) } style={{color: '#000'}}>(Pokaż)</Link></div> : '' }
          </>
        }, '230px'],
        ['Odbiorca', (data) => <>
          <UserName user={data.recipient} gender banned deleted />
          <div>
            {data.deleted_by_recipient ? <span style={{ color: 'red' }}>(USUNIĘTO)</span> : ''}
            {data.deleted_by_admin ? <span style={{ color: 'green' }}> (del admin)</span> : ''}
            {data.deleted_by_automat ? <span style={{ color: 'blue' }}> (del automat)</span> : ''}
          </div>
        </>, '230px'],
        ['Treść', (data) => {
          return <>{data.body}
            {data.is_read ? <Image src={process.env.PUBLIC_URL + '/is_read.png'} style={{ marginLeft: '5px', width: '19px', height: 'auto' }} avatar /> : ''}
          </>
        }],
        ['Data', (data) => (Moment(data.created_at).format('Y-MM-DD HH:mm') + ` (${data.id})`), '230px']
      ]} />
  </>
};

export const MessagesDataTableView = () => {
  let messages = useSelector(getMessages);
  const meta = useSelector(getMessagesMeta);
  const dispatch = useDispatch();
  const appRouter = useAppRouter();

  const setPageAndFetchMessages = (page) => {
    dispatch(changePage(page));
    dispatch(fetchMessages());
  };

  let searchText = '';
  const onSearchTextChange = (e, {name, value}) => {
      searchText = value;
  };

  const searchClick = () => {
      if(searchText.length > 0) {
          dispatch(setFilter({}))
          appRouter.goToMessagesSearch(searchText);
      }
  }

  return <>
    <MessagesSection messages={messages} />
    <Divider />
    <Segment basic textAlign={"center"}>

      <Pagination activePage={meta.current_page}
        totalPages={meta.last_page}
        onPageChange={(e, { activePage }) => setPageAndFetchMessages(activePage)}
      />
    </Segment>
    <Segment>
      <Input onChange={onSearchTextChange} />
      <Button onClick={searchClick} content={'Szukaj'} style={{marginLeft: '10px'}}/>
    </Segment>
  </>
};

export const MessagesView = () => {
  const params = useParams();
  const meta = useSelector(getMessagesMeta);

  let title;
  let showFilters = !(params.id || params.body);
  if (params.id)
    title = `Wiadomości użytkownika id = ${params.id}`;
  else if (params.body && params.body.length > 0)
    title = `Wiadomości użytkowników  - treść: ${params.body}`;
  else
    title = 'Wiadomości użytkowników';

  return <Container style={{ width: '99%' }}>
    <Divider hidden />
    <Header as='h2'>
      { title }
    </Header>
    {meta.total ? <div>Razem: {meta.total}</div> : ''}
    <Divider />
    {showFilters ?
      <div>
        <Filter />
        <Divider hidden />
      </div>
      : ''}
    
    <MessagesDataTableView />
  </Container>;
};

export const Messages = () => {
  const dispatch = useDispatch();
  const {id, body} = useParams();

  useEffect(()=>{
    dispatch(resetMessagesQuery({user_id: id, body: body}));
    dispatch(changePage(1));
    dispatch(fetchMessages());
  }, [id, body, dispatch]);

  return <MessagesView />
};
