import {createAction} from "../utils";
import {API} from "../api-endpoints";
import {call, put, takeEvery} from "@redux-saga/core/effects";
import {handleActions} from "redux-actions";
import {apiAuthRequest} from "../store/sagas";


const RESET = 'app/permissions/RESET';

const FETCH_SAGA = 'app/permissions/FETCH_SAGA';



export const resetPermissions = (permissions) => createAction(RESET, {permissions});
export const fetchPermissions = () => createAction(FETCH_SAGA);



const initialState = [];

export default handleActions({
        [RESET]: (permissions, action) => {
            return action.payload.permissions;
        }
    },
    initialState,
);



function* callFetchPermissions() {
    const response = yield call(apiAuthRequest, API.GET_PERMISSIONS);
    yield put(resetPermissions(response.data));
}

export function* permissionsSaga() {
    yield takeEvery(FETCH_SAGA, callFetchPermissions);
}
