import React, {useEffect} from "react";
import {Pagination, Segment} from "semantic-ui-react";
import {UsersGroupTable} from "./components/table-users";
import {useGetUsersGroups} from "../../api/users";
import {NumberParam, useQueryParams, withDefault} from "use-query-params";


export const UsersGroups = () => {
    const usersQuery = useGetUsersGroups();

    const [queryUrl, setQueryUrl] = useQueryParams({
        page: withDefault(NumberParam, 1),
    });

    useEffect( () => {
        if(usersQuery.page !== queryUrl.page){
            usersQuery.setPage(queryUrl.page);
        }
    }, [queryUrl])

    const setPageAndFetch = (page) => {
        usersQuery.setPage(page)
        setQueryUrl({page})
    };

    return <>

        <UsersGroupTable groups={usersQuery.groups}/>

        <Segment basic textAlign={"center"}>

            <Pagination activePage={usersQuery.page}
                        totalPages={usersQuery.data?.data?.last_page}
                        onPageChange={(e, { activePage })=>setPageAndFetch(activePage)}
            />
        </Segment>

    </>
};
