import React from 'react'
import {ChatList, ChatListItem, Column, Row, Subtitle, Title} from '@livechat/ui-kit'

export const ThreadsView = ({data, active_thread_id, onSelectActiveThread}) => (
    <ChatList>
        {data.map(function ({id, subject, last_message: {created_at, body}}) {
            return <ChatListItem active={id === active_thread_id} onClick={()=>onSelectActiveThread(id)}>
                <Column fill>
                    <Row justify>
                        <Title ellipsis>{subject}</Title>
                        <Subtitle nowrap>{created_at}</Subtitle>
                    </Row>
                    <Subtitle ellipsis>
                        {body}
                    </Subtitle>
                </Column>
            </ChatListItem>
        })}
    </ChatList>
);