import React from 'react';
import {Container} from "semantic-ui-react";
import {OrderForm} from "../../components/forms/order-form";

export default class CreateOrderComponent extends React.Component{

    render() {
        return <Container>
            <OrderForm new={true}/>
        </Container>
    }
}

