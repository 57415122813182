import {createAction} from "../utils";
import {handleActions} from "redux-actions";
import {getMessagesStore} from "../store/createRootReducer";
import {put, select, takeEvery} from "@redux-saga/core/effects";
import {fetchApi} from "./app";
import {API} from "../api-endpoints";

const FETCH_MESSAGES = 'app/messages/FETCH_MESSAGES';
const RESET = 'app/messages/RESET';
const UPDATE = 'app/messages/UPDATE';

export const fetchMessages = () => createAction(FETCH_MESSAGES);
const reset = (key, value) => createAction(RESET, {key, value});
const update = (key, value) => createAction(UPDATE, {key, value});

export const updateMeta = (meta) => update('meta', meta);
export const resetMeta = (meta) => reset('meta', meta);

export const resetMessagesQuery = (query) => reset('query', query);

export const changePage = (page) => updateMeta({current_page: page});

export const filters = {
    all: {},
    spam: {
        'spam': 1,
        'deleted_by_admin': 0
    },
    deleted: {
        'deleted': 1
    },
    contact_data: {
        'contact_data': 1
    }
};

export const setFilter = (filter) => reset('filter', filter);

const initialState = {
    id: undefined,
    messages: [],
    meta: {current_page: 1, last_page: 1, per_page: 100},
    filter: filters.all,
    query: {}
};

export default handleActions({
        [RESET]: (state, action) => {
            return {...state, [action.payload.key]: action.payload.value}
        },
        [UPDATE]: (state, action) => {    
            return {...state, [action.payload.key]: {...state[action.payload.key], ...action.payload.value}}
        },
    },
    initialState
)

export const getMessages = (state) => getMessagesStore(state).messages;
export const getMessagesMeta = (state) => getMessagesStore(state).meta;
export const getMessagesQuery = (state) => getMessagesStore(state).query;
export const getMessagesFilter = (state) => getMessagesStore(state).filter;

export function* callFetchMessages() {

    let filter = yield select(getMessagesFilter);
    let {current_page: page, per_page} = yield select(getMessagesMeta);

    const query = yield select(getMessagesQuery);
    const response = yield fetchApi(API.messages(), {...filter, page, per_page, ...query}, 'get');
    yield put(reset('messages', response.data.data));
    yield put(resetMeta(response.data.meta));
}

export function* messagesSaga() {
    yield takeEvery(FETCH_MESSAGES, callFetchMessages);
}