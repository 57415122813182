import React, {Suspense} from 'react'
import {Provider} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import {history} from './store/configure-store'
import {ROUTE} from "./route-names";
import * as Page from './pages';
import * as Menu from './components/menus';
import {Redirect} from "react-router";
import _ from "lodash";
import useUserApi from "./api/user";
import {QueryCache, ReactQueryCacheProvider, useQueryCache} from "react-query";
import {ReactQueryDevtools} from 'react-query-devtools'
import {QueryParamProvider, transformSearchStringJsonSafe} from "use-query-params";
import {PageLoaderProvider} from "./components/page/loader";

const queryCache = new QueryCache()

export const ApiContext = React.createContext();

const ApiProvider = ({children}) => {
    const userApi = useUserApi();
    return <ApiContext.Provider value={userApi}>
        {children}
    </ApiContext.Provider>
}

const stringifyOptions = {
    encode: true,
    transformSearchString: transformSearchStringJsonSafe,
};

const RootProvider = ({children}) =>
    <PageLoaderProvider>
        <ReactQueryCacheProvider queryCache={queryCache}>
            {children}
            <ReactQueryDevtools initialIsOpen />
        </ReactQueryCacheProvider>
    </PageLoaderProvider>


const RootComponent = () => {
    window.queryCache = useQueryCache();
    return <span/>
}

const Root = ({ store }) => {

    global._ = _;
    return <RootProvider>
        <Suspense fallback="loading">
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <QueryParamProvider ReactRouterRoute={Route} stringifyOptions={stringifyOptions}>
                        <ApiProvider>
                            <RootComponent/>
                            <Switch>
                                <Route path={ROUTE.LOGIN} component={Page.Login}/>
                                <Menu.MainMenu>
                                    <Switch>
                                        <Route path={'/404'} component={Page.GenericNotFound}/>
                                        <Route exact path={ROUTE.DASHBOARD} component={Page.Dashboard} />

                                        <Route exact path={ROUTE.ADMINS} component={Page.Admins} />

                                        <Route exact path={ROUTE.COMMENTS} component={Page.Comments} />
                                        <Route exact path={ROUTE.COMMENTS_SEARCH} component={Page.Comments} />
                                        <Route exact path={ROUTE.MESSAGES} component={Page.Messages} />
                                        <Route exact path={ROUTE.MESSAGES_FOR_USER} component={Page.Messages} />
                                        <Route exact path={ROUTE.MESSAGES_SEARCH} component={Page.Messages} />

                                        <Route exact path={ROUTE.ADMIN} component={Page.Admin} />
                                        <Route exact path={ROUTE.NEW_ADMIN} component={Page.Admin}/>

                                        <Route exact path={ROUTE.MAILING} component={Page.SentMails}/>
                                        <Route exact path={ROUTE.MAILING_NEW} component={Page.SendMail}/>

                                        <Route exact path={ROUTE.ACTIVITIES} component={Page.Activities} />
                                        <Route exact path={ROUTE.ROLES} component={Page.Roles} />
                                        <Route exact strict path={ROUTE.USERS} component={Page.Users} />
                                        <Route exact path={ROUTE.USER_DESCRIPTIONS} component={Page.UserDescriptions} />
                                        <Route exact path={ROUTE.USERS_PLANS} component={Page.UsersPlans} />

                                        <Route path={ROUTE.PHOTOS_TO_CROP} exact component={Page.PhotosToCropPage}/>
                                        <Route path={ROUTE.PHOTO} exact component={Page.PhotoEdit} />
                                        <Route exact path={ROUTE.PHOTOS} component={Page.Photos} />
                                        <Route exact path={ROUTE.VERIFY_PHOTOS} component={Page.VerifyPhotos} />
                                        <Route exact path={ROUTE.ORDERS} component={Page.Orders} />
                                        <Route exact path={ROUTE.PAYMENT_STATS} component={Page.PaymentStats} />

                                        <Route exact path={ROUTE.PAYMENT_PLANS} component={Page.PaymentPlans} />
                                        <Route exact path={ROUTE.PAYMENT_PLANS_CREATE} component={Page.PaymentPlanCreate} />
                                        <Route exact path={ROUTE.PAYMENT_PLANS_EDIT} component={Page.PaymentPlanEdit} />

                                        <Route exact path={ROUTE.REPORTS} component={Page.ReportsPage} />

                                        <Route exact path={ROUTE.SUGGESTIONS} component={Page.Suggestions} />
                                        <Route exact path={ROUTE.VERIFICATION_MAIL} component={Page.VerificationMail} />

                                        <Route exact path={ROUTE.FILTERS} component={Page.Filters} />

                                        <Route path={ROUTE.CONTENT} exact component={Page.ContentEdit} />
                                        <Route path={ROUTE.CONTENT_BY_LOCATION} exact component={Page.ContentByLocationEdit} />
                                        <Route exact path={ROUTE.CONTENT_NEW} component={Page.ContentNew} />
                                        <Route exact path={ROUTE.CONTENT_BY_LOCATION_NEW} component={Page.ContentByLocationNew} />
                                        <Route exact path={ROUTE.CONTENTS} component={Page.Contents} />
                                        <Route exact path={ROUTE.CONTENTS_BY_LOCATION} component={Page.ContentsByLocation} />

                                        <Route path={ROUTE.USER_CREATE} component={Page.UserCreate}/>

                                        <Route path={ROUTE.USER}>
                                            <Menu.UserMenu>
                                                <Switch>
                                                    <Route exact path={ROUTE.USER} component={Page.UserInfo}/>
                                                    <Route path={ROUTE.USER_EDIT} component={Page.UserEdit}/>
                                                    <Route path={ROUTE.USER_PHOTOS} component={Page.UserPhotos}/>
                                                    <Route exact path={ROUTE.USER_PAYMENTS} component={Page.UserPayments}/>
                                                    <Route path={ROUTE.USER_CHAT} component={Page.UserChat}/>
                                                    <Route path={ROUTE.USER_COMMENTS} component={Page.UserComments}/>
                                                    <Route path={ROUTE.ORDER_CREATE} component={Page.UserOrderCreate}/>
                                                    <Route path={ROUTE.USER_ADVANCED_INFO} component={Page.UserAdvancedInfo}/>
                                                    <Route path={ROUTE.USER_VERIFICATION_PHOTOS} component={Page.UserVerificationPhotos}/>
                                                    <Route path={ROUTE.USER_LOGIN_LOGS} component={Page.UserLoginLogsPage}/>
                                                    {/*<Redirect to='/404'/>*/}
                                                </Switch>
                                            </Menu.UserMenu>
                                        </Route>
                                        {/*<Redirect to='/404'/>*/}
                                    </Switch>
                                </Menu.MainMenu>
                                <Redirect to='/404'/>

                            </Switch>
                        </ApiProvider>
                    </QueryParamProvider>
                </ConnectedRouter>
            </Provider>
        </Suspense>
    </RootProvider>
};

export default Root
