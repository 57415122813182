import React from 'react';
import UserFilterForm, {FormFilterContext} from "../../../components/forms/UserFilterFormOnContext";
import {Button, Form, Label} from "semantic-ui-react";
import {If} from "../../../components/helpers/Condition";

export const UserFilter = () => {
    const { filterData: filter } = React.useContext(FormFilterContext);
    return <>
        <UserFilterForm.Field name={'register_within'} as={
            <Form.Input type={'number'}>
                <Label basic>Minęło od rejestracji mniej niż</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'register_over'} as={
            <Form.Input type={'number'}>
                <Label basic>Minęło od rejestracji więcej niż</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'last_login_within'} as={
            <Form.Input type={'number'}>
                <Label basic>Logował się w ciągu ostatnich</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'last_login_over'} as={
            <Form.Input type={'number'}>
                <Label basic>Nie logował się więcej niż</Label>
                <input/>
                <Label basic>dni</Label>
            </Form.Input>
        }/>
        {' '}
        <UserFilterForm.Field name={'login_exact'} as={
            <Form.Input type={'text'} >
                <Label basic>Login użytkownika</Label>
                <input/>
            </Form.Input>
        }/>
    </>
}