import React from "react";
import * as UsersModule from "../../../modules/users";
import {Filter} from "../../../components/filter-button/filter-button-group";
import {useStateWithOnChangeCallback} from "../../../hooks";

export default function UserFilters({value, onChange}){
    const [actualFilter, setActualFilter] = useStateWithOnChangeCallback(value, onChange);
    return <Filter filters={filters} actual_filter={actualFilter} onChange={setActualFilter}/>
}

const filters = [
    ['Wszyscy', UsersModule.filters.all],
    ['Tylko zweryfikowani', UsersModule.filters.verified],
    ['Tylko niezweryfikowani', UsersModule.filters.unverified],
    ['Ostatnio aktywni', UsersModule.filters.last_online],
    ['Zablokowani', UsersModule.filters.banned],
    ['Ban 24', UsersModule.filters.ban24],
    ['Ban 48', UsersModule.filters.ban48],
    ['Ban 72', UsersModule.filters.ban72],
    ['tylko z Polski', {poland: 1}],
    ['tylko spoza Polski', {poland: 0}],
    ['Standard', {account_type: 'standard'}],
    ['Premium', {account_type: 'premium'}],
    ['Vip', {account_type: 'vip'}],
    ['HOT', {hot_person: 1}],
    ['Podejrzani', {suspect: 1}],
    ['Zaufani', {confidential: 1}],
    ['Połączeni użytkownicy', {has_connected_accounts: 1}],
    ['Zawieszone', {frozen: 'only'}],
    ['Usunięci', UsersModule.filters.deleted],
    ['Niezmienione hasła', UsersModule.filters.unchanged_password],
    ['Zmienione hasła', UsersModule.filters.changed_password],
];