import React, {useEffect} from "react";
import UserForm from "../../components/forms/users-user-form";
import {connect} from "react-redux";
import {fetchUser} from "../../modules/user";
import {getUserStore} from "../../store/createRootReducer";
import {useParams} from "react-router";

const UserEdit = props => {
    const {user, fetchUser} = props;
    const {id} = useParams();
    useEffect(()=>{fetchUser(id)}, [id]);
    return <UserForm initialValues={user}/>
};

export default connect(
    state => ({
        user: getUserStore(state).data
    }),
    {
        fetchUser
    }
)(UserEdit)