import React from "react";
import {useForm} from "react-hook-form";
import {GenderSelect as GenderSelectField} from "../user_filter";
import {Button, Form} from "semantic-ui-react";
import _ from "underscore";

export const FormFilterContext = React.createContext(null)

export function UserFilterForm({children, onSubmit, onChange, filter={}}) {
    const methods = useForm();
    const [filterData, resetFilterData] = React.useState(filter);

    const setFilterData = (data) => {
        resetFilterData(s=> ({...s, ...data}));
    }
    
    React.useEffect( () => {
        if(!_.isEqual(filterData, filter))
            onChange && onChange(filterData)
    }, [JSON.stringify(filterData)])


    return (<FormFilterContext.Provider value={{...methods, setFilterData, filterData}}>
            {children}
        </FormFilterContext.Provider>
    );
}

function Field({as, name, multiple=false, ...props}){
    const { control, filterData, setFilterData } = React.useContext(FormFilterContext);

    const watchedValue = filterData[name];
    const [value, setValue] = React.useState(null);

    React.useEffect( () => {
        if(watchedValue === undefined)
            setValue('');
        else
            setValue(watchedValue)
    }, [value, watchedValue])

    const onChange = (...data) => {
        if(multiple){
            setFilterData({[name]: [...watchedValue, data[1].value]})
        }else{
            setFilterData({[name]: data[1].value})
        }
    }

    return React.createElement(as.type, {...as.props, onChange, value, ...props});
}

export function CheckBox({as, value, multiple=null, ...props}){
    const { setValue, filterData, setFilterData } = React.useContext(FormFilterContext);
    const [active, setActive] = React.useState(false);

    const name = Object.keys(value)[0];
    const value_of_button = Object.values(value)[0];
    const actual_value = filterData[name];

    function checkActive(){
        let eq = true;
        if(value_of_button === undefined){
            return actual_value == false || actual_value == undefined;
        }
        if(Array.isArray(actual_value)){
            if(!actual_value.includes(value_of_button))
                eq = false;
        }else{
            if(actual_value !== value_of_button)
                eq = false;
        }
        return eq;
    }

    const isChecked = () => checkActive();

    React.useEffect( () => {
        console.log(filterData,value);
        if(isChecked())
            setActive(true);
        else
            setActive(false);
    }, [JSON.stringify(filterData)])

    const onClick = () => {
        if(multiple) {
            if(value_of_button === undefined){
                setFilterData({[name]: undefined})
            }else{
                if(isChecked()){
                    const value_to_remove = Object.values(value)[0];
                    const value_ = new Set(filterData[name]);
                    value_.delete(value_to_remove);
                    const new_val = Array.from(value_);
                    if(new_val == false){
                        setFilterData({[name]: undefined})
                    }else{
                        setFilterData({[name]: new_val})
                    }
                }else{
                    const value_ = new Set(Object.values(value));
                    if(filterData[name]){
                        filterData[name].forEach( val => value_.add(val))
                    }
                    setFilterData({[name]: Array.from(value_)})
                }
            }

        }else{
            if(isChecked()){
                Object.keys(value).forEach(v => setFilterData({[v]: undefined}))
            }else{
                setFilterData(value)
            }
        }
    }

    return React.createElement(as.type, {...as.props, ...props, onClick, color: active ? 'green' : 'gray'});
}

export function ButtonForm({content, value, ...props}){
    return <CheckBox as={<Button content={content}/>} value={value} {...props}/>
}

export function GenderMaleButton(props){
    return <ButtonForm content={'Tylko mężczyźni'} value={{gender: 'm'}} {...props}/>
}

export function SelectField({name, label, options, ...props}) {
    return <Field as={
        <Form.Select label={label} options={options.map((opt, i) => ({
            key: i,
            text: opt.text,
            value: opt.value,
        }))} {...props}/>
    } name={name}/>
}

export function GenderSelect(props) {
    return <Field as={<GenderSelectField/>} name={'gender'} {...props}/>
}

export const ButtonGroup = ({multiple=false, ...props}) => <Button.Group>
    {props.children.map( child => React.createElement(child.type, {...child.props, multiple}))}
</Button.Group>

export default class X extends React.Component{
    static Provider = UserFilterForm;
    static Field = Field;
    static ButtonForm = ButtonForm;
    static SelectField = SelectField;
    static ButtonGroup = ButtonGroup;
}