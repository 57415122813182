import React from "react";
import _ from 'lodash';
import {useDispatch, useSelector} from "react-redux";

import {
    api_call_messages,
    fetchTemplates,
    fetchThread,
    fetchThreads,
    getAdditional,
    getModuleThreads,
    getTemplates,
    MESSAGES,
    updateAdditional,
    url
} from "../../modules/suggestions";
import {defaultTheme, ThemeProvider} from "@livechat/ui-kit";
import {getModule} from "../../modules/main_module";
import {Button, Divider, Dropdown, Form, TextArea} from "semantic-ui-react";
import {handleChangeForm} from "../../components/forms/users-search-form";
import {Pagination} from "../../components/tables/pagination";
import {FilterButtons} from "../../components/FilterButtons";
import {ThreadsView} from "../../components/stateless/chat/ThreadsView";
import {ChatView} from "../../components/stateless/chat/ChatView";
import {PageContainer} from "../../components/page/PageContainer";
import {UserName} from "../../components/User/UserName";
import {parse} from 'query-string'
import {useLocation} from "react-router";
import {useAppRouter} from "../../hooks/router";


export const Suggestions = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchTemplates())
    }, [dispatch]);

    return <MainView

    />
}

const filters = [
    ['Wszystkie', {}],
    ['Sugestie', {message_type: 'suggestion'}],
    ['Wiadomości kontaktowe', {message_type: 'contact_message'}],
    ['Nieprzeczytane', {read: 0}],
];

const MessageType = {
    ContactMessage: 0,
    Suggestion: 1,
}

const Sender = {
    User: 0,
    Admin: 1,
}

export const Threads = ({style}) => {
    const dispatch = useDispatch();
    const {data: threads, meta} = useSelector(getModuleThreads);
    const {active_thread_id, filter} = useSelector(getAdditional);
    const [page, changePage] = React.useState(1);

    React.useEffect(() => {
        dispatch(
            fetchThreads({page, ...filter})
        )
        return () => dispatch(updateAdditional('active_thread_id', null))
    }, [dispatch, page, filter]);

    const handleSelectThread = (id) => {
        dispatch(
            updateAdditional('active_thread_id', id)
        );
    };
    const handleChangeFilter = (new_filter) => {
        dispatch(updateAdditional('filter', new_filter));
        changePage(1);
        updateAdditional('active_thread_id', null);
    };

    _.forEach(threads, thread => {
        if(thread.message_type == MessageType.Suggestion){
            thread.subject = 'Sugestia';
        }
    })

    return <div style={style}>
        <FilterButtons filters={filters} onChange={handleChangeFilter} actual_filter={filter}/>
        <Divider/>
        <ThreadsView data={threads ? threads : []} active_thread_id={active_thread_id} onSelectActiveThread={handleSelectThread}/>
        <Pagination pagination={meta} onChange={changePage}/>
    </div>
}

export const Messages = ({style}) => {
    const location = useLocation();
    const {login, email} = parse(location.search);

    const dispatch = useDispatch();
    const messages_module = useSelector(getModule(MESSAGES));
    const messages = _.get(messages_module, 'data');
    let user = {};
    const {active_thread_id} = useSelector(getAdditional);

    React.useEffect(() => {
        dispatch(
            fetchThread(active_thread_id)
        )
    }, [dispatch, active_thread_id]);

    if(messages){
        _.forEach(messages, message => {
            message.isOwnMessage = message.sender == Sender.Admin;
            if(!message.user){
                message.user = {name: message.email}
            }
            if(message.sender == Sender.User){
                user = message.user;
            }
        });
    }

    return <div style={style}>
        {active_thread_id && messages ?
            <div>
                { user?.id ? <UserName user={user} /> : <>{user.name}, {user.email}</>}
                <ChatView messages={Array.from(messages)} style={{width: '50%'}}/>
                <AnswerField/>
            </div>
            : null
        }
        {login && email && (!active_thread_id || !messages) ?
            <div>
                { <>{login}, {email}</>}
                <AnswerField/>
            </div>
            : null
        }
    </div>
}

export const AnswerField = () => {
    const dispatch = useDispatch();
    const {active_thread_id} = useSelector(getAdditional);
    const [data, setDataForm] = React.useState({});
    const [templateContent, setTemplateContent] = React.useState('');
    const templates = useSelector(getTemplates);
    const appRouter = useAppRouter();
    const location = useLocation();
    const {user_id} = parse(location.search);
    const handleSubmit = () => {
        const params = {};
        if (active_thread_id) params['parent_id'] = active_thread_id;
        else if (user_id) params['user_id'] = +user_id;
        dispatch(
            api_call_messages('post', url(), {...params, ...data}, {
                onSuccess: () => {
                    if (active_thread_id)
                        dispatch(fetchThread(active_thread_id));
                    else
                        appRouter.goToUserPage(user_id, true);
                    setDataForm({});
                }
            })
        )

        setTemplateContent('');
    }
    const handleChangeTemplate = (e, {key, value}) => {
        setDataForm({message: value});
        setTemplateContent(value);
    }

    return <Form onSubmit={handleSubmit}>
        <Dropdown text={'Szablon'} style={{marginTop: '10px', marginBottom: '10px'}}
                  onChange={handleChangeTemplate}
                  value={templateContent}
                  options={_.map(templates, temp => ({value: temp.content, text: temp.title, key: temp.id})).concat({value: '', text: 'Brak'})}
        />
        <TextArea name={'message'} label={"Treść"} value={data['message'] || "" } onChange={handleChangeForm(setDataForm)} rows="15"/>
        <Button type='submit' content={'Wyślij'} style={{marginTop: '10px'}}/>
    </Form>
}

export const MainView = () => (
    <PageContainer name={'Sugestie i wiadomości kontaktowe'}>
        <div style={{ display: 'flex' }}>
            <ThemeProvider theme={{...defaultTheme,
                ChatListItem: {
                    css: {display: 'block'}
                }
            }}>
                <Threads style={{width: '40%'}}/>
                <Messages style={{width: '60%', marginLeft: '1%'}}/>
            </ThemeProvider>
        </div>
    </PageContainer>
)