import {handleActions} from "redux-actions";
import {call, put} from 'redux-saga/effects';
import {createAction} from "../utils";
import {fetchApi} from "./app";
import {getModulesStore} from "../store/createRootReducer";
import {takeEvery} from "@redux-saga/core/effects";
import * as _ from "lodash";

const RESET = 'app/mainModules/RESET';
const UPDATE = 'app/mainModules/UPDATE';
const API_CALL = 'app/mainModules/API_CALL';
const initModuleState = {
    data: [],
    meta: {},
    additional: {}
};

export const reset = (module, key, value) => createAction(RESET, {module, key, value});
export const update = (module, key, value) => createAction(UPDATE, {module, key, value});
export const api_call = (module, method, url, body={}, functions={}) => createAction(API_CALL, {module, method, url, body, functions});
export const init_module = (module) => reset(module, null, initModuleState);

const initialState = {
    'photos': {
        data: [],
        meta: {},
    }
};

const _getModule = module => state => _.get(state, module, initModuleState);
const _getData = module => _.get(module, 'data', []);
const _getMeta = module => _.get(module, 'meta', {});
const _getAdditional = module => _.get(module, 'additional', {});

export const getData = module => state => _getData(_getModule(module)(getModulesStore(state)));
export const getMeta = module => state => _getMeta(_getModule(module)(getModulesStore(state)));
export const getAdditional = module => state => _getAdditional(_getModule(module)(getModulesStore(state)));
export const getModule = (module) => state => _getModule(module)(getModulesStore(state));

export default handleActions({
    [RESET]: (state, action) => {
        const data = action.payload.key ?
            {[action.payload.key]: action.payload.value} :
            action.payload.value;

        return {...state, [action.payload.module]: data}
    },
    [UPDATE]: (state, action) => {
        const {module, key, value} = action.payload;

        const data = action.payload.key ?
            {[key]: {..._.get(_getModule(module)(state), key, {}), ...value}} :
            value;

        return {...state, [module]: {...state[module], ...data}}
    },
}, initialState)

export function* call_api_call(action) {
    const {module, method, url, body, functions} = action.payload;
    try{
        const response = yield call(fetchApi, url, body, method);
        if(method === 'get')
            yield put(update(module, null, response.data));

        if(response.data){
            yield put(update(module, 'last_call', response.data));
        }

        functions.onSuccess && functions.onSuccess();
    }catch (e) {
        functions.onError && functions.onError();
    }
}

export function* moduleSaga() {
    yield takeEvery(API_CALL, call_api_call);
}