import {axiosRequest} from "./core";
import {QueryConfig, useInfiniteQuery} from "react-query";

interface FetchThreadsRequest{
    user_id: number
}

export const fetchThreads = (data: FetchThreadsRequest) => axiosRequest({
    method: "post",
    url: 'getThreads',
    data,
})


interface FetchMessagesRequest{
    thread_id: number,
    page: number,
    per_page: number
}

export const fetchMessages = (data : FetchMessagesRequest) => axiosRequest({
    method: 'post',
    url: 'getMessages',
    data,
})

export const useFetchMessages = (data: FetchMessagesRequest, config?: QueryConfig<any>) => {

    const queryResult = useInfiniteQuery<any, any>({
        queryKey: ['threads', data.thread_id, 'messages'],
        queryFn: (...args: any) => {
            return fetchMessages({per_page: 50, ...data, page: args[3] ?? 1})
        },
        config: {
            ...config,
            enabled: data.thread_id,
            getFetchMore: (lastRequest: any) => {
                const next_page = lastRequest.meta.current_page+1;
                const last_page = lastRequest.meta.last_page;
                return next_page <= last_page ? next_page : false
            }
        }
    });

    return {
        ...queryResult,
        messages: queryResult.data?.map(x => {
            console.log({x})
            return x.data;
        }).flat() ?? [],
        participants: queryResult.data?.[0]?.participants ?? [],
    }
}
