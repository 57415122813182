import React from 'react';
import {render} from 'react-dom';
import Root from "./Root";
import configureStore from './store/configure-store'
import './i18n';
import './axios-interceptor'
import * as Axios from 'axios';
import {configure} from "axios-hooks";
import './global-stylesheet.css';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});
configure({ axios })

export const store = configureStore();

render(
    <Root store={store}/>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
