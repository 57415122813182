import React from "react";
import useUserApi from "../../api/user";
import {VerifyPhotos} from "../verify_photos/verify_photos";

export default function UserVerificationPhotos(){
    const {user_id} = useUserApi();

    return <VerifyPhotos
        query={{user_id}}
    />
};
