import {Query} from "cogent-js";
import {api_url} from "../utils";

export default class ApiQuery extends Query{
    constructor(options = {}) {
        super(options);
        this.base_url = api_url('admin');
        this.queryParameters['limit'] = 'per_page';
        this.params({using_new_query_builder: true})
    }

    where(key, value){
        try{
            if(key.constructor === Object)
                Object.keys(key).map( key_ => this.where(key_, key[key_]))
            else if(value.constructor === Array)
                return super.whereIn(key, value)
            else
                this.filters[key] = value;
        }catch (e){
            this.filters[key] = 'undefined';
        }
        return this
    }

    params(params) {
        if (params === undefined || params.constructor !== Object) {
            throw new Error('The params() function takes a single argument of an object.');
        }

        this.paramsObj = {...this.paramsObj, ...params};

        return this;
    }
}