import React from "react";
import { TableFromArray } from "../../components/stateless/Table";
import { Dimmer, Loader } from "semantic-ui-react";
import { statusOptions, paymentMethodOptions } from "../../modules/orders";
import Moment from "moment";

export const OrdersTable = ({ orders, loading, show_user = false }) => {
    const columns = [
        ['Id transakcji zew.', (data) => {
            if (data.payment_method != 'sms' && data.payment_method != 'manual')
                return data.order_key_id
            else
                return ' ';
        }],
        ['Promocja', (data) => {
            switch (data.promo_type) {
                case 1: return 'Pierwszy zakup < 30d od rej.'
                case 2: return 'Pierwszy zakup > 30d od rej.'
                case 3: return 'Powracający'
                default: return '-'
            }
        }],
        ['Subskrypcja', (data) => {
            let accountType = data.account_type;
            if (accountType == 'vip') accountType = 'VIP';
            else if (accountType == 'premium') accountType = 'Premium';
            let subscriptionLength = Math.abs(Moment(data.date_end).diff(Moment(data.date_start), 'months', false));
            let measureType = 'M';
            if (subscriptionLength == 0) {
                subscriptionLength = Math.abs(Moment(data.date_end).diff(Moment(data.date_start), 'days', false));
                measureType = 'D';
            }
            return `${accountType} ${subscriptionLength}${measureType}`;
        }],
        ['Kwota', (data) => `${(data.amount / 100).toFixed(2)} ${data.currency}`],
        ['Status', (data) => {
            const statusObject = statusOptions.find(status => status.value == data.status);
            if (statusObject) {
                let color = 'black';
                if (data.status == 'completed') color = 'green';
                return <span style={{ color: color }}>{statusObject.text}</span>;
            }
            return '';
        }],
        ['Ropoczęcie zakupu', (data) => data.created_at],
        ['Data zapłaty', (data) => {
            if (data.pay_date)
                return data.pay_date;
            return '-';
        }],
        ['Metoda płatności', (data) => {
            const paymentObject = paymentMethodOptions.find(status => status.value == data.payment_method);
            if (paymentObject)
                return paymentObject.text;
            return '';
        }]
    ];

    if (show_user) {
        columns.concat([
            'Użytkownik', (data) => data.user_id
        ])
    }


    return <div style={{ position: 'relative' }}>
        {loading && <Dimmer active inverted>
            <Loader inverted />
        </Dimmer>}
        <TableFromArray data={orders} columns={columns} />

    </div>
};




