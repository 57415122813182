import React from 'react';
import {Button, Icon, Modal} from "semantic-ui-react";
import {ReportUser} from "../User/UserReportCard";
import {If} from "../helpers/Condition";
import {UserName} from "../User/UserName";


export const UsersReportsModal = ({user, reports, open, onClose, onClickCheckReport}) => {

    const checkAllReports = () => {
        reports.forEach(report => {
            onClickCheckReport(report.id)
        })

        onClose();
    }

    return <If condition={user !== null}>
        <Modal
            open={open}
            onClose={onClose}
            size='small'
        >
            <Modal.Header>
                <span style={{display: 'block ruby'}}>
                     <UserName user={user} />
                </span>

                <div style={{color: 'gray'}}>
                    Ilość zgłoszeń: {reports.length}
                </div>
            </Modal.Header>
            <Modal.Content>
                {reports.map(report => (
                    <span>
                        <ReportUser report={report} more_info={true}/>
                        <Button onClick={() => onClickCheckReport(report.id)} content={"OK"}/>
                    </span>
                ))}
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' onClick={checkAllReports} inverted>
                    <Icon name='checkmark' /> Anuluj wszystkie
                </Button>
            </Modal.Actions>
        </Modal>
    </If>
}