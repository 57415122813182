import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {Card, Checkbox, Divider} from "semantic-ui-react";
import {
    checkReport,
    fetchReportedUsers,
    fetchReportsOfUser,
    getReportedUsers,
    getReports,
    REPORTED_USERS
} from "../../modules/reports";
import {PageContainer} from "../../components/page/PageContainer";
import {UserReportCard} from "../../components/User/UserReportCard";
import {UsersReportsModal} from "../../components/modals/UsersReportsModal";
import {Pagination} from "../../components/tables/pagination";
import {getMeta} from "../../modules/main_module";

const AUTO_BANNED = 'auto_banned';

export const ReportsPage = () => {
    const dispatch = useDispatch();

    const [filter, setFilter] = React.useState('');
    const [page, setPage] = React.useState(1);

    const [modalState, setModalState] = React.useState({open: false, user: {}});
    let query = {};

    const reports = useSelector(getReportedUsers);
    const pagination = useSelector(getMeta(REPORTED_USERS));
    const users_reports = useSelector(getReports);

    const handleOpenModal = (user) => {
        setModalState({open: true, user});
        dispatch(fetchReportsOfUser(user.id));
    }

    const fetchUsers = () => {
        if(filter === AUTO_BANNED)
            query = {...query, auto_banned: true};
        query = {...query, page};
        dispatch(fetchReportedUsers(query));
    }

    const handleCloseModal = () => {
        fetchUsers();
        setModalState({open: false, user: {}});
    }

    const handleCheckReport = (id) => {
        dispatch(checkReport(id, () => {
            fetchUsers();
            dispatch(fetchReportsOfUser(modalState.user.id));
        }));
    }



    React.useEffect(fetchUsers, [dispatch, filter, page]);

    React.useEffect( () => {
        if(!users_reports.length){
            handleCloseModal();
        }
    }, [users_reports]);

    React.useEffect( () => {
        if(!reports.length) {
            if(page > 1)
                setPage(page-1)
        }
    }, [reports]);

    return <PageContainer name={'Zgłoszenia'}>
        <UsersReportsModal
            open={modalState.open}
            user={modalState.user}
            onClose={handleCloseModal}
            reports={users_reports}
            onClickCheckReport={handleCheckReport}
        />
        <Checkbox value={filter === AUTO_BANNED} onChange = {(e,v) => {v.checked ? setFilter(AUTO_BANNED) : setFilter('')}} label={'Automatycznie zgłoszeni'}/>
        <Divider/>
        <Card.Group>
            { reports.map(report =>
                <UserReportCard data={report} onClickShowModalFunction={handleOpenModal}/> )}
        </Card.Group>
        <Pagination pagination={pagination} onChange={(page)=>setPage(page)}/>
    </PageContainer>
}