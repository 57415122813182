import {handleActions} from "redux-actions";
import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {createAction} from "../utils";
import {API} from "../api-endpoints";
import {getUserStore} from "../store/createRootReducer";
import {apiAuthRequest} from "../store/sagas";
import {fetchApi} from "./app";
import {startSubmit, stopSubmit} from "redux-form";
import {resetOrders} from "./orders";


const SAGA_FETCH_USER = 'app/users/SAGA_FETCH';
const SAGA_FETCH_USER_PHOTOS = 'app/users/SAGA_FETCH_USER_PHOTOS';
const SAGA_FETCH_USER_ORDERS = 'app/users/SAGA_FETCH_USER_ORDERS';

const BAN_USER = 'app/users/BAN_USER';
const REMOVE_BAN_USER = 'app/users/REMOVE_BAN_USER';

const SET_HOT_USER = 'app/users/SET_HOT_USER';

const RESET_USER = 'app/users/RESET_USER';
const RESET_PHOTOS = 'app/users/RESET_PHOTOS';
const OPEN_BLOCK_MODAL = 'app/users/OPEN_BLOCK_MODAL';


export const fetchUser = (id) => createAction(SAGA_FETCH_USER, {id});
export const fetchPhotos = () => createAction(SAGA_FETCH_USER_PHOTOS, {});
export const fetchUserOrders = (user) => createAction(SAGA_FETCH_USER_ORDERS, {user});
export const banUser = (form, values) => createAction(BAN_USER, {form, values});
export const removeBanUser = () => createAction(REMOVE_BAN_USER);

export const setHotUser = (value) => createAction(SET_HOT_USER, {value});

export const resetUser = (data) => createAction(RESET_USER, {data});
export const resetUserPhotos = (photos) => createAction(RESET_PHOTOS, {photos});
export const openBlockModal = (open=true) => createAction(OPEN_BLOCK_MODAL, {open});



const initialState = {
    id: undefined,
    data: {},
    photos: [],
    block_modal_open: false,
};


export default handleActions({
        [RESET_USER]: (state, action) => {
            return {...state, data: action.payload.data}
        },
        [RESET_PHOTOS]: (state, action) => {
            return {...state, photos: action.payload.photos}
        },
        [OPEN_BLOCK_MODAL]: (state, action) => {
            return {...state, block_modal_open: action.payload.open}
        },
    },
    initialState
)


export const getUserId = state => getUserStore(state).data.id;
export const getUser = state => getUserStore(state).data;
export const getBlockModalOpen = state => getUserStore(state).block_modal_open;

function* callFetchUser(action) {
    let id = action.payload.id;
    yield put(resetUser({id}));
    const response = yield call(fetchApi, API.user(id), {}, 'get');
    yield put(resetUser(response.data.data));
}

export function* callFetchUserPhotos() {
    const response = yield call(apiAuthRequest, API.GET_PHOTOS, {
        user_id: yield select(getUserId)
    });
    yield put(resetUserPhotos(response.data.data));
}

export function* callBanUser(action) {
    const {form, values} = action.payload;
    yield put(startSubmit(form));

    let data = {
        action: 'ban',
        ban_type: values.ban_type
    };
    try {
        const response = yield fetchApi(API.user(yield select(getUserId)), data, 'put');
        yield put(resetUser(response.data.data));
        yield put(stopSubmit(form));
        yield put(openBlockModal(false));
    }catch (e) {
        console.log(e.data);
        yield put(stopSubmit(form, e.response.data.errors));
    }
}

export function* callUnBanUser() {
    const response = yield fetchApi(API.user(yield select(getUserId)), {action: 'remove_ban'}, 'put');
    yield put(resetUser(response.data.data));
}

export function* callFetchUserOrders(action) {
    const response = yield fetchApi(API.user_orders(action.payload.user), {}, 'get');
    yield put(resetOrders(response.data.data));
}

export function* callSetHotUser(action) {
    const response = yield fetchApi(API.user(yield select(getUserId)), {action: 'hot_person', value: action.payload.value}, 'put');
    yield put(resetUser(response.data.data));
}

export function* userSaga() {
    yield takeEvery(SAGA_FETCH_USER, callFetchUser);
    yield takeEvery(SAGA_FETCH_USER_PHOTOS, callFetchUserPhotos);
    yield takeEvery(SAGA_FETCH_USER_ORDERS, callFetchUserOrders);
    yield takeEvery(BAN_USER, callBanUser);
    yield takeEvery(REMOVE_BAN_USER, callUnBanUser);
    yield takeEvery(SET_HOT_USER, callSetHotUser);
}

