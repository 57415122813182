import {createContext, useEffect, useState} from "react";

export const useStateWithOnChangeCallback = (externalValue, onChange) => {
    const [actualValue, setActualValue] = useState(externalValue);

    useEffect( () => {
        if(externalValue !== actualValue)
            onChange(actualValue);
    }, [actualValue])

    useEffect( () => {
        if(externalValue !== actualValue)
            setActualValue(externalValue);
    }, [externalValue])

    return [actualValue, setActualValue];
}

export const useSelectedEntities = () => {
    const [selected, setSelected] = useState([]);

    const handleClick = (id) => {
        if(selected.find(i => i == id)){
            setSelected(prev => prev.filter(x => x !== id))
        }else{
            setSelected(prev => [...prev, id]);
        }
    }

    const isSelected = (id) => {
        return selected.find(i => i == id)
    }

    return {selected, handleClick, isSelected, setSelected}
}

export const SelectedEntitiesContext = createContext({selected: [], handleClick: undefined, isSelected: undefined, setSelected: undefined})

