import {useEffect, useState} from 'react';
import useAxios from "axios-hooks";
import {AxiosRequestConfig, AxiosResponse} from "axios";
import {useDispatch} from 'react-redux';
import {getAuthToken} from "../modules/app";
import {PaginatedQueryConfig, usePaginatedQuery, useQueryCache} from "react-query";
import axios from '../axios-interceptor';
import {Endpoint} from "./requests";
import {usePageLoader} from "../components/page/loader";

type handleResponse = (response: AxiosResponse, {dispatch} : {dispatch: any}) => any


export const axiosRequest = async (config : AxiosRequestConfig) => {
    const res = await axios({
        ...config
    })
    return res.data
}

export interface PaginatedRequest {
    page?: number,
    per_page?: number,
}

export function useResource(config: AxiosRequestConfig, onSuccess?: handleResponse, onError?: handleResponse, defaultParams?: any){
    const request = useRequest(config, onSuccess, onError);

    useEffect(() => {
        // @ts-ignore
        request.call(defaultParams)
    }, [defaultParams])

    return request;
}

export function useRequest(config: AxiosRequestConfig, onSuccess_?: handleResponse, onError_?: handleResponse){
    config.headers = {...config.headers, 'Authorization': `bearer ${getAuthToken()}`}

    const [
        meta,
        execute
    ] = useAxios(
        config,
        { manual: true }
    )

    const [successFlag, setSuccessFlag] = useState(false);

    const setSuccessFlagAsOk = () => {
        setSuccessFlag(true)

        setTimeout( () => {
            setSuccessFlag(false)
        }, 1000)
    }


    const dispatch = useDispatch();
    const queryCache = useQueryCache();
    const helpers = {dispatch, queryCache};

    // meta.data && onSuccess_ && onSuccess_(meta.data);
    // meta.error && onError_ && onError_(meta.error);

    function call(data?: AxiosRequestConfig, onSuccess?: handleResponse, onError?: handleResponse) {
        if(data)
            data.data = {...config.data, ...data.data}

        execute(data).then(res => {
            onSuccess_ && onSuccess_(res, helpers)
            onSuccess && onSuccess(res, helpers)
            setSuccessFlagAsOk();
        }).catch(e => {
            console.error('error:', e);
            onError_ && onError_(e.res, helpers);
            onError && onError(e.res, helpers);
        })
    }

    return {
        ...meta, execute,
        isLoading: meta.loading,
        successFlag,
        call
    };
}

interface CustomPaginatedQuery {
    initConfig?: {
        page?: number,
        perPage?: number,
        filters?: any,
        params?: any
        sortBy?: string
    }
    config?: PaginatedQueryConfig<any>
}

export const useCustomPaginatedQuery = (key: Endpoint, request: any, config_?: CustomPaginatedQuery) => {
    const queryCache = useQueryCache();
    const pageLoader = usePageLoader();
    const {initConfig, config} = config_ ?? {};

    const [page, setPage] = useState(initConfig?.page ?? 1);
    const [perPage, setPerPage] = useState(initConfig?.perPage ?? 15);
    const [filters, setFilters] = useState(initConfig?.filters ?? {});
    const [sortBy, setSortBy] = useState(initConfig?.sortBy ?? '');
    const [params, setParams] = useState(initConfig?.params ?? {});

    const paginatedQueryResult = usePaginatedQuery({
        queryKey: [key, {page, perPage, filters}],
        queryFn: () => request({page, filters, perPage, params, sortBy}),
        config
    });

    // @ts-ignore
    useEffect( () => pageLoader.setLoading?.(paginatedQueryResult.isFetching), [paginatedQueryResult.isFetching])

    return {
        queryCache,
        ...paginatedQueryResult,
        // @ts-ignore
        rawData: paginatedQueryResult.data?.data?.data ?? [],
        // @ts-ignore
        data: paginatedQueryResult.resolvedData?.data?.data ?? [],
        // @ts-ignore
        pagination: paginatedQueryResult.resolvedData?.data?.meta ?? {},
        setPage,
        setPerPage,
        setFilters,
        filters,
        page,
        perPage,
        params,
        setParams,
        sortBy, setSortBy
    }
}
