import React, {useEffect, useMemo} from "react";
import {Button, Header, Loader, Pagination, Segment, TransitionablePortal} from "semantic-ui-react";
import {Link, useLocation} from "react-router-dom";
import {ROUTE} from "../../route-names";
import {UsersSearchForm} from "../../components/forms/users-search-form";
import {PageContainer} from "../../components/page/PageContainer";
import Accordion from "../../components/page/Accordion";
import UserFilters from "./components/filter-users";
import UsersTable from "./components/table-users";
import _ from 'lodash';
import {useGetUsers} from "../../api/users";
import {NumberParam, ObjectParam, useQueryParam, withDefault} from "use-query-params";
import {keysToCamel, keysToSnake} from "../../utils";
import {UsersGroups} from "./users-groups";
import {filters as usersFilters} from "../../modules/users";


export const Users = () => {
    const usersQuery = useGetUsers();
    const location = useLocation();

    const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 1))
    const [filter, setFilter] = useQueryParam('filter', withDefault(ObjectParam, usersFilters.all))
    const [query, setQuery] = useQueryParam('query', withDefault(ObjectParam, {}))

    useEffect( () => {
        usersQuery.setPage(page);
        usersQuery.setFilters({...keysToSnake(filter), ...keysToSnake(query)})
    }, [location])

    const handleChangePage = (page) => {
        usersQuery.setPage(page)
        setPage(page)
    };

    const Actions = () => (
        <Link to={ROUTE.USER_CREATE}>
            <Button id='editUserButton' floated='right'>Dodaj użytkownika</Button>
        </Link>
    )

    const handleFilterChange = (filter, concat=false) => {
        if(_.isEqual(usersFilters.all, filter))
            setQuery({})

        handleChangePage(1)
        usersQuery.setFilters(filter)
        setFilter(keysToCamel(filter))
    }

    const handleQueryChange = (new_query) => {
        const _query = _.pick(new_query, ['ip', 'phone', 'email', 'login']);
        setQuery(keysToCamel(_query), 'replace')
        usersQuery.setFilters(prevState => (_.pickBy({...prevState, ..._query}, v => v !== undefined)))
    }

    const connected_users_filter = _.isEqual({has_connected_accounts: 1}, usersQuery.filters);

    const getSearchFilterQuery = useMemo(() =>
        keysToSnake(query),
        [location]
    )
    const activeFilter = useMemo(() =>
        keysToSnake(filter),
        [location]
    )

    const isSearcherActive = JSON.stringify(query) !== "{}";
    return <PageContainer name={'Użytkownicy'} actions={Actions}>

        <TransitionablePortal open={usersQuery.isFetching}>
            <Segment
                style={{ right: '5px', position: 'fixed', bottom: '5px', zIndex: 1000 }}
            >
                <Header as={'h4'}><Loader active inline /> Ładowanie danych </Header>
            </Segment>
        </TransitionablePortal>

        <TransitionablePortal open={usersQuery.error}>
            <Segment
                style={{ right: '5px', position: 'fixed', bottom: '5px', zIndex: 1000 }}
            >
                <Header as={'h4'}>Wystąpił błąd</Header>
            </Segment>
        </TransitionablePortal>

        <Segment>
            <div>
                <h5>Podstawowe filtry</h5>
                <UserFilters value={activeFilter} onChange={handleFilterChange}/>
            </div>

            {!connected_users_filter &&
            <>
                <Accordion title={`Wyszukiwarka ${isSearcherActive ? '(Aktywna)' : ''}`}>
                    <UsersSearchForm onChange={handleQueryChange} query={getSearchFilterQuery}/>
                </Accordion>
            </>
            }
        </Segment>

        {isSearcherActive &&
            <style jsx>{`
                    .title {
                      color: green!important;
                      font-weight: bold;
                    }
                 `}</style>
        }

        {connected_users_filter ? (
            <UsersGroups/>
        ) : (
            <>
                <UsersTable users={usersQuery.users}/>

                <Segment basic textAlign={"center"}>

                    <Pagination activePage={usersQuery.page}
                                totalPages={usersQuery.resolvedData?.data?.meta?.last_page}
                                onPageChange={(e, { activePage })=>handleChangePage(activePage)}
                    />
                </Segment>
            </>
        )}



    </PageContainer>
};

export default Users;