import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Container, Divider, Header, Pagination, Popup, Segment} from "semantic-ui-react";
import {SelectableTableFromArray} from "../../components/stateless/Table";
import * as UserDescriptionsModule from "../../modules/user_descriptions";
import {
  changePage,
  fetchUserDescriptions,
  getUserDescriptions,
  getUserDescriptionsMeta,
  setFilter
} from "../../modules/user_descriptions";
import {UserName} from "../../components/User/UserName";
import {api_url, parseDate} from "../../utils";
import {request} from "../../modules/app";

const are_filters_equal = (filter1, filter2) => {
  return (filter1 && filter2 &&
    filter2[Object.keys(filter1)[0]] === filter1[Object.keys(filter1)[0]] &&
    Object.keys(filter1).length
  ) &&
    (
      Object.keys(filter1).length !== 0
    );
}

export const ButtonSetFilter = ({ actual_filter, setFilter, filter, content }) => (
  <Button size='mini' id='filter_comments' color={are_filters_equal(filter, actual_filter) ? 'green' : null} floated='left' onClick={() => setFilter(filter)} content={content} />
);

const Filter = () => {
  const dispatch = useDispatch();
  const actual_filter = useSelector(UserDescriptionsModule.getUserDescriptionsFilter);

  const filters = [
    ['Opisy użytkowników', UserDescriptionsModule.filters.profile_desc],
    ['Imiona użytkowników', UserDescriptionsModule.filters.profile_name],
    ['Opisy zdjęć', UserDescriptionsModule.filters.photo_desc],
  ];

  const setFilterAndFetchUserDescriptions = (filter) => {
    dispatch(setFilter(filter));
    dispatch(changePage(1));
    dispatch(fetchUserDescriptions());
  };

  return <Button.Group floated={'left'}>
    {filters.map((filter) => (
      <ButtonSetFilter content={filter[0]} actual_filter={actual_filter} filter={filter[1]} setFilter={setFilterAndFetchUserDescriptions} />
    ))}
  </Button.Group>
};

const UserDescriptionsSection = ({ view, userDescriptions }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const currentFilter = useSelector(UserDescriptionsModule.getUserDescriptionsFilter);

  const rejectDescriptions = (ids) => {
    const action = currentFilter?.action;
    let actionParam = '';
    if (action) actionParam = `?action=${action}`;
    setLoading(true)
    request('post', api_url(`admin/user_content_approvements/rejectMany${actionParam}`), { data: ids }).then(res => {
        dispatch(fetchUserDescriptions())
    })
  }  
  
  const acceptDescriptions = (toApprove) => {
    const action = currentFilter?.action;
    let actionParam = '';
    if (action) actionParam = `?action=${action}`;
    setLoading(true)
    request('post', api_url(`admin/user_content_approvements${actionParam}`), { data: toApprove }).then(res => {
        dispatch(fetchUserDescriptions())
    })
  }

  useEffect(() => {
    setLoading(false)
    setSelected([]);
  }, [userDescriptions])

  const RejectSelectedButton = useCallback(() =>
    <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Odrzuć (${selected.length})`} />}>
      <div>Czy na pewno odrzucić wybrane opisy?</div>
      <Button onClick={() => {
        rejectDescriptions(selected);
      }} content={'Odrzuć'} loading={loading} disabled={loading} />
    </Popup>
    , [userDescriptions, selected, loading])

  const ApproveSelectedButton = useCallback(() =>
    <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Zatwierdź (${selected.length})`} />}>
      <div>Czy na pewno zatwierdzić wybrane opisy?</div>
      <Button onClick={() => {
        const toApprove = [];
        selected.forEach(id => {
          const description = userDescriptions.find(description => description.id == id).value;
          toApprove.push({
            id: id,
            value: description
          });
        });
        acceptDescriptions(toApprove);
      }} content={'Zatwierdź'} loading={loading} disabled={loading} />
    </Popup>
    , [userDescriptions, selected, loading])


  return <>
    <ApproveSelectedButton />
    <RejectSelectedButton />
    <SelectableTableFromArray selected={selected} setSelected={setSelected} data={userDescriptions} warningConditionField={'suspect'} warningConditionValue={1} columns={[
      ['Użytkownik', (data) => <UserName user={data.user} premium_icon={false} hot_icon={false} gender country account_type/>, '350px'],
      ['Opis', (data) => <textarea rows="4" style={{ width: '100%' }} defaultValue={data.value} onChange={(event) => data.value = event.target.value}/>],
      ['Data utworzenia', (data) => parseDate(data.date), '150px'],
    ]} />
  </>
};

export const UserDescriptionsView = () => {
  let userDescriptions = useSelector(getUserDescriptions);
  const [view, setView] = useState(0);
  const meta = useSelector(getUserDescriptionsMeta);
  const dispatch = useDispatch();


  const setPageAndFetchUserDescriptions = (page) => {
    dispatch(changePage(page));
    dispatch(fetchUserDescriptions());
  };

  return <Container style={{ width: '99%' }}>
    <Divider hidden />
    <Header as='h2'>
      Opisy do akceptacji
      </Header>
    <Divider />
    <Button.Content align={'right'}>
      <Filter />
    </Button.Content>
    <Divider hidden />
    <UserDescriptionsSection view={view} userDescriptions={userDescriptions} />
    <Divider />
    <Segment basic textAlign={"center"}>

      <Pagination activePage={meta ? meta.current_page : 1}
        totalPages={meta ? meta.last_page : 2}
        onPageChange={(e, { activePage }) => setPageAndFetchUserDescriptions(activePage)}
      />
    </Segment>
  </Container>;
};

export const UserDescriptions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserDescriptions());
  }, [dispatch]);

  return <UserDescriptionsView />
};