import React, {useEffect, useState } from "react";
import {Button, Container, Divider, Form, Header, Message, Popup} from "semantic-ui-react";
import {Field, reduxForm} from "redux-form";
import {Checkbox, InputField, SelectField} from 'react-semantic-redux-form';
import {connect, useDispatch} from "react-redux";
import {getPhoto, rotatePhoto, submitPhoto} from "../../modules/photos";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import {useQueryCache} from "react-query";
import {PhotoSrc} from "../../pages/users/components/user-page/photos-section";
import SmartForm from './core';
import {useForm} from "react-hook-form";
import {PhotoCrop, usePhotoCrop} from "../photo-crop";
import {PhotoRotateButtons} from "../photo/actions";
import {UserName} from "../User/UserName";
import {useGetUser, useNewUserApi} from "../../api/users";
import {usePhotosApi} from "../../api/photos";

export class ImageCrop extends React.Component{

    constructor(props) {
        super(props);
        this.ref = React.createRef()
    }

    handleChange(crop_pixels, crop_percent){
        const percent_to_pixels = crop => {
            const crop_pixels = {...crop};
            let scale = this.getScale();
            Object.keys(crop).map(k => {
                if(k !== 'aspect' && k !== 'unit'){
                    crop_pixels[k] = Math.trunc(crop[k] * scale)
                }
                return null;
            });
            crop_pixels.unit = 'px';
            return crop_pixels;
        };

        const new_crop = percent_to_pixels(crop_pixels);
        this.props.onChange({crop_pixels: new_crop, crop_percent})


    }

    setCrop() {
        const {onChange, initialValues} = this.props;

        console.log('mount', initialValues);
        if (initialValues && initialValues.crop && initialValues.crop.crop_pixels) {
            const crop_pixels = initialValues.crop.crop_pixels;

            const pixels_to_percent = crop => {
                const new_crop = {...crop};
                const {width, height} = initialValues;
                new_crop.x = width / crop_pixels.x;
                new_crop.y = height / crop_pixels.y;
                new_crop.width = width / crop_pixels.width;
                new_crop.height = height / crop_pixels.height;
                new_crop.unit = '%';
                return new_crop;
            };

            const newCrop = {...initialValues.crop, crop_percents: pixels_to_percent(initialValues.crop.crop_pixels)};
            console.log({newCrop});
            onChange(newCrop)
        }
    }

    getScale() {
        let img = this.ref.current.imageRef;
        let scale = img.naturalHeight / img.clientHeight;
        return scale;
    }

    render() {
        const { initialValues, crop} = this.props;
        console.log('render', {initialValues});
        console.log('render', {crop});

        const new_crop = {...crop.crop_percent, aspect: 1, unit:'%'};
        console.log('render', crop.crop_percent);
        return (
            <PhotoSrc fetchSize={'original'} cropped={0} photo_id={initialValues.id} render={({src}) => (
                <ReactCrop src={src} crop={{...new_crop}} onChange={this.handleChange.bind(this)} ref={this.ref}/>
            )} />
        );
    }
}

const rotationsOptions = [
    { value: '', text: '' },
    { value: '90', text: '90' },
    { value: '180', text: '180' },
    { value: '270', text: '270' },
].map((status, i) => ({ ...status, key: i }));

export const CropField = ({input: {value: crop, onChange}, initialValues}) =>{

    return <ImageCrop crop={crop} onChange={(val) =>{
        onChange(val);
        console.log('new_value', val);
    }} initialValues={initialValues}/>
    // return <ReactCrop src={initialValues.url_full} crop={{...crop, aspect: 1}} onChange={onChange} />
};


export const EditPhotoView = props => {
    const { error, onSubmit, initialValues, handleSubmit, form } = props;
    const dispatch = useDispatch();

    const queryCache = useQueryCache();

    const rotatePhoto_ = (r) => {
        dispatch(rotatePhoto(r))
        queryCache.invalidateQueries(['photos-base64', initialValues.id])
    }

    return <Container>
        <Divider hidden />
        <Field name={'crop'} component={CropField} initialValues={initialValues} />
        <Form autoComplete="off" error={error}>
            {error &&
            <Message
                error
                content={'Wystąpił błąd'} />
            }

            <Header as="h3" dividing>
                Informacje
            </Header>

            <Field
                component={Checkbox}
                name='status'
                label="Zatwierdzone" />
            <Divider hidden />

            <Field
                component={Checkbox}
                name='private'
                label="Prywatne" />
            <Divider hidden />

            <Field
                component={SelectField}
                name='rotation'
                label="Obrót"
                options={rotationsOptions}
                search={false} />
            <Divider hidden />

            <Field name={'description'} component={InputField} label={'Opis'} />
            <Divider hidden />

            <Button type='button' id='submitFormButton' onClick={handleSubmit(() => {
                onSubmit(form)
                queryCache.invalidateQueries(['photos-base64', initialValues.id])
            })}>Zapisz</Button>
            <Button onClick={() => rotatePhoto_(-90)} icon={'undo'} />
            <Button onClick={() => rotatePhoto_(90)} icon={'redo'} />
        </Form>
    </Container>
};

export const EditPhoto = ({photo, onSubmit, config={}}) => {

    const methods = useForm();
    const fieldValues = methods.watch();
    console.log({fieldValues})
    const photoCrop = usePhotoCrop();
    useEffect( () =>  photoCrop.setPhoto(photo), [photo])
    const {user_data: user} = useGetUser(photo.user_id, {withThreads:false, withPhotos:false});
    const userApi = useNewUserApi();
    const photosApi = usePhotosApi(photo.id);

    useEffect( () => {
        Object.keys(methods.getValues()).forEach(x => methods.unregister(x))
        photo.status = !!photo.status;
        methods.reset(photo)
        methods.register('crop');
        return () => {
            Object.keys(methods.getValues()).forEach(x => methods.unregister(x))
        }
    }, [photo])

    const [croppedSize, setCroppedSize] = useState({});

    useEffect(() => {
        methods.setValue('crop', photoCrop.cropValues)
        if (photoCrop.cropValues.width == 0 && photoCrop.cropValues.height == 0)
            setCroppedSize({})
        else
            setCroppedSize({ value: `${photoCrop.cropValues.width}x${photoCrop.cropValues.height}` });
    }, [photoCrop.cropValues])

    return <>
        <SmartForm methods={methods}>
            <Button content={"Zapisz"} onClick={methods.handleSubmit(onSubmit)} />
            <div style={{ display: 'inline-flex', verticalAlign: 'middle', marginLeft: '10px' }}>
                {user?.id &&
                    <UserName user={user} hot_icon premium_icon gender banned deleted messages country account_type connected_warn/>
                }
                <span style={{marginRight: '10px', marginTop: '5px', marginLeft: '10px'}}>Obróć:</span>
                <PhotoRotateButtons photo_id={photo.id} />
                <Popup
                    content={"Nie można ustawić jako gorący użytkownik, ponieważ użytkownik nie ma zdjęcia profilowego"}
                    disabled={user.can_set_as_hot_person != null} trigger={
                        <span style={{ filter: `saturate(${user.hot_person ? 1 : 0})` }}>
                            <Button size={'tiny'} content={"HOT"}
                                onClick={() => userApi.setHotUser(user.id, !user.hot_person)}
                                color={user.hot_person ? "pink" : undefined}
                                disabled={!user.can_set_as_hot_person}
                                style={{marginLeft: '15px'}} />
                        </span>
                    }
                />
                { photo.confirmed ?
                    <Button content={'Usuń weryf.'} onClick={() => photosApi.edit({confirmed: 0})} style={{marginLeft: '10px'}}/>
                    :
                    <Button content={'Zweryfikuj'} onClick={() => photosApi.edit({confirmed: 1})} style={{marginLeft: '10px'}}/>
                }
            </div>

            {!config.hidePrivateField && <Form.Checkbox name={'private'} label={"Prywatne"} />}
            {!config.hideStatusField && <Form.Checkbox name={'status'} label={"Zatwierdzone"} />}

            <Form.Input name={'description'} />
            {croppedSize && croppedSize.value ? <div>Wielkość skadrowana: {croppedSize.value}</div> : ''}
            <Form.Field>
                <PhotoSrc fetchSize={'original'} cropped={0} photo_id={photo.id} render={({ src, loading }) => (
                    <PhotoCrop src={src} photoCrop={photoCrop} />
                )} />
            </Form.Field>
        </SmartForm>

    </>
}

export const EditPhotoFormComponent = reduxForm({
    form: 'photoEdit',
    enableReinitialize: true,
})(EditPhotoView);

export default connect(state=>({
    initialValues: getPhoto(state),
    photo: getPhoto(state),
}), {onSubmit: submitPhoto})(EditPhotoFormComponent);