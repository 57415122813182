import React from 'react'
import { Message, MessageList, MessageText } from '@livechat/ui-kit'
import { Image } from "semantic-ui-react";

const Attachment = ({ attachment }) => (
    <div>
        <a href={attachment.url}>Załącznik: {attachment.name}</a>
    </div>
)

export const ChatView = ({ messages }) => (
    <MessageList active>
        {messages.map(({ isOwnMessage, body, created_at, user, attachment }) => {
            return <><Message authorName={user.name} date={created_at} isOwn={isOwnMessage}>
                <MessageText>{body}</MessageText>
                {attachment && <><Image src={process.env.PUBLIC_URL + '/attachment.png'} /><Attachment attachment={attachment} /></>}
            </Message><hr /></>
        })}
    </MessageList>
);