import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {OrdersTable} from "../../components/tables/OrdersTable";
import {getOrders} from "../../modules/orders";
import useUserApi from "../../api/user";

export const UserOrdersComponent = () => {
    const {fetchOrders, user_id} = useUserApi();
    const orders = useSelector(getOrders);

    useEffect( () => {
        fetchOrders.call();
    }, [user_id]);

    return <OrdersTable orders={orders} loading={fetchOrders.loading || fetchOrders.globalLoading}/>
};

export default UserOrdersComponent;
