import {handleActions} from "redux-actions";
import {call, put, select, takeEvery} from "@redux-saga/core/effects";
import {getRoles} from "../store/createRootReducer";
import {createAction} from "../utils";
import {API} from "../api-endpoints";
import {fetchPermissions} from "./permissions";
import {apiAuthRequest} from "../store/sagas";

const SAGA_FETCH_ROLES = 'app/roles/SAGA_FETCH_ROLES';
const SAGA_DELETE_ROLE = 'app/roles/SAGA_DELETE';
const SAGA_SAVE_ROLE = 'app/roles/SAGA_SAVE';

const ADD = 'app/roles/ADD';
const DELETE = 'app/roles/DELETE';
const EDIT = 'app/roles/EDIT';
const RESET = 'app/roles/RESET';
const TOGGLE_PERMISSION = 'app/roles/TOGGLE_PERMISSION';
const TOGGLE_EDIT = 'app/roles/TOGGLE_EDIT';


export const fetchRolesSaga = ()=>createAction(SAGA_FETCH_ROLES);
export const deleteRoleSaga = (id)=>createAction(SAGA_DELETE_ROLE, {id});
export const saveRoleSaga = (id)=>createAction(SAGA_SAVE_ROLE, {id});

export const addRole = ()=>createAction(ADD);
export const deleteRole = (id)=>createAction(DELETE, {id});
export const editRole = (id, key, value)=>createAction(EDIT, {id, key, value});
export const resetRoles = (roles)=>createAction(RESET, {roles});
export const togglePermission = (id, permission_name)=>createAction(TOGGLE_PERMISSION, {id, permission_name});
export const toggleEdit = (id)=>createAction(TOGGLE_EDIT, {id});


const initialState = [];

export default handleActions(
    {
        [RESET] : (roles, action) => {
            return action.payload.roles
        },
        [ADD] : (state) => {
            const hasNewRole = !!state.find((el) => 0===el.id);
            if(!hasNewRole){
                const newRole = {
                    id: 0,
                    name: '',
                    edit: true,
                    permissions: []
                };
                return state.concat([newRole])
            }else{
                return state;
            }
        },
        [DELETE] : (state, action) => {
            const id = action.payload.id;
            const role = state.find((el) => id===el.id);
            const index = state.indexOf(role);

            return [...state.slice(0, index), ...state.slice(index+1)];
        },
        [EDIT] : (state, {payload: {id, key, value}}) => {
            const role = state.find(e => e.id === id);
            role[key] = value;
            return Object.assign([], state);
        },
        [TOGGLE_EDIT] : (state, {payload: {id}}) => {
            const role = state.find(e => e.id === id);
            role.edit = !role.edit;
            return [...state];
        },
        [TOGGLE_PERMISSION]: (state, action) => {
            const role = state.find(e => e.id === action.payload.id);
            const permissions = role.permissions;

            const name = action.payload.permission_name;
            const indexOfPermission = permissions.indexOf(name);
            if(indexOfPermission === -1){
                role.permissions = permissions.concat(name);
            }else{
                role.permissions = [...permissions.slice(0, indexOfPermission), ...permissions.slice(indexOfPermission+1)];
            }
            return [...state];
        }
    },
    initialState,
);


export const getRoleById = (state, id) => {
    return getRoles(state).find(el => el.id === id);
};


function* callFetchRoles() {
    yield put(fetchPermissions());
    const response = yield call(apiAuthRequest, API.GET_ROLES);
    yield put(resetRoles(response.data.data));
}

function* callSaveRole(action) {
    let id = action.payload.id;
    const role = yield select(getRoleById, id);

    try{
        if(role.id){
            yield call(apiAuthRequest, API.UPDATE_ROLE, {
                id: role.id,
                name: role.name,
                permissions: role.permissions,
            });
        }
        else{
            const data = {
                name: role.name,
                permissions: role.permissions,
            };
            const response = yield call(apiAuthRequest, API.CREATE_ROLE, data);
            const newId = response.data.data.role_id;
            yield put(editRole(id, 'id', newId));
            id = newId;
        }
        yield put(editRole(id, 'edit', false))
    }catch (e) {
        console.log("ERROR", e);
    }
}

function* callDeleteRole(action) {
    const id = action.payload.id;
    const role = yield select(getRoleById, id);

    try{
        if(id){
            yield call(apiAuthRequest, API.DELETE_ROLE, {
                id: role.id
            });
        }
        yield put(deleteRole(id));
    }catch (e) {
        console.error(e);
    }
}

export function* rolesSaga() {
    yield takeEvery(SAGA_FETCH_ROLES, callFetchRoles);
    yield takeEvery(SAGA_SAVE_ROLE, callSaveRole);
    yield takeEvery(SAGA_DELETE_ROLE, callDeleteRole);
}