import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Container, Divider, Header, Image, Input, Pagination, Popup, Segment} from "semantic-ui-react";
import Moment from 'moment';
import {SelectableTableFromArray} from "../../components/stateless/Table";
import * as CommentsModule from "../../modules/comments";
import {changePage, fetchComments, getComments, getCommentsMeta, setFilter, resetCommentsQuery} from "../../modules/comments";
import {Link} from "react-router-dom";
import {ROUTE} from "../../route-names";
import {UserName} from "../../components/User/UserName";
import {api_url} from "../../utils";
import {request} from "../../modules/app";
import { useParams } from "react-router";
import {useAppRouter} from "../../hooks/router";

export const are_filters_equal = (filter1, filter2) => {
    return (filter1 && filter2 &&
            filter2[Object.keys(filter1)[0]] === filter1[Object.keys(filter1)[0]] &&
            Object.keys(filter1).length
        ) &&
        (
            Object.keys(filter1).length !== 0
        );
}

export const ButtonSetFilter = ({actual_filter, setFilter, filter, content}) => (
    <Button size='mini' id='filter_comments' color={are_filters_equal(filter, actual_filter) ? 'green' : null} floated='center' onClick={()=>setFilter(filter)} content={content}/>
);

const Filter = () => {
    const dispatch = useDispatch();
    const actual_filter = useSelector(CommentsModule.getCommentsFilter);

    const filters = [
        ['Wszystkie', CommentsModule.filters.all],
        ['Zgłoszone', CommentsModule.filters.reported],
        ['Dane kontaktowe?', CommentsModule.filters.abuse],
    ];

    const setFilterAndFetchComments = (filter) => {
        dispatch(setFilter(filter));
        dispatch(changePage(1));
        dispatch(fetchComments());
    };

    return <Button.Group floated={'left'}>
        {filters.map((filter) => (
            <ButtonSetFilter content={filter[0]} actual_filter={actual_filter} filter={filter[1]} setFilter={setFilterAndFetchComments}/>
        ))}
    </Button.Group>
};

const CommentView = ({data}) => (
    <Card color={!data.reports ? 'blue' : 'red'}>
        <Card.Content>

            <Card.Header>
                <Link to={ROUTE.user(data.author.id)}>
                    <Image
                        avatar
                        src={data.author.profile_photo || process.env.PUBLIC_URL + '/user_avatar.png'}
                    />
                    {data.author.login}
                </Link>
            </Card.Header>
            <Card.Header>
                <a href={`https://buziak.pl/profil/${data.user.login}`}>
                    <Image
                        avatar
                        src={data.user.profile_photo || process.env.PUBLIC_URL + '/user_avatar.png'}
                    />
                    {data.user.login}
                </a>
            </Card.Header>


            <Card.Meta>

            </Card.Meta>
            <Card.Meta>
                {Moment(data.created_at).format('Y-MM-DD HH:mm')}
            </Card.Meta>
            <Card.Meta>
                Zgłoszenia: {data.reports}
            </Card.Meta>
            <Card.Description>
                {data.text}
            </Card.Description>
        </Card.Content>
    </Card>
);

const CommentsSection = ({view, comments}) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    const delete_comment = (id) => {
        setLoading(true)
        request('delete', api_url(`admin/comments/${id}`), {}).then(res => {
            dispatch(fetchComments())
        })
    }
    const clear_reports = (ids) => {
        setLoading(true)
        request('post', api_url(`admin/comments/clearReports`), { ids: ids }).then(res => {
            dispatch(fetchComments())
        })
    }
    const clear_abuse = (ids) => {
        setLoading(true)
        request('post', api_url(`admin/comments/clearAbuse`), { ids: ids }).then(res => {
            dispatch(fetchComments())
        })
    }

    useEffect(() => {
        setLoading(false)
        setSelected([]);
    }, [comments])

    const DeleteSelectedButton = useCallback( () =>
        <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Usuń (${selected.length})`}/>}>
            <div>Czy na pewno usunąć wybrane komentarze?</div>
            <Button onClick={() => {
                selected.forEach(comment => {
                    delete_comment(comment)
                })
            }} content={'Usuń'} loading={loading} disabled={loading}/>
        </Popup>
    , [comments, selected, loading])

    const ClearReportsButton = useCallback(() =>
        <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Czyść zgłoszenia (${selected.length})`} />}>
            <div>Czy na pewno usunąć zgłoszenia dla wybranych komentarzy?</div>
            <Button onClick={() => {
                clear_reports(selected);
            }} content={'Czyść zgłoszenia'} loading={loading} disabled={loading} />
        </Popup>
    , [comments, selected, loading])

    const ClearAbuseButton = useCallback(() =>
        <Popup open={loading === false ? undefined : true} on={"click"} disabled={!selected.length} trigger={<Button floated={'right'} content={`Poprawne (${selected.length})`} />}>
            <div>Czy na pewno oznaczyć wybrane komentarze jako poprawne?</div>
            <Button onClick={() => {
                clear_abuse(selected);
            }} content={'Poprawne'} loading={loading} disabled={loading} />
        </Popup>
    , [comments, selected, loading])

    if(view === 1)
        return <Card.Group itemsPerRow={4}>
            {comments.map(column => (
                <CommentView data={column}/>
            ))}
        </Card.Group>;
    else
        return <>
            <ClearAbuseButton/>
            <ClearReportsButton/>
            <DeleteSelectedButton/>
            <SelectableTableFromArray selected={selected} setSelected={setSelected} data={comments} warningConditionField={'abuse'} warningConditionValue={1} columns={[
                ['Autor', (data) => <UserName user={data.author} gender banned deleted/>],
                ['Adresat', (data) => <UserName user={data.user} gender banned deleted/>],
                ['Tekst', (data) => data.text, 'auto', true],
                ['Data', (data) => Moment(data.created_at).format('Y-MM-DD HH:mm')],
                ['Zgłoszenia', (data) => data.reports],
                ['', (data) => <Popup on={"click"} trigger={<Button content={'Usuń'} />}>
                    Czy na pewno usunąć ten kometarz?
                    <Button onClick={() => delete_comment(data.id)} content={'Usuń'} loading={loading} disabled={loading}/>
                </Popup>]
            ]} />
            </>
};

export const CommentsView = () => {
    let comments = useSelector(getComments);
    const [view, setView] = useState(0);
    const meta = useSelector(getCommentsMeta);
    const dispatch = useDispatch();
    const {text} = useParams();
    const appRouter = useAppRouter();

    const setPageAndFetchComments = (page) => {
        dispatch(changePage(page));
        dispatch(fetchComments());
    };

    let searchText = '';
    const onSearchTextChange = (e, {name, value}) => {
        searchText = value;
    };

    const searchClick = () => {
        if(searchText.length > 0) {
            dispatch(setFilter({}))
            appRouter.goToCommentsSearch(searchText);
        }
    }

    return <Container style={{width: '99%', paddingBottom: '20px'}}>
        <Divider hidden/>
        <Header as='h2'>
            Komentarze { text && text.length > 0 ? ` - treść: ${text}` : '' }
        </Header>
        <Divider/>
        { !text ? <>
            <Button.Content align={'right'}>
                <Filter/>

                <Button.Group align={'right'}
                    buttons={['list', 'th'].map((v, i)=>({key: i, icon: v, onClick: () => setView(i), active: view===i}))}
                />

            </Button.Content>

            <Divider hidden/>
        </>
        : ''}
        <CommentsSection view={view} comments={comments}/>
        <Divider/>
        <Segment basic textAlign={"center"}>

            <Pagination activePage={meta.current_page}
                        totalPages={meta.last_page}
                        onPageChange={(e, { activePage })=>setPageAndFetchComments(activePage)}
            />
        </Segment>
        <Segment>
            <Input onChange={onSearchTextChange} />
            <Button onClick={searchClick} content={'Szukaj'} style={{marginLeft: '10px'}}/>
        </Segment>
    </Container>;
};

export const Comments = () => {
    const dispatch = useDispatch();
    const {text} = useParams();

    useEffect(()=>{
      dispatch(resetCommentsQuery({text: text}));
      dispatch(changePage(1));
      dispatch(fetchComments());
    }, [text, dispatch]);

    return <CommentsView/>
};

